import React, {Component} from 'react';
import style from './Form.module.css';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

class Form extends Component {

    state = {
        userName: '',
        email: '',
        phone: '',
        text: ''
    }

    handlerSubmit = event => {
        event.preventDefault();

        const slackApiToken = 'xoxb-479231226724-2186732529542-uNDE8ZsWBYfXkzhZVq7Z7uhO';
        const channelId = 'C025FA0NXRU';

        const json = [
                {
                    "color": "#36a64f",
                    "pretext": "Запрос с сайта https://digitalnomads.software",
                    "title": "Сообщение от: " + this.state.userName,
                    "fields": [
                        {
                            "title": "E-mail",
                            "value": this.state.email,
                            "short": false
                        },
                        {
                            "title": "Телефон",
                            "value": this.state.phone,
                            "short": false
                        }
                    ],
                    "text": this.state.text,
                    "image_url": "http://my-website.com/path/to/image.jpg",
                    "thumb_url": "http://example.com/path/to/thumb.png",
                    "footer": "Slack API",
                    "footer_icon": "https://platform.slack-edge.com/img/default_application_icon.png",
                    "ts": new Date().getTime()
                }
            ];

        this.setState({userName:'', email:'', phone:'', text: ''})

        this.sendTextMessage(json, slackApiToken, channelId);
    }

    sendTextMessage = (json, token, channels) => {
        var urlencoded = new URLSearchParams();
        urlencoded.append("token", token);
        urlencoded.append("channel", channels);
        urlencoded.append("attachments", JSON.stringify(json));

        fetch('https://slack.com/api/chat.postMessage', {
            method: 'POST',
            body: urlencoded
        })
        .then(response => {
            this.props.method(response);
            console.log(response);
        })
        .catch(err => {
            console.log(err);
        })

        this.setState({userName:'', email:'', phone:'', link: '', file: '', companyName: '', companyLink: '', text: ''});
    }

    handlerChange = event => {
        event.preventDefault();
        const {value, name} = event.target;
        this.setState ({ [name]: value });
    }

    render() {

        const  cls = [style.Form];

        if (this.props.black) {
            cls.push(style.black)
        } 

        return(
            <form 
                onSubmit={this.handlerSubmit}
                onFocus={() => false}
                className={cls.join(' ')}
            >
                <div className={style.Form__top}>
                    <h3>Все крутые проекты <br/> начинаются со слова «Привет»</h3>
                </div>
                <div className={style.Form__input}>
                    <div>
                        <input
                            name='userName'
                            type='text'
                            value={this.state.userName}
                            onChange={this.handlerChange}
                            onFocus={() => false}
                            required
                        />
                        <label>Введите Ф.И.О. <span>*</span></label>
                    </div>
                    <div>
                        <input
                            name='email'
                            type='email'
                            value={this.state.email}
                            onChange={this.handlerChange}
                            onFocus={() => false}
                            required
                        />
                        <label>E-mail <span>*</span></label>
                    </div>
                    <div>
                        <InputMask name='phone'
                                   type='phone'
                                   mask="(+7) 999-999-99-99"
                                   required
                                   onChange={this.handlerChange}
                                   onFocus={() => false}
                                   value={this.state.phone}
                        />
                        <label>Телефон <span>*</span></label>
                    </div>
                </div>
                <textarea
                    placeholder='Опишите ваш проект'
                    name='text'
                    value={this.state.text}
                    onChange={this.handlerChange}
                    onFocus={() => false}
                    wrap='soft'
                    required
                />
                <div className={style.Form__bottom}>
                    <div className={style.Form__bottom_checkbox}>
                        <input
                            type="checkbox" 
                            required
                        />
                        <span></span>
                        <label>Я согласен с <Link to='/privacy-policy' target='_blank'>условиями <br/>обработки персональных данных</Link></label>
                    </div>
                    <input
                        type='submit'
                        value='Сказать привет'
                    />
                </div>
            </form>
        )
    }
}

export default Form