import React, {Component} from 'react';
import style from './Blog.module.css';
import { Container } from 'reactstrap';
import articles from '../../json/articles.json'

import Description from '../../components/Description/Description';
import Header from '../../components/Header/Header';
import FormHandler from '../../components/FormHandler/FormHandler';
import Footer from '../../components/Footer/Footer';
import ArticlesCard from '../../components/ArticlesCard/ArticlesCard';

class Blog extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Блог',
            }
        ],
        articles: articles,
        black: true
    }

    render() {

        const {descriptions, articles, black} = this.state;
   
        return(

            <div className={style.Blog}>
                <Container>
                    <Header/>
                    <Description description={descriptions[0]}/>
                    <ArticlesCard articles={articles}/>
                    <FormHandler black={black}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default Blog