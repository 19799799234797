import React from 'react';
import { Container } from 'reactstrap';
import style from './PrivacyPolicy.module.css';
import image from '../../img/logo-blue.svg';

const PrivacyPolicy = () => {
    return(
        <div className={style.PrivacyPolicy}>
            <Container>
                <div className={style.PrivacyPolicy__logo}>
                    <img src={image} alt={'Логотип Дижитал Номадс'}/>
                </div>
                <div className={style.PrivacyPolicy__title}>Политика конфиденциальности</div>
                <div className={style.PrivacyPolicy__text}>
                    <p>
                        Дата вступления в силу: 29 июня 2021 года. <br/>
                        Адрес размещения: <a href='https://dn-site.dnomads.pro/' target='_blank' rel="noreferrer">https://digitalnomads.software/</a>
                    </p>
                    <p>
                        Настоящая политика конфиденциальности определяет основания, порядок, 
                        взаимные права и обязанности сторон в процессе сбора, хранения, 
                        использования информации о гражданах (персональных данных) 
                        (далее — обработка ПД) зарегистрированным в установленном законом порядке 
                        в качестве юридического лица — Общество с ограниченной ответственностью "Диджитал Номадс" 
                        (далее — Агентство) и получаемой Агентством посредством заполнения формы обратной связи, 
                        имеющейся в составе веб-сайта, расположенного на домене digitalnomads.software (далее — Сайт).
                    </p>
                    <ol>
                        <li>
                            Обработка ПД Агентством осуществляется на основании и вследствие свободного волеизъявления любого лица — пользователя Сайта, 
                            имеющего намерение ознакомиться с информацией об услугах Агентства и/или заказать услуги (далее — Пользователь).
                        </li>
                        <li>
                            Агентство осуществляет сбор, хранение и использование персональных данных, указанных по желанию и усмотрению Пользователя, в следующем объёме:
                            <ol type='a'>
                                <li>Фамилия, имя, отчество Пользователя;</li>
                                <li>Адрес электронной почты Пользователя;</li>
                                <li>Номер телефона Пользователя;</li>
                                <li>Иные персональные данные, которые Пользователь посчитает необходимым сообщить Агентству через текстовое поле «Опишите ваш проект».</li>
                                <li>Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).</li>
                            </ol>
                        </li>
                        <li>
                            Целью обработки ПД Агентством является оценка возможности оказания услуг Пользователю. 
                        </li>
                        <li>
                            Любые персональные данные из перечня, приведённого в пункте 2 настоящей политики конфиденциальности подлежат удалению по первому письменному требованию Пользователя, 
                            направленному на адрес электронной почты <a href="mailto:smile@dnomads.pro">smile@dnomads.pro</a> с темой письма “требование об удалении персональных данных”, с указанием точного перечня персональных данных, 
                            подлежащих удалению, предоставлением сведений, позволяющих достоверно определить, что запрос об удалении персональных данных исходит от лица, 
                            чьи персональные данные были обработаны Агентством, в течение 3 (трех) рабочих дней с момента получения такого требования. 
                        </li>
                        <li>
                            Все обрабатываемые Агентством персональные данные хранятся в месте, исключающим свободный доступ к ним третьих лиц, с использованием современных средств защиты.
                        </li>
                        <li>
                            Агентство не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте. 
                        </li>
                        <li>
                            Обработка персональных данных Пользователя осуществляется без ограничения срока, при условии, что цели обработки Персональных данных ещё не достигнуты, 
                            любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств. 
                            Пользователь соглашается с тем, что Агентство вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, 
                            операторам электросвязи, исключительно в целях выполнения заказа Пользователя. Персональные данные Пользователя могут быть переданы уполномоченным органам 
                            государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
                        </li>
                        <li>
                            В случае нарушения своих обязательств Агентством, последнее несёт ответственность за убытки (реальный ущерб), 
                            понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством 
                            Российской Федерации, за исключением случаев, предусмотренных п. 7 настоящей политики конфиденциальности. 
                        </li>
                        <li>
                            В случае возникновения споров между Агентством и Пользователем соблюдение претензионного порядка является обязательным. 
                            Срок ответа на претензию — 20 рабочих дней с момента получения письменной претензии. 
                            В случае применения судебного порядка разрешения спора, последние разрешаются в Арбитражном суде Томской области.
                        </li>
                        <li>
                            Агентство вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
                        </li>
                    </ol>
                </div>
                <div className={style.PrivacyPolicy__bottom}>
                    <p>Директор</p>
                    <p>А.А. Пашкевич</p>
                </div>
            </Container>
        </div>
    ) 
}

export default PrivacyPolicy