import React from 'react';
import style from './Stages.module.css';

import StagesList from './StagesList/StagesList';

const Stages = ({stages}) => {

    return(
        <div className={style.Stages}>
            <h2 className={style.Stages__title}>
                Этапы работы 
            </h2>
            <StagesList
                stages={stages}
            />
        </div>
    )
}

export default Stages