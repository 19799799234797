import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import style from './Menu.module.css';
import Backdrop from '../UI/Backdrop/Backdrop'

const links = [
    {to: '/about-us', label: ' О компании'},
    {to: '/our-services', label: ' Наши услуги'},
    {to: '/project', label: ' Проекты'},
    {to: '/work-with-us', label: ' Карьера'},
    {to: '/contact', label: ' Контакты'}
];


class Menu extends Component {
    
    renderLinks () {
        return links.map((link, index) => {
            return (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        onClick={this.props.onClose}
                    >
                        {link.label}
                    </NavLink>
                </li>
            )
        })
    }


    render() {
        const cls = [style.Menu];

        if (!this.props.isOpen) {
            cls.push(style.close)
        }

        return (
        
            <React.Fragment>
                <div className={cls.join(' ')} id={'Menu'}>
                    <nav className={style.Menu__nav}>
                        <ul>
                            {this.renderLinks()}
                        </ul>
                    </nav>
                    <div className={style.Menu__footer}>
                        <ul>
                            <li>
                                <a href="tel:+79627825057">+7 962 782 50 57</a>
                            </li>
                            <li>
                                <a href="mailto:smile@dnomads.pro">smile@dnomads.pro</a>
                            </li>
                        </ul>
                        <div>
                            Россия, Томск,
                            <br/>
                            Ачинская 9, 1 этаж
                        </div>
                    </div>

                </div>

                {this.props.isOpen ? <Backdrop onClick={this.props.onClose} /> : null}
       
            </React.Fragment>

        )
    
    }

}

export default Menu
