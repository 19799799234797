import style from '../../components/ContactFaces/ContactFaces.module.css';
import img from '../../img/direction-2.png';
import img1 from '../../img/contact-1.png';
import React from 'react';

const contacts = [
    {
        name: 'Дмитрий Халабаев',
        photo: img,
        icon: '../../img/socialSprite.svg#phone',
        think: 'Привет! Меня зовут Дмитрий. \nЯ готов ответить на любые вопросы по поводу сотрудничества с нами.',
        connection: '+7 (923) 416-67-46',
        href: 'tel: +7 (923) 416-67-46'
    },
    {
        name: 'Татьяна Соя',
        photo: img1,
        icon: '../../img/socialSprite.svg#telegram',
        think: 'Привет! Меня зовут Татьяна. \nЯ готова ответить на любые вопросы по поводу актуальных вакансий.',
        connection: 'tatyanasoya',
        href: 'tg://resolve?domain=tatyanasoya'
    }
]

const renderFaces = () => {
    return contacts.map((contact, index) => {
        return (
            <div key={index} className={style.ContactFaces__item}>
                <p className={style.ContactFaces__item_think}>
                    {contact.think}
                </p>
                <div className={style.ContactFaces__item_block}>
                    <div className={style.ContactFaces__item_img}>
                        <img src={contact.photo} alt='Контакт'/>
                    </div>
                    <div className={style.ContactFaces__item_info}>
                        <div className={style.ContactFaces__item_name}>
                            {contact.name}
                        </div>
                        <div className={style.ContactFaces__item_phone}>
                            <svg>
                                <use xlinkHref={ contact.icon }>
                                </use>
                            </svg>
                            <a href={contact.href}>{contact.connection}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
}


const ContactFaces = () => {
    return (
        <div className={style.ContactFaces__directions}>
            { renderFaces() }
            <div className={style.ContactFaces__item}>
                <div className={style.ContactFaces__item_status}>
                    <div className={style.ContactFaces__mail}>
                        А ещё вы можете написать нам на почту <a href="mailto:hr@dnomads.pro">hr@dnomads.pro</a>
                    </div>
                    <div className={style.ContactFaces__phone}>
                        Или позвонить по телефону <a href="tel: +7 (952) 162-17-72">+7 (952) 162-17-72</a>
                    </div>
                    <div className={style.ContactFaces__address}>
                        Или прийти в гости <a href="https://2gis.ru/tomsk/firm/70000001038798938?m=84.966226%2C56.492748%2F17.83" target="_blank" rel="noreferrer">Томск, улица Ачинская 9, 1 этаж</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactFaces
