import React from 'react';
import style from './Footer.module.css';


const Footer = () => {

    return(
        <div className={style.Footer}>
            <div className={style.Footer__protect}>2024 Digital Nomads.<br/> Все права защищены</div>
        </div>
    )
}

export default Footer
