import React, {Component} from 'react';
import style from './TeamBuilding.module.css';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import FormHandler from '../../components/FormHandler/FormHandler';
import Footer from '../../components/Footer/Footer';
import Stages from '../../components/Stages/Stages';


class TeamBuilding extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Ключевые компетентности',
                subtitle: 'Компания Digital nomads предлагает полный комплекс услуг по проектированию, разработке и сопровождению мобильных приложений на самых популярных платформах'
            },
            {
                id: 1,
                title: 'Разработка мобильных приложений',
                subtitle: 'Более 10 лет работы в сфере разработки мобильных приложений различной специфики позволил нам накопить богатый опыт в проектировании интуитивного и релевантного тематике приложения интерфейса',
                price: 'Стоимость: от 1 500 000 ₽',
                data: 'Сроки: от 2 месяцев'
            },
            {
                id: 2,
                title: 'Дизайн интерфейсов приложений',
                subtitle: 'UX-дизайн отвечает за функциональность, адаптивность продукта и то, какие эмоции он вызывает у пользователей. Чем проще ваш интерфейс, тем проще пользователю получить результат и совершить целевое действие.',
                price: 'Стоимость: от 1 500 ₽ / час'
            },
            {
                id: 3,
                title: 'Создание MVP',
                subtitle: 'MVP — сокращение от «Minimum Viable Product». Цель разработки MVP — быстро проверить основную гипотезу о востребованности приложения среди целевой аудитории. Вместо долгой и дорогой разработки приложения с большим набором функций и сценариев выпустить приложение, хорошо решающее одну пользовательскую задачу.',
                price: 'Стоимость: от 500 000 ₽',
                data: 'Сроки: от 4-х недель'
            },
            {
                id: 4,
                title: 'Аналитика мобильных приложений',
                subtitle: 'Аналитика — один из ключевых элементов для принятия решений в экономике и продвижения мобильных приложений.',
                price: 'Стоимость: от 1 750 ₽ / час'
            },
            {
                id: 5,
                title: 'Формирование команды \nдля реализации проекта',
                subtitle: 'Наш главный офис находится в Томске — сибирской Силиконовой долине. В Томске много специалистов мирового уровня, которые готовы помочь вам в реализации проекта. А мы поможем вам их найти, обучить и сплотить. Обычно команда состоит из middle-разработчика, тимлида/senior-разработчика,\n QA-инженера и PM парт-тайм.',
                price: 'Стоимость: от 500 000 ₽'
            }
        ],
        stages: [
            {
                number: '01',
                title: 'Подключение PM',
                subtitle: 'PM выступает единственной точкой контакта по всем вопросам.'
            },
            {
                number: '02',
                title: 'Roadmap на фазу', 
                subtitle: 'PM очерчивает круг работ на срок от 2‑х до 4‑х месяцев, разбитый на итерации по 1‑2 недели.'
            },
            {
                number: '03',
                title: 'Выделение команды',
                subtitle: 'Если в течение первых 2 недель не устраивает специалист — не оплачиваете его работу.'
            },
            {
                number: '04',
                title: 'Старт фазы 1/2',
                subtitle: '— проводим кик-офф митинг (знакомство клиента с командой и постановка целей);'
            },
            {
                number: '05',
                title: 'Старт фазы 2/2',
                subtitle: '— c самого начала проекта подключаем кураторов: технического, бизнес и куратора по качеству.'
            },
            {
                number: '06',
                title: 'Разработка \nпо итерациям',
                subtitle: 'Работаем по Scrum. В конце каждой итерации проводим шоу результатов. Регулярные  релизы.'
            },
            {
                number: '07',
                title: 'Контроль',
                subtitle: 'С самого начала проекта подключаем кураторов: технического, бизнес и куратора по качеству.'
            },
            {
                number: '08',
                title: 'Окончание фазы',
                subtitle: 'Подводим итоги, переходим к разработке следующей фазы.'
            }
        ],
        black: true
    }


    render() {
        const {descriptions, stages, black} = this.state;

        return(

            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions[5]}/>
                    <Stages stages={stages}/>
                    <FormHandler black={black}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default TeamBuilding