import React from 'react';
import style from './Result.module.css';
import image from '../../img/Harakiri-result-bg-2.png'
import { Container } from 'reactstrap';

const Result = ({result, comment}) => {
    return(
        <div className={style.Result__bg}>
            <Container>
                <div className={style.Result}>
                    <div className={style.Result__block}>
                        <h5>Итог</h5>
                        <p>{result}</p>
                    </div>

                    <div className={style.Result__img}>
                        <img src={image} alt={'Итоги'}/>
                    </div>

                    <div className={style.Result__comment}>
                        <div className={style.Result__comment_text}>
                            <p>{comment.text}</p>
                        </div>
                        <div className={style.Result__comment_autor}>
                            <p className={style.autor}>{comment.autor}</p>
                            <p>{comment.position}</p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    ) 
}

export default Result