// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.Tabs_Tabs__ul__2ybCS {\n    margin-bottom: 1.5rem;\n}\n.Tabs_Tabs__li__110JG {\n    width: 25%;\n    text-align: center;\n}\n.Tabs_Tabs__li__110JG a {\n    font-weight: 600;\n    font-size: 1.625rem;\n    line-height: 2rem;\n    color: #484848;\n    border-bottom: 4px solid #484848;\n}\n.Tabs_Tabs__li__110JG a.active {\n    color: #FFFFFF;\n    border-bottom: 4px solid #FFFFFF;\n}\n.Tabs_Tabs__li__110JG a:hover {\n    cursor: pointer;\n}\n\n@media screen and (max-width: 991px) {\n    .Tabs_Tabs__3CcwB {\n        display: none;\n    }\n}\n\n", "",{"version":3,"sources":["webpack://src/components/UI/Tabs/Tabs.module.css"],"names":[],"mappings":";AACA;IACI,qBAAqB;AACzB;AACA;IACI,UAAU;IACV,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;IACd,gCAAgC;AACpC;AACA;IACI,cAAc;IACd,gCAAgC;AACpC;AACA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n.Tabs__ul {\n    margin-bottom: 1.5rem;\n}\n.Tabs__li {\n    width: 25%;\n    text-align: center;\n}\n.Tabs__li a {\n    font-weight: 600;\n    font-size: 1.625rem;\n    line-height: 2rem;\n    color: #484848;\n    border-bottom: 4px solid #484848;\n}\n.Tabs__li :global(a.active) {\n    color: #FFFFFF;\n    border-bottom: 4px solid #FFFFFF;\n}\n.Tabs__li a:hover {\n    cursor: pointer;\n}\n\n@media screen and (max-width: 991px) {\n    .Tabs {\n        display: none;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tabs__ul": "Tabs_Tabs__ul__2ybCS",
	"Tabs__li": "Tabs_Tabs__li__110JG",
	"Tabs": "Tabs_Tabs__3CcwB"
};
export default ___CSS_LOADER_EXPORT___;
