// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_Header__2C_Ub {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-weight: 600;\n    padding: 1.5625rem 0;\n}\n.Header_Header__right__pUEg0 {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-end;\n}\n.Header_Header__right__pUEg0 div:first-child {\n    margin-right: 2.5rem;\n}\n\n@media screen and (max-width: 414px) {\n    .Header_Header__2C_Ub {\n        height: 100px;\n    }\n    .Header_Header__right__pUEg0 {\n        width: 100%;\n    }\n    .Header_Header__right__pUEg0 div:first-child {\n        margin-left: 1.5rem;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;IAChB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;AACzB;AACA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,WAAW;IACf;IACA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".Header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-weight: 600;\n    padding: 1.5625rem 0;\n}\n.Header__right {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-end;\n}\n.Header__right div:first-child {\n    margin-right: 2.5rem;\n}\n\n@media screen and (max-width: 414px) {\n    .Header {\n        height: 100px;\n    }\n    .Header__right {\n        width: 100%;\n    }\n    .Header__right div:first-child {\n        margin-left: 1.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": "Header_Header__2C_Ub",
	"Header__right": "Header_Header__right__pUEg0"
};
export default ___CSS_LOADER_EXPORT___;
