// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutUs_AboutUs__2fzc0 {\n}\n.AboutUs_FooterArdaContainer__1PV0r {\n    position: relative; /* Создание контекста для абсолютного позиционирования */\n    display: flex;\n    justify-content: center; /* Центрирование Footer по горизонтали */\n    align-items: center; /* Центрирование по вертикали */\n    width: 100%; /* Заполнение всей ширины контейнера */\n}\n\n.AboutUs_Arda__2QIfG {\n    position: absolute;\n    left: 0; /* Привязка к левому краю */\n}\n\n.AboutUs_Footer__2b279 {\n    text-align: center;\n    vertical-align: center;\n}", "",{"version":3,"sources":["webpack://src/pages/AboutUs/AboutUs.module.css"],"names":[],"mappings":"AAAA;AACA;AACA;IACI,kBAAkB,EAAE,wDAAwD;IAC5E,aAAa;IACb,uBAAuB,EAAE,wCAAwC;IACjE,mBAAmB,EAAE,+BAA+B;IACpD,WAAW,EAAE,sCAAsC;AACvD;;AAEA;IACI,kBAAkB;IAClB,OAAO,EAAE,2BAA2B;AACxC;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B","sourcesContent":[".AboutUs {\n}\n.FooterArdaContainer {\n    position: relative; /* Создание контекста для абсолютного позиционирования */\n    display: flex;\n    justify-content: center; /* Центрирование Footer по горизонтали */\n    align-items: center; /* Центрирование по вертикали */\n    width: 100%; /* Заполнение всей ширины контейнера */\n}\n\n.Arda {\n    position: absolute;\n    left: 0; /* Привязка к левому краю */\n}\n\n.Footer {\n    text-align: center;\n    vertical-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AboutUs": "AboutUs_AboutUs__2fzc0",
	"FooterArdaContainer": "AboutUs_FooterArdaContainer__1PV0r",
	"Arda": "AboutUs_Arda__2QIfG",
	"Footer": "AboutUs_Footer__2b279"
};
export default ___CSS_LOADER_EXPORT___;
