// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TeammateItem_TeammateItem__2wGiT {\n    position: relative;\n    z-index: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    align-self: flex-end;\n    margin-bottom: 30px;\n}\n.TeammateItem_TeammateItem__avatar__21tcO {\n    position: relative;\n}\n.TeammateItem_TeammateItem__circle__3mbHa {\n    opacity: 0;\n    transition: 0.5s ease;\n    content: '';\n    position: absolute;\n    top: 20%;\n    left: 25%;\n    width: 160px;\n    height: 160px;\n    border-radius: 50%;\n    filter: blur(40px);\n    z-index: -1;\n}\n.TeammateItem_TeammateItem__avatar__21tcO:hover .TeammateItem_TeammateItem__circle__3mbHa {\n    opacity: 1;\n }\n\n", "",{"version":3,"sources":["webpack://src/components/Teammate/TeammateItem/TeammateItem.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,oBAAoB;IACpB,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,qBAAqB;IACrB,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,UAAU;CACb","sourcesContent":[".TeammateItem {\n    position: relative;\n    z-index: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    align-self: flex-end;\n    margin-bottom: 30px;\n}\n.TeammateItem__avatar {\n    position: relative;\n}\n.TeammateItem__circle {\n    opacity: 0;\n    transition: 0.5s ease;\n    content: '';\n    position: absolute;\n    top: 20%;\n    left: 25%;\n    width: 160px;\n    height: 160px;\n    border-radius: 50%;\n    filter: blur(40px);\n    z-index: -1;\n}\n.TeammateItem__avatar:hover .TeammateItem__circle {\n    opacity: 1;\n }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TeammateItem": "TeammateItem_TeammateItem__2wGiT",
	"TeammateItem__avatar": "TeammateItem_TeammateItem__avatar__21tcO",
	"TeammateItem__circle": "TeammateItem_TeammateItem__circle__3mbHa"
};
export default ___CSS_LOADER_EXPORT___;
