import style from '../Requisites/Requisites.module.css';
import React from 'react';

const requisites = [
  'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ДИДЖИТАЛ НОМАДС"',
  'ОГРН: 1207000000305',
  'ИНН: 7017469940',
  'КПП: 701701001'
]

const renderRequisites = () => {
  return requisites.map((item, index) => {
    return (
      <p key={index} className={style.Requisites__item}>
          {item}
      </p>
    )
  })
}


const Requisites = () => {
  return (
    <div className={style.Requisites__directions}>
      { renderRequisites() }
    </div>
  )
}

export default Requisites