import React, {Component} from 'react';
import style from './SpeedReading.module.css';
import { Container } from 'reactstrap';

import descriptions from '../../../json/projectDescription.json';
import details from '../../../json/projectDetails.json';
import preview from '../../../json/projectPreview.json';
import roadmap from '../../../json/projectRoadmap.json';

import PreviewCase from '../../../components/PreviewCase/PreviewCase'
import Footer from '../../../components/Footer/Footer';
import RoadMapBlock from '../../../components/RoadMapBlock/RoadMapBlock';

class SpeedReading extends Component {

    state = {
        descriptions: descriptions,
        preview: preview,
        details: details,
        roadmap: roadmap,
        scrolling: 0
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const elements = document.getElementsByClassName("RoadMapItem_RoadMapItem__left__3uKx_");

        const elemHeight = document.getElementById('RoadMapBlock').scrollHeight;
        const clientHeight = document.documentElement.clientHeight;
        const elemY = document.getElementById('RoadMapBlock').getBoundingClientRect().y;
        
        const k = .85;

        // add color to text
        Array.from(elements).forEach((el) => {
            let needColor = false;
            const elemOffset = el.getBoundingClientRect().y - clientHeight * k;
            needColor = elemOffset <= 0;
            if (needColor) {el.style = 'color:#0064FA'} else {el.style = null};
        });

        // add color to vertical line
        const offset = elemY - clientHeight * k;
        let persentage = 0;
        if (offset <= 0) {
            persentage = Math.abs(offset/elemHeight * 100)
            if (persentage >= 100) {
                persentage = 100;
            }
        } else {
            persentage = 0;
        }

        this.setState({scrolling: persentage});
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
  
    render() {
        const {descriptions, preview, details, roadmap, scrolling} = this.state;
       
        return(
            <div className={style.SpeedReading}>
                <div className={style.SpeedReading__preview}>
                    <PreviewCase
                        descriptions={descriptions[6]}
                        preview={preview[6]}
                        details={details.speedReading}
                    />
                </div>
                <RoadMapBlock 
                    roadmap={roadmap.speedReading} 
                    onScroll = {this.handleScroll}
                    scrolling = {scrolling}
                />
                <Container>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default SpeedReading