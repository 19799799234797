import React from 'react';
import style from './Gallery.module.css';

import Backdrop from '../UI/Backdrop/Backdrop';
import GallerySlider from '../UI/GallerySlider/GallerySlider';

const Gallery = ({content, setActive, active}) => {

    const cls = [style.Gallery];

    if (active) {
        cls.push(style.active)
    } 
    
    return(
        <>
            <div className={cls.join(' ')}>
                <div 
                    className={style.Gallery__Close}
                    onClick={setActive}
                >
                </div>
                <GallerySlider content={content} />
            </div>

            { active ? <Backdrop onClick={setActive} zIndex={'true'} /> : null }
        </>
    )

}


export default Gallery