import React, {Component} from 'react';
import style from './Harakiri.module.css';
import { Container } from 'reactstrap';

import descriptions from '../../../json/projectDescription.json';
import preview from '../../../json/projectPreview.json';
import details from '../../../json/projectDetails.json';
import roadmap from '../../../json/projectRoadmap.json';

import PreviewCase from '../../../components/PreviewCase/PreviewCase'
import Footer from '../../../components/Footer/Footer';
import RoadMapBlock from '../../../components/RoadMapBlock/RoadMapBlock';
import Result from '../../../components/Result/Result';

class Harakiri extends Component {

    state = {
        descriptions: descriptions,
        preview: preview,
        details: details,
        roadmap: roadmap,
        results: {
            harakiri: 'Увеличение доли заказов из мобильного приложения до 40% на текущий момент.'
        },
        comments: [
            {
                id: 0,
                text: '«Мы благодарны компании Диджитал Номадс за то, что она предлагает лучшие решения для нашего бизнеса. Ребята погрузились в наш проект как в свой собственный - первоклассная команда. Они слышали нас и быстро перестраивались под наши требования, помогая регулярно внедрять новые функции. Они показали себя не просто подрядчиками, а полноценными партнёрами. Мы рекомендуем компанию Диджитал Номадс как профессионалов в мобильной разработке».',
                autor: 'Валерий Князев',
                position: 'Руководитель ИТС компания Харакири'
            }
        ],
        scrolling: 0
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const elements = document.getElementsByClassName("RoadMapItem_RoadMapItem__left__3uKx_");

        const elemHeight = document.getElementById('RoadMapBlock').scrollHeight;
        const clientHeight = document.documentElement.clientHeight;
        const elemY = document.getElementById('RoadMapBlock').getBoundingClientRect().y;
        
        const k = .7;

        // add color to text
        Array.from(elements).forEach((el) => {
            let needColor = false;
            const elemOffset = el.getBoundingClientRect().y - clientHeight * k;
            needColor = elemOffset <= 0;
            if (needColor) {el.style = 'color:#0064FA'} else {el.style = null};
        });

        // add color to vertical line
        const offset = elemY - clientHeight * k;
        let persentage = 0;
        if (offset <= 0) {
            persentage = Math.abs(offset/elemHeight * 100)
            if (persentage >= 100) {
                persentage = 100;
            }
        } else {
            persentage = 0;
        }

        this.setState({scrolling: persentage});
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
  
    render() {
        const {descriptions, preview, roadmap, results, comments, scrolling, details} = this.state;
       
        return(
            <div className={style.Harakiri}>
                <PreviewCase
                    descriptions={descriptions[0]}
                    preview={preview[0]}
                    details={details.harakiri}
                />
                <RoadMapBlock 
                    roadmap={roadmap.harakiri} 
                    onScroll = {this.handleScroll}
                    scrolling = {scrolling}
                />
                <Result 
                    result={results.harakiri}
                    comment={comments[0]}
                />
                <Container>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default Harakiri