// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GeneralServiceBlock_GeneralServiceBlock__2rCRT {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-bottom: 3.5rem;\n}\n\n@media screen and (max-width: 991px) {\n    .GeneralServiceBlock_GeneralServiceBlock__2rCRT {\n        flex-direction: column;\n        margin-bottom: 3rem;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/GeneralServiceBlock/GeneralServiceBlock.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;AACJ","sourcesContent":[".GeneralServiceBlock {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-bottom: 3.5rem;\n}\n\n@media screen and (max-width: 991px) {\n    .GeneralServiceBlock {\n        flex-direction: column;\n        margin-bottom: 3rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GeneralServiceBlock": "GeneralServiceBlock_GeneralServiceBlock__2rCRT"
};
export default ___CSS_LOADER_EXPORT___;
