import React from 'react';
import Button from '../UI/Button/Button';
import style from './ProjectBlock.module.css';
import ProjectList from './ProjectList/ProjectList';

const ProjectBlock = ({projects, itemsToShow, showMore, noneArrow, projectsLength}) => {

    return(
        <div className={style.ProjectBlock}>
           <ProjectList
                projects={projects}
                itemsToShow={itemsToShow}
           />
           <div className={style.ProjectBlock__button}>
                {itemsToShow < projectsLength &&
                    <Button 
                        onClick={showMore}
                        noneArrow={noneArrow}
                    >
                        Показать больше
                    </Button>
                }
           </div>
        </div>
    )
}

export default ProjectBlock