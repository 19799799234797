import React from 'react';
import style from './StagesItem.module.css';

const StagesItem = ({number, title, subtitle}) => {

    return(
        <div key={number} className={style.StagesItem}>
            <div className={style.StagesItem__number}>
                {number}
            </div>
            <h5 className={style.StagesItem__title}>
                {title}
            </h5>
            <p className={style.StagesItem__subtitle}>
                {subtitle}
            </p>
        </div>
    )
}

export default StagesItem