// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../img/bg_iOS.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IosDeveloper_IosDeveloper__31HLo {\n    background: #151515 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 100% 50% no-repeat;\n    padding: 10px 0;\n    overflow: hidden;\n    background-size: 30%;\n}\n", "",{"version":3,"sources":["webpack://src/pages/IosDeveloper/IosDeveloper.module.css"],"names":[],"mappings":"AAAA;IACI,8EAAkE;IAClE,eAAe;IACf,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":[".IosDeveloper {\n    background: #151515 url(\"../../img/bg_iOS.svg\") 100% 50% no-repeat;\n    padding: 10px 0;\n    overflow: hidden;\n    background-size: 30%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IosDeveloper": "IosDeveloper_IosDeveloper__31HLo"
};
export default ___CSS_LOADER_EXPORT___;
