import React from 'react';
import workPromises from '../../json/workPromises.json';
import workOpportunities from '../../json/workOpportunities.json';
import WorkDetailsList from './WorkDetailsList/WorkDetailsList';

const detailTitles = [
  'В нашей команде ты будешь:',
  'Развиваться как профессионал ты сможешь с помощью:'
]

const WorkDetails = () => {
  return (
    <div>
      <WorkDetailsList detailList={workPromises} title={detailTitles[0]}/>
      <WorkDetailsList detailList={workOpportunities} title={detailTitles[1]}/>
    </div>
  )
}

export default WorkDetails