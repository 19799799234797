import React, {Component} from 'react';
import style from './MVP.module.css';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import FormHandler from '../../components/FormHandler/FormHandler';
import Footer from '../../components/Footer/Footer';
import AdvantagesList from '../../components/AdvantagesList/AdvantagesList';
import DirectionsDevelopment from '../../components/DirectionsDevelopment/DirectionsDevelopment';
import Stages from '../../components/Stages/Stages';


class MVP extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Ключевые компетентности',
                subtitle: 'Компания Digital nomads предлагает полный комплекс услуг по проектированию, разработке и сопровождению мобильных приложений на самых популярных платформах'
            },
            {
                id: 1,
                title: 'Разработка мобильных приложений',
                subtitle: 'Более 10 лет работы в сфере разработки мобильных приложений различной специфики позволил нам накопить богатый опыт в проектировании интуитивного и релевантного тематике приложения интерфейса',
                price: 'Стоимость: от 1 500 000 ₽',
                data: 'Сроки: от 2 месяцев'
            },
            {
                id: 2,
                title: 'Создание MVP',
                subtitle: 'MVP — сокращение от «Minimum Viable Product». Цель разработки MVP — быстро проверить основную гипотезу о востребованности приложения среди целевой аудитории. Вместо долгой и дорогой разработки приложения с большим набором функций и сценариев выпустить приложение, хорошо решающее одну пользовательскую задачу.',
                price: 'Стоимость: от 500 000 ₽',
                data: 'Срок реализации: от 4-х недель'
            }
        ],
        advantages: [
            {
                id: 0,
                img: './../img/mvp-1.svg',
                title: 'Экономия',
                subtitle: 'Вы израсходуете меньший бюджет, если гипотеза была ошибочна.',
            },
            {
                id: 1,
                img: './../img/mvp-2.svg',
                title: 'Скорость',
                subtitle: 'Можно получить быструю обратную связь от пользователей и точнее развивать продукт.',
            },
            {
                id: 2,
                img: './../img/mvp-3.svg',
                title: 'Предпродажи',
                subtitle: 'Пользователи узнают о приложении на несколько месяцев раньше.',
            }
        ],
        directions: [
            {
                id: 0,
                title: 'iOS',
                src: './../img/Ios.jpg',
                alt: 'Ios',
                languages: [
                    {item: 'Swift'},
                    {item: 'Objective-C'}
                ],
                services: [
                    {item: 'Google FireBase'},
                    {item: 'VKApi, FBApi'},
                    {item: 'Fabric, Crashlytics'},
                    {item: 'Sketch, Zeplin, Figma'},
                    {item: 'Яндекс.Касса sdk'},
                    {item: 'Stripe'},
                    {item: 'CloudPayments'},
                    {item: 'PayKeeper'},
                    {item: 'Apple Pay'}
                ],
                technologys: [
                    {
                        list: [
                            {item: 'MVC, MVP, MVVM, VIPER SOLID, Clean architecture'},
                            {item: 'AutoLayout, SnapKit Realm, CoreData Git'},
                            {item: 'CoreAnimation, CoreGraphics'},
                            {item: 'CoreLocation, MapKit'},
                            {item: 'WebSocket'},
                            {item: 'GCD'},
                            {item: 'Swinject'},
                        ]
                    },
                    {
                        list: [
                            {item: 'PromiseKit'},
                            {item: 'Socket, STOMP'},
                            {item: 'Dispatch Queues'},
                            {item: 'PassKit'},
                            {item: 'AVKit'},
                            {item: 'ARKit'},
                            {item: 'SpriteKit'},
                            {item: 'Starscream, CocoaAsyncSocket'},
                            {item: 'Speech'},
                            {item: 'RxSwift, Reactive'}
                        ]
                    }
                ]
            },
            {
                id: 1,
                title: 'Android',
                src: './../img/Android.jpg',
                alt: 'Android',
                languages: [
                    {item: 'Kotlin'},
                    {item: 'Java'}
                ],
                services: [
                    {item: 'App metrika'},
                    {item: 'Appsflyer'},
                    {item: 'Firebase'},
                    {item: 'Crashlytics (Firebase/Fabric)'},
                    {item: 'Pushwoosh'},
                    {item: 'Applozic'},
                    {item: 'Sendbird'},
                    {item: 'Facebook analytics'},
                    {item: ''},
                    {item: 'Google play services'},
                    {item: 'Google AdMob'},
                    {item: 'GoogleMaps'},
                    {item: 'Stripe'},
                    {item: 'PayKeeper'},
                    {item: 'CloudPayments'},
                    {item: 'Android Pay'}
                ],
                more: true,
                technologys: [
                    {
                        list: [
                            {item: 'MVP, MVI, SOLID, Clean architecture'},
                            {item: 'Retrofit 2, okhttp3, Scarlet'},
                            {item: 'Rxjava2, coroutines'},
                            {item: 'Dagger 2, koin, toothpick'},
                            {item: 'Cicerone'},
                            {item: 'Groupie, Adapter delegate'},
                            {item: 'Moxy, Reamp, MviCore'},
                        ]
                    },
                    {
                        list: [
                            {item: 'SqlLite, room, realm, dbflow'},
                            {item: 'Junit, mockito, mockk'},
                            {item: 'Glide, Picasso'},
                            {item: 'MpChart, Lottie'},
                            {item: 'Custom View'},
                            {item: 'Android studio, Android sdk, Jetpack'},
                            {item: 'Git, gradle, proguard'},
                        ]
                    }
                ]
            },
            {
                id: 2,
                title: 'Back-end',
                src: './../img/Back-end.jpg',
                alt: 'Back-end',
                languages: [
                    {item: 'Kotlin'},
                    {item: 'Java'}
                ],
                services: [
                    {item: 'Postman'},
                    {item: 'Swagger'},
                ],
                technologys: [
                    {
                        list: [
                            {item: 'Git'},
                            {item: 'Alf resco'},
                            {item: 'SQL'},
                            {item: 'Apache Solr'},
                            {item: 'Jenkins'},
                            {item: 'REST'},
                            {item: 'Hibernate ORM'},
                            {item: 'JPA'},
                            {item: 'Microservices'},
                            {item: 'Jasper'},
                            {item: 'Elasticsearch'},
                            {item: 'XBRL'},
                            {item: 'Openshift'},
                        ]
                    },
                    {
                        list: [
                            {item: 'JUnit'},
                            {item: 'Cucumber'},
                            {item: 'Spring Boot'},
                            {item: 'Spring Data'},
                            {item: 'Spring Security'},
                            {item: 'Spring Websocket'},
                            {item: 'Spring Web (spring data rest)'},
                            {item: 'Spring AOP'},
                            {item: 'Logback'},
                            {item: 'MySQL (PostgreSQL)'},
                            {item: 'Maven'}
                        ]
                    }
                ]
            },
            {
                id: 3,
                title: 'ReactNative',
                src: './../img/ReactNative.jpg',
                alt: 'ReactNative',
                languages: [
                    {item: 'JavaScript'},
                ],
                services: [
                    {item: 'VK API'},
                    {item: 'CloudPayments'},
                    {item: 'InApp Purchases'},
                ],
                technologys: [
                    {
                        list: [
                            {item: 'MVC'},
                            {item: 'Git'},
                            {item: 'Trello, JIRA'},
                            {item: 'REST, JSON,XML'},
                            {item: 'React Native'},
                            {item: 'React'},
                            {item: 'React navigation'},
                        ]
                    },
                    {
                        list: [
                            {item: 'Redux'},
                            {item: 'Redux-Saga'},
                            {item: 'Redux-Thunk'},
                            {item: 'Redux Persist'},
                            {item: 'Redux Logger'},
                            {item: 'React animations'},
                            {item: 'Jest'},
                        ]
                    }
                ]
            },
        ],
        stages: [
            {
                number: '01',
                title: 'Аналитика',
                subtitle: 'Собираем информацию о продукте, клиенте, конкурентах и близких аналогах.'
            },
            {
                number: '02',
                title: 'Проектирование',
                subtitle: 'Составляем подробную карту экранов приложения c кратким описанием технических решений.'
            },
            {
                number: '03',
                title: 'Дизайн',
                subtitle: 'Разрабатываем дизайн‑концепцию приложения, согласовываем стилистику с заказчиком.'
            },
            {
                number: '04',
                title: 'Разработка',
                subtitle: 'Разрабатываем продукт, соблюдая сроки указанные в договоре.'
            },
            {
                number: '05',
                title: 'Тестирование',
                subtitle: 'Начинаем тестирование с самой первой сборки приложения.'
            },
            {
                number: '06',
                title: 'Поддержка',
                subtitle: 'Выделяем команду, которая будет заниматься развитием и дальнейшей разработкой приложения.'
            }
        ],
        black: true,
        itemsToShow: 8,
        expanded: false
    }

    showMore = () => {
        this.state.itemsToShow === 8 ? (
          this.setState({ itemsToShow: this.state.directions[1].services.length, expanded: true})
        ) : (
          this.setState({ itemsToShow: 8, expanded: false})
        )
    }

    render() {
        const {descriptions, advantages, directions, stages, black, itemsToShow, expanded} = this.state;

        return(

            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions[2]}/>
                    <AdvantagesList advantages={advantages}/>
                    <DirectionsDevelopment
                        directions={directions}
                        showMore={this.showMore}
                        itemsToShow={itemsToShow}
                        expanded={expanded}
                    />
                    <Stages stages={stages}/>
                    <FormHandler black={black}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default MVP