import React from 'react'
import style from './Backdrop.module.css'

const Backdrop = ({zIndex, onClick}) => {

    const  cls = [style.Backdrop];

    if (zIndex) {
        cls.push(style.zIndex)
    } 

    return(
        <div 
            className={cls.join(' ')} 
            onClick={onClick}
        />
    )
};

export default Backdrop