// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Expectations_Expectations__3cg74 {\n    margin: 3.375rem 0 5rem;\n    white-space: pre-wrap;\n}\n.Expectations_Expectations__3cg74 div {\n    margin-bottom: 5rem;\n}\n.Expectations_Expectations__3cg74 h5 {\n    margin-bottom: 1.5625rem;\n}\n.Expectations_Expectations__3cg74 p {\n    font-size: 1.125rem;\n}\n\n@media screen and (max-width: 767px) {\n    .Expectations_Expectations__3cg74 div {\n        margin-bottom: 4rem;\n    }\n    .Expectations_Expectations__3cg74 h5 {\n        margin-bottom: 1.5rem;\n    }\n    .Expectations_Expectations__3cg74 p {\n        font-size: 0.875rem;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Expectations/Expectations.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,qBAAqB;AACzB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,qBAAqB;IACzB;IACA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".Expectations {\n    margin: 3.375rem 0 5rem;\n    white-space: pre-wrap;\n}\n.Expectations div {\n    margin-bottom: 5rem;\n}\n.Expectations h5 {\n    margin-bottom: 1.5625rem;\n}\n.Expectations p {\n    font-size: 1.125rem;\n}\n\n@media screen and (max-width: 767px) {\n    .Expectations div {\n        margin-bottom: 4rem;\n    }\n    .Expectations h5 {\n        margin-bottom: 1.5rem;\n    }\n    .Expectations p {\n        font-size: 0.875rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Expectations": "Expectations_Expectations__3cg74"
};
export default ___CSS_LOADER_EXPORT___;
