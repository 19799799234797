// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TeammateList_TeammateList__PseaB {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    margin-top: 2.5625rem;\n}\n\n@media screen and (max-width: 768px) {\n    .TeammateList_TeammateList__PseaB {\n        justify-content: center;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Teammate/TeammateList/TeammateList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".TeammateList {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    margin-top: 2.5625rem;\n}\n\n@media screen and (max-width: 768px) {\n    .TeammateList {\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TeammateList": "TeammateList_TeammateList__PseaB"
};
export default ___CSS_LOADER_EXPORT___;
