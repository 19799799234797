// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../img/bg_frontend.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReactDeveloper_ReactDeveloper__3l_ar {\n    background: #151515 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 100% 45% no-repeat;\n    padding: 10px 0;\n    overflow: hidden;\n    background-size: 90%;\n}", "",{"version":3,"sources":["webpack://src/pages/ReactDeveloper/ReactDeveloper.module.css"],"names":[],"mappings":"AAAA;IACI,8EAAuE;IACvE,eAAe;IACf,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":[".ReactDeveloper {\n    background: #151515 url(\"../../img/bg_frontend.svg\") 100% 45% no-repeat;\n    padding: 10px 0;\n    overflow: hidden;\n    background-size: 90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReactDeveloper": "ReactDeveloper_ReactDeveloper__3l_ar"
};
export default ___CSS_LOADER_EXPORT___;
