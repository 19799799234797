import React from 'react';
import style from './StagesList.module.css';

import StagesItem from '../StagesItem/StagesItem';

const StagesList = ({stages}) => {

    const elements = stages.map((stage) => {
        return (
            <StagesItem
                key={stage.number}
                {...stage}
            />
        )
    });

    return(
        <div className={style.StagesList}>
            {elements}
        </div>
    )
}

export default StagesList