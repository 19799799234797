import React from 'react';
import style from './ProjectList.module.css';

import ProjectItem from '../ProjectItem/ProjectItem';
import ProjectItemMobile from '../ProjectItemMobile/ProjectItemMobile';

const ProjectList = ({projects, itemsToShow}) => {

    const elements = projects.slice(0, itemsToShow).map((project) => {
        return(
            <ProjectItem
                key={project.id}
                {...project}
            />
        )
    })
    const elementsMobile = projects.slice(0, itemsToShow).map((project) => {
        return(
            <ProjectItemMobile
                key={project.id}
                {...project}
            />
        )
    })

    return(
        <div className={style.ProjectList}>
            {elements}
            {elementsMobile}
        </div>
    )
}

export default ProjectList