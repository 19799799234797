import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import style from './OurProject.module.css';
import projects from '../../json/ourProject.json'

import OurProjectCardList from './OurProjectCardList/OurProjectCardList';
import Button from '../UI/Button/Button';


class OurProject extends Component {
    state = {
        projects: projects,
        itemsToShow: 3,
        noneArrow: true
    }

    showMore = () => {
        this.setState((prev) => {
          return {itemsToShow: prev.itemsToShow + 3};
        });
    }

    render() {

        const {projects, itemsToShow, noneArrow} = this.state;
        const projectsLength = this.state.projects.length;

        return( 
            <div className={style.OurProject}>
                <div className={style.OurProject__title}>
                    <h2>Наши проекты</h2>
                    <div className={style.link}>
                        <Link to='/Project'>Все проекты</Link>
                        <div className={style.arrow}></div>
                    </div>
                </div>
                <OurProjectCardList
                    projects={projects}
                    itemsToShow={itemsToShow}
                    projectsLength={projectsLength}
                />
                <div className={style.OurProject__button}>
                    {itemsToShow < projectsLength &&
                        <Button 
                            onClick={this.showMore}
                            noneArrow={noneArrow}
                        >
                            Показать больше
                        </Button>
                    }
                </div>
            </div>
        )
    }
}

export default OurProject