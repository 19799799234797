import React from 'react';
import { Link } from 'react-router-dom';
import style from './ArticleCardItem.module.css';

const ArticleCardItem = ({id, title, preview, date, time, src, alt}) => {

    const link = `/blog/${id+1}`;
    
    return(
        <div key={id} className={style.ArticleCardItem}>
            <div className={style.ArticleCardItem__img}>
                <img src={src} alt={alt}/>
            </div>
            <div className={style.ArticleCardItem__content}>
                <div className={style.ArticleCardItem__title}>
                    {title}
                </div>
                <div className={style.ArticleCardItem__text}>
                    <p>{preview}</p>
                </div>
                <div className={style.ArticleCardItem__bottom}>
                    <div className={style.ArticleCardItem__bottom_date}>{date}</div>
                    <Link to={link} className={style.ArticleCardItem__bottom_time}>{time}</Link>
                </div>
            </div>
        </div>
    )
}

export default ArticleCardItem