import React, {Component} from 'react';
import style from './TechnicalDocumentation.module.css';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import FormHandler from '../../components/FormHandler/FormHandler';
import Footer from '../../components/Footer/Footer';
import Stages from '../../components/Stages/Stages';
import AdvantagesList from '../../components/AdvantagesList/AdvantagesList';


class TechnicalDocumentation extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Ключевые компетентности',
                subtitle: 'Компания Digital nomads предлагает полный комплекс услуг по проектированию, разработке и сопровождению мобильных приложений на самых популярных платформах'
            },
            {
                id: 1,
                title: 'Разработка мобильных приложений',
                subtitle: 'Более 10 лет работы в сфере разработки мобильных приложений различной специфики позволил нам накопить богатый опыт в проектировании интуитивного и релевантного тематике приложения интерфейса',
                price: 'Стоимость: от 1 500 000 ₽',
                data: 'Сроки: от 2 месяцев'
            },
            {
                id: 2,
                title: 'Дизайн интерфейсов приложений',
                subtitle: 'UX-дизайн отвечает за функциональность, адаптивность продукта и то, какие эмоции он вызывает у пользователей. Чем проще ваш интерфейс, тем проще пользователю получить результат и совершить целевое действие.',
                price: 'Стоимость: от 1 500 ₽ / час'
            },
            {
                id: 3,
                title: 'Создание MVP',
                subtitle: 'MVP — сокращение от «Minimum Viable Product». Цель разработки MVP — быстро проверить основную гипотезу о востребованности приложения среди целевой аудитории. Вместо долгой и дорогой разработки приложения с большим набором функций и сценариев выпустить приложение, хорошо решающее одну пользовательскую задачу.',
                price: 'Стоимость: от 500 000 ₽',
                data: 'Сроки: от 4-х недель'
            },
            {
                id: 4,
                title: 'Аналитика мобильных приложений',
                subtitle: 'Аналитика — один из ключевых элементов для принятия решений в экономике и продвижения мобильных приложений.',
                price: 'Стоимость: от 1 750 ₽ / час'
            },
            {
                id: 5,
                title: 'Формирование команды для реализации поекта',
                subtitle: 'Наш главный офис находится в Томске — сибирской Силиконовой долине. В Томске много специалистов мирового уровня, которые готовы помочь вам в реализации проекта. А мы поможем вам их найти, обучить и сплотить. Обычно команда состоит из middle-разработчика, тимлида/senior-разработчика, QA-инженера и PM парт-тайм.',
                price: 'Стоимость: от 500 000 ₽'
            },
            {
                id: 6,
                title: 'Составление технической документации',
                subtitle: 'Детализированная документация по организационным и проектным решениям для успешного создания и эффективного функционирования всей системы.',
                price: 'Стоимость: от 1200 ₽ / час'
            }
        ],
        advantages: [
            {
                id: 0,
                img: './../img/technical-documentation-1.svg',
                title: 'В ногу со временем',
                subtitle: 'Благодаря сочетанию знаний в современной технической документации, опыта в разработке и проектному мышлению мы помогаем нашим клиентам достичь максимальной эффективности от всей \nсистемы.',
            },
            {
                id: 1,
                img: './../img/technical-documentation-2.svg',
                title: 'Создание роадмэпа',
                subtitle: 'В дорожной карте прописывается план для достижения конечного состояния. Сроки выполнения работ планируются заранее, фактическая работа выполняется по удобному графику.',
            },
            {
                id: 2,
                img: './../img/technical-documentation-3.svg',
                title: 'Экспертность',
                subtitle: 'У наших ведущих специалистов опыт работы более 15 лет. Мы тщательно тестируем код. Наша цель — качественный продукт.',
            }
        ],
        stages: [
            {
                number: '01',
                title: 'Аналитика',
                subtitle: 'Собираем информацию о продукте, клиенте, конкурентах и близких аналогах.'
            },
            {
                number: '02',
                title: 'Анализ',
                subtitle: 'Анализируем код, безопасность серверов, дизайн продукта, рекламные каналы продви- жения. Так же анализируем ситуацию на рынке, собираем информацию о трендах рынка.'
            },
            {
                number: '03',
                title: 'Имплементация',
                subtitle: 'Предоставляем все рекомендации по улучшению или по стратегии запуска продукта.'
            }
        ],
        black: true
    }


    render() {
        const {descriptions, advantages, stages, black} = this.state;

        return(

            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions[6]}/>
                    <AdvantagesList advantages={advantages}/>
                    <Stages stages={stages}/>
                    <FormHandler black={black}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default TechnicalDocumentation