// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BPharmacy_BPharmacy__2yWU7 {\n    background: #151515;\n    overflow: hidden;\n}\n\n@media screen and (max-width: 991px) {\n    .BPharmacy_BPharmacy__preview__223CR {\n        min-height: 1240px;\n    }\n}\n@media screen and (max-width: 767px) {\n    .BPharmacy_BPharmacy__preview__223CR {\n        min-height: 900px;\n    }\n}", "",{"version":3,"sources":["webpack://src/pages/Case/BPharmacy/BPharmacy.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;AACA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".BPharmacy {\n    background: #151515;\n    overflow: hidden;\n}\n\n@media screen and (max-width: 991px) {\n    .BPharmacy__preview {\n        min-height: 1240px;\n    }\n}\n@media screen and (max-width: 767px) {\n    .BPharmacy__preview {\n        min-height: 900px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BPharmacy": "BPharmacy_BPharmacy__2yWU7",
	"BPharmacy__preview": "BPharmacy_BPharmacy__preview__223CR"
};
export default ___CSS_LOADER_EXPORT___;
