import React from 'react';
import TeammateItem from '../TeammateItem/TeammateItem';
import style from './TeammateList.module.css';

const TeammateList = ({team}) => {

  const elements = team.map((item) => {
    return (
      <TeammateItem 
        {...item}
      />
    )
  })

  return (
    <div className={style.TeammateList}>
      {elements}
    </div>
  );
}

export default TeammateList;
