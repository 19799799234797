import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

export const PortfolioPDF = ({pdfSource})=>{
    useEffect(()=>{
        window.open(pdfSource, "_self")
    },
    [])
    return(
        <Redirect to="/" /> 
    )
}
