import React from 'react';
import style from './HistoryItem.module.css';

const HistoryItem = ({onClick, preview, id}) => {

    const action = () => {
        onClick(id);
    }

    return (
        <div className={style.HistoryItem} onClick={action}>
            <img src={preview.src} alt={preview.alt}/>

            <svg width="100%" height="100%" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M170 85C170 131.944 131.944 170 85 170C38.0558 170 0 131.944 0 85C0 38.0558 38.0558 0 85 0C131.944 0 170 38.0558 170 85ZM85 164C128.63 164 164 128.63 164 85C164 41.3695 128.63 6 85 6C41.3695 6 6 41.3695 6 85C6 128.63 41.3695 164 85 164Z" fill="url(#paint0_radial)"/>
                <defs>
                    <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(45 164) rotate(-55.9841) scale(170.712)">
                        <stop offset="0" stopColor="#FFAA00"/>
                        <stop offset="0.499447" stopColor="#FF0000"/>
                        <stop offset="1" stopColor="#DF00A7"/>
                    </radialGradient>
                </defs>
            </svg>

        </div>
    )
}

export default HistoryItem
