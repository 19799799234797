import React from 'react';
import style from './Language.module.css';

const Language = props => {

    const  cls = [style.Language];

    if (props.isOpen) {
        cls.push(style.open)
    } 

    return (
        <div  
            className={cls.join(' ')}
            style={props.style}
        >
            <ul>
                <li>Ru</li>
                <li><span></span></li>
                <li>En</li>
            </ul>
        </div>
    )

}
export default Language