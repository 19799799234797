import React from 'react';
import style from './WorkDetailsList.module.css';
import WorkDetailsItem from '../WorkDetailsItem/WorkDetailsItem';


const WorkDetailsList = ({detailList, title, customClass}) => {

  const leftElements = detailList.map((item, index) => {
    if (index % 2 === 0) {
      item.customClass = customClass ?? '';
      return (
        <WorkDetailsItem
          key={item.id}
          {...item}
        />
      )
    }
  });

  const rightElements = detailList.map((item, index) => {
    if (index % 2 !== 0) {
      item.customClass = customClass ?? '';
      return (
        <WorkDetailsItem
          key={item.id}
          {...item}
        />
      )
    }
  });

  return(
    <div className={style.WorkDetailsList}>
      {title && <div className={style.WorkDetailsList__title}>{title}</div>}
      <div className={`${style.WorkDetailsList__table} ${customClass === 'promises_item' && 'd-flex flex-wrap'}`}>
        <div className={`${style.WorkDetailsList__table_left} ${customClass && style[customClass]}`}>{leftElements}</div>
        <div className={`${customClass && style[customClass]}`}>{rightElements}</div>
      </div>

    </div>
  )
}

export default WorkDetailsList