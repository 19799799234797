// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./img/bg_matrix1.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Montserrat', sans-serif;\n  font-weight: 400;\n  font-size: 1rem;\n  color: #FFFFFF;\n}\n.Matrix-background {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") top no-repeat #151515;\n  background-size: contain;\n  padding: 10px 0;\n  overflow: hidden;\n}\n\n@media screen and (max-width: 414px) {\n  .Matrix-background {\n    background-size: auto;\n  }\n}\n\n", "",{"version":3,"sources":["webpack://src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,qCAAqC;EACrC,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;AACA;EACE,yEAA6D;EAE7D,wBAAwB;EACxB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".App {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Montserrat', sans-serif;\n  font-weight: 400;\n  font-size: 1rem;\n  color: #FFFFFF;\n}\n.Matrix-background {\n  background: url(\"./img/bg_matrix1.png\") top no-repeat #151515;\n  -webkit-background-size: contain;\n  background-size: contain;\n  padding: 10px 0;\n  overflow: hidden;\n}\n\n@media screen and (max-width: 414px) {\n  .Matrix-background {\n    background-size: auto;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
