// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MatrixEffect_MatrixEffect__3GKya {\n    position: relative;\n    z-index: -2;\n    height: 100vh;\n}", "",{"version":3,"sources":["webpack://src/components/MatrixEffect/MatrixEffect.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB","sourcesContent":[".MatrixEffect {\n    position: relative;\n    z-index: -2;\n    height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MatrixEffect": "MatrixEffect_MatrixEffect__3GKya"
};
export default ___CSS_LOADER_EXPORT___;
