import React from 'react';
import { Link } from 'react-router-dom';
import style from './Button.module.css'

const Button = ({children, to, onClick, noneArrow}) => {

    const cls = [style.Button]

    if (noneArrow) {
        cls.push(style.noneArrow)
    }

    return (
        <Link
            className={cls.join(' ')}
            to={to} 
            onClick={onClick}
        >
            <div>
                {children}
                <div className={style.arrow}></div>
            </div>
        </Link>
    )
};

export default Button

