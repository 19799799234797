// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_Footer__238hM {\n    display: flex;\n    align-items: center; /* Центрирование по вертикали */\n    justify-content: center; /* Центрирование по горизонтали */\n    color: #E4E4E4;\n    padding: 3rem 0 4.25rem;\n}\n@media screen and (max-width: 767px) {\n    .Footer_Footer__238hM {\n        flex-direction: column;\n        padding: 2rem 0;\n        align-items: center;\n        text-align: center;\n    }\n    .Footer_Footer__protect__1W01y {\n        font-size: 0.625rem;\n        color: #969696;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,EAAE,+BAA+B;IACpD,uBAAuB,EAAE,iCAAiC;IAC1D,cAAc;IACd,uBAAuB;AAC3B;AACA;IACI;QACI,sBAAsB;QACtB,eAAe;QACf,mBAAmB;QACnB,kBAAkB;IACtB;IACA;QACI,mBAAmB;QACnB,cAAc;IAClB;AACJ","sourcesContent":[".Footer {\n    display: flex;\n    align-items: center; /* Центрирование по вертикали */\n    justify-content: center; /* Центрирование по горизонтали */\n    color: #E4E4E4;\n    padding: 3rem 0 4.25rem;\n}\n@media screen and (max-width: 767px) {\n    .Footer {\n        flex-direction: column;\n        padding: 2rem 0;\n        align-items: center;\n        text-align: center;\n    }\n    .Footer__protect {\n        font-size: 0.625rem;\n        color: #969696;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": "Footer_Footer__238hM",
	"Footer__protect": "Footer_Footer__protect__1W01y"
};
export default ___CSS_LOADER_EXPORT___;
