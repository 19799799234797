import React from 'react';
import style from './OtherServiceBlock.module.css';

import OtherServiceItem from '../OtherServiceBlock/OtherServiceItem/OtherServiceItem'

const OtherServiceBlock = ({otherServices}) => {

    const elements = otherServices.map((item, index) => {
        return (
            <OtherServiceItem
                key={index}
                {...item}
            />
        )
    })

    return(
        <div className={style.OtherServiceBlock}>
            <div className={style.OtherServiceBlock__title}>
                <h4>Другие услуги</h4>
            </div>
            <div className={style.OtherServiceBlock__list}>
                {elements}
            </div>
        </div>
    )
}

export default OtherServiceBlock