import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import ScrollToTop from './components/UI/ScrollToTop/ScrollToTop';

ReactDOM.render(
  <BrowserRouter>
    <App>
      <ScrollToTop/>
      <App />
    </App>
  </BrowserRouter>,
  document.getElementById('root')
);

