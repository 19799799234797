import React, { Component} from 'react';
import DirectionsDevelopmentItem from '../../DirectionsDevelopment/DirectionsDevelopmentItem/DirectionsDevelopmentItem';

import style from './Collapse.module.css'

class Collapses  extends Component {

    state = {
        accordionStates: []
    }

    componentDidMount() {
        // init accordion states
        let newAccordion = [];
        this.props.directions.forEach(element => {
            newAccordion[element.id] = false;
        });
        newAccordion[0] = true;
        this.setState({accordionStates: newAccordion});
    }

    handleClick = (directionId) => {
        let newAccordionState = this.state.accordionStates;
        const currentState = newAccordionState[directionId];

        // clear state for all accordions
        for(let i = 0; i < newAccordionState.length; i++) {
            newAccordionState[i] = false;
            this.setState({accordionStates: newAccordionState[i]});
        }
        newAccordionState[directionId] = !currentState;
        this.setState({accordionStates: newAccordionState});

    };

    render () {

        const {directions, showMore, itemsToShow, expanded} = this.props;

        const trigger = [style.Collapse__trigger]
        trigger.push(style.active)

        const content = [style.Collapse__content]
        content.push(style.open)

        return (
            <>
            {directions.map((direction) => { 
                return(
                    <div className={style.Collapse}>
                        <div className={style.Collapse__item}>
                            <div className={this.state.accordionStates[direction.id] ? trigger.join(' ')
                                : style.Collapse__trigger} onClick={() => this.handleClick(direction.id)}>
                                <h5>{direction.title}</h5>
                                <div className={style.arrow}></div>
                            </div>
                            
                            <div className={this.state.accordionStates[direction.id] ? content.join(' ') 
                                : style.Collapse__content} >    
                                <DirectionsDevelopmentItem
                                    showMore={showMore}
                                    itemsToShow={itemsToShow}
                                    expanded={expanded}
                                    onClick={() => this.handleClick(direction.id)}
                                    arrow={this.state.accordionStates[direction.id] ? 'arrow' : null}
                                    key={direction.id}
                                    {...direction}
                                />
                            </div>
                        </div>
                    </div>
                )
            
            })}
            </>
        ) 
    }
}


export default Collapses;