import React from 'react';
import style from './HistoryGalleryMobile.module.css'

import Stories from 'react-insta-stories';

const HistoryGalleryMobile = ({content, setActive}) => {

    let stories = [];

    content.forEach(item => {
        if (item.type === "video") {
            stories.push({
                url: item.src, 
                type: item.type
            })
        } else {
            stories.push({
                url: item.src, 
            })
        }        
    });

    return (
        <div className={style.HistoryGalleryMobile}>
            <div 
                className={style.HistoryGalleryMobile__Close}
                onClick={setActive}
            ></div>
            <Stories
                stories={stories}
                defaultInterval={1500}
                width={`100%`}
                height={`100%`}
            />
        </div>
        
    )

}
export default HistoryGalleryMobile