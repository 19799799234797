import React from 'react';
import style from './ProjectDetailsList.module.css';

import ProjectDetailsItem from './ProjectDetailsItem/ProjectDetailsItem'

const ProjectDetailsList = ({details, preview}) => {

    const elements = details.map((detail, index) => {
        return(
            <ProjectDetailsItem
                key={index}
                {...detail}
            />
        )
    })

    return(
        <div className={style.ProjectDetailsList}>
            {elements}
        </div>
    ) 
}

export default ProjectDetailsList