// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Company_Company__2WVWO {\n    margin-bottom: 2rem;\n}\n.Company_Company__title__nSCUK {\n    margin-bottom: 2rem;\n}\n\n@media screen and (max-width: 767px) {\n    .Company_Company__2WVWO {\n        margin-bottom: 4rem;\n    }\n}\n\n@media screen and (max-width: 414px) {\n    .Company_Company__title__nSCUK {\n        white-space: pre-wrap;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Company/Company.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".Company {\n    margin-bottom: 2rem;\n}\n.Company__title {\n    margin-bottom: 2rem;\n}\n\n@media screen and (max-width: 767px) {\n    .Company {\n        margin-bottom: 4rem;\n    }\n}\n\n@media screen and (max-width: 414px) {\n    .Company__title {\n        white-space: pre-wrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Company": "Company_Company__2WVWO",
	"Company__title": "Company_Company__title__nSCUK"
};
export default ___CSS_LOADER_EXPORT___;
