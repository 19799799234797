import React from 'react';
import style from './Description.module.css';

const Description = ({description}) => {

    return(
        <div key={description.id} className={style.Description} >
            <h2 className={style.Description__title}>
                {description.title}
            </h2>
            <p className={style.Description__subtitle}>
                {description.subtitle}
            </p>
        </div>
    )
}

export default Description