import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import style from './Navigation.module.css';

const links = [
    {to: '/', label: '', style: style.logo},
    {to: '/about-us', label: 'О компании'},
    {to: '/our-services', label: 'Наши услуги'},
    {to: '/project', label: 'Проекты'},
    {to: '/work-with-us', label: 'Карьера', style: style.work, vacanciesNumber: true},
    {to: '/contact', label: 'Контакты'}
];

class Navigation extends Component {

    setActiveLinkClass(link) {
        return window.location.pathname !== '/' && link.includes(window.location.pathname) ? style.active : ''
    }

    renderLinks() {
        return links.map((link, index) => {
            return (
                <li key={index} className={link.style ?? ''}>
                    <Link to={link.to} className={this.setActiveLinkClass(link.to)}>
                        {link.label !== '' ? link.label : <img src={this.props.logo} alt={'Logo'}/>}
                    </Link>
                </li>
            )
        })
    }

    render() {
        return (
            <div className={style.Navigation}>
                <ul>
                    {this.renderLinks()}
                </ul>
            </div>
        )
    }
}

export default Navigation