import React from 'react';
import { Container } from 'reactstrap';
import style from './RoadMapBlock.module.css';
import RoadMapList from './RoadMapList/RoadMapList';


const RoadMapBlock = ({roadmap, scrolling,widthForRoadItems=55}) => {


    return(
        <div id={'RoadMapBlock'} className={style.RoadMapBlock}> 
            <Container>
                <RoadMapList roadmap={roadmap} scrolling={scrolling} widthForRoadItems={widthForRoadItems}/>
            </Container>
        </div>
    ) 
}

export default RoadMapBlock