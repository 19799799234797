import React, { Component } from 'react';
import style from './Header.module.css';
import logo from '../../img/logo.svg';

import Navigation from '../Navigation/Navigation';
import Language from '../Language/Language';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import Menu from '../Menu/Menu';


class Header extends Component {

    state = {
        menu: false,
        opacity: '0',
        scrolling: false,
    };

    componentDidMount() {
        document.getElementById('Menu').addEventListener('scroll', this.handleScroll);

        const vacanciesLength = 4;
        this.setState({vacanciesNumber: vacanciesLength})
    }
    componentWillUnmount() {
        document.getElementById('Menu').removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const scrollY = document.getElementById('Menu').scrollTop;
        if (scrollY === 0 && this.state.scrolling) {
            this.setState({scrolling: false, opacity: '1'});
        }
        else if (scrollY >= 80 && !this.state.scrolling) {
            this.setState({scrolling: true, opacity: '0'});
        }
    }

    updateBodyStyles = () => {
        if (this.state.menu) {
            document.body.style.overflow = "hidden";
            this.setState({opacity: '1'});
        } else {
            document.body.style.overflow = "visible";
            this.setState({opacity: '0'});
        }
    };

    toggleMenuHandler = () => {
        this.setState({
            menu: !this.state.menu
        }, this.updateBodyStyles)
    };

    menuCloseHandler = () => {
        this.setState({
            menu: false
        });
        document.body.style.overflow = "visible"; 
        this.setState({scrolling: false, opacity: '0'});
    };

    render() {
        return (
            <header className={style.Header}>
                {!this.state.menu ? <Navigation logo={logo}/> : null}
                <div className={style.Header__right}>
                    <Language
                        isOpen={this.state.menu}
                        style={{ opacity: `${this.state.opacity}`}}
                    />
                    <BurgerMenu
                      isOpen={this.state.menu}
                      onToggle={this.toggleMenuHandler}
                    />
                </div>
                <Menu
                    isOpen={this.state.menu}
                    onClose={this.menuCloseHandler}
                />
            </header>
        )
    }
}
export default Header