import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import style from './TabsTeam.module.css';
import TeammateList from '../../Teammate/TeammateList/TeammateList';

const TabsTeam = ({team}) => {

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const tabs = () => {
    const keys =  Object.keys(team);
    let tabList = [];
    for( let i = 1; i <= keys.length; i++) {
        tabList.push(            
            <TabPane tabId={`${i}`}>
                <TeammateList 
                  key={i}
                  team={team[keys[i-1]]}
                />
            </TabPane>
        )
    }
    return tabList;
  }

  return (
    <div id={style.TabsTeam} className={style.TabsTeam}>
      <Nav className={style.TabsTeam__ul}>
        <NavItem className={style.TabsTeam__li}>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            iOS
          </NavLink>
        </NavItem>
        <NavItem className={style.TabsTeam__li}>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Android
          </NavLink>
        </NavItem>
        <NavItem className={style.TabsTeam__li}>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Back-end
          </NavLink>
        </NavItem>
        <NavItem className={style.TabsTeam__li}>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
            Front-end
          </NavLink>
        </NavItem>
        <NavItem className={style.TabsTeam__li}>
          <NavLink
            className={classnames({ active: activeTab === '5' })}
            onClick={() => { toggle('5'); }}
          >
            Тестировщики
          </NavLink>
        </NavItem>
        <NavItem className={style.TabsTeam__li}>
          <NavLink
            className={classnames({ active: activeTab === '6' })}
            onClick={() => { toggle('6'); }}
          >
            Руководство
          </NavLink>
        </NavItem>
        <NavItem className={style.TabsTeam__li}>
          <NavLink
            className={classnames({ active: activeTab === '7' })}
            onClick={() => { toggle('7'); }}
          >
            Категория
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
          {tabs()}
      </TabContent>
    </div>
  );
}

export default TabsTeam;
