import React from 'react';
import style from './ArticleContent.module.css';

const ArticleContent = ({id, title, text, date, time, src, alt, scrollTop}) => {

    return(
        <div key={id} className={style.ArticleContent}>
            <div className={style.ArticleContent__img}>
                <img src={src} alt={alt}/>
            </div>
            <div className={style.ArticleContent__container}>
                <div className={style.ArticleContent__title}>
                    {title}
                </div>
                <div className={style.ArticleContent__subtitle}>
                    <div className={style.ArticleContent__subtitle_date}>{date}</div>
                    <div className={style.ArticleContent__subtitle_time}>{time}</div>
                </div>
                <div className={style.ArticleContent__text}>
                    <p>{text}</p>
                </div>
                <div className={style.ArticleContent__button} onClick={scrollTop}>
                    <div className={style.ArticleContent__arrow}></div>
                </div>
            </div>
        </div>
    )

}

export default ArticleContent