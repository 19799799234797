import React, { useRef, useEffect } from 'react';
import style from './MatrixEffect.module.css';

const MatrixEffect = props => {

  const canvasRef = useRef(null),
        w = window.innerWidth,
        h = window.innerHeight;

  const state = {
    fps: 25,
    color: "#0f0",
    charset: "А小0В-Г1Д=Е2八 上3З 九4中 К5木 М6Н О1了 Р8С Т9У Ф!Х Ц?Ч 月.ЩЪ,土 夕:大Ю;下"
  };

  const draw = (ctx, random, p, h, w) => {
    ctx.fillStyle = "rgba(0,0,0,.07)";
    ctx.fillRect(0, 0, w, h);
    ctx.fillStyle = state.color;
    ctx.font = 18 + "px system-ui";

    for (let i = 0; i < p.length; i++) {
      let v = p[i];
      ctx.fillText(random(state.charset), i * 26, v * 2);
      p[i] = v >= h || v >= 9750 * Math.random() ? 0 : v + 10;
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    let p;
    const resize = () => {
      p = Array(Math.ceil(w / 10)).fill(0);
    };
    window.addEventListener("resize", resize);
    resize();

    const random = (items) => items[Math.floor(Math.random() * items.length)];

    let interval = setInterval(() => {draw(context, random, p, h, w)}, 1000 / state.fps);
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {draw(context, random, p, h, w)}, 1000 / state.fps);

    window.addEventListener('resize', () => window.location.reload());
  });

  return <canvas className={style.MatrixEffect} ref={canvasRef} {...props} width={w} height={h}/>
}

export default MatrixEffect