import React, {Component} from 'react';
import style from './ArticlesCard.module.css';

import Button from '../UI/Button/Button';
import ArticleCardList from './ArticleCardList/ArticleCardList';


class ArticlesCard extends Component {
    state = {
        articles: this.props.articles,
        itemsToShow: 8,
        noneArrow: true
    }

    showMore = () => {
        this.setState((prev) => {
          return {itemsToShow: prev.itemsToShow + 4};
        });
    }

    render() {

        const {articles, itemsToShow, noneArrow} = this.state;
        const articlesLength = this.state.articles.length;

        return( 
            <div className={style.ArticlesCard}>
                <ArticleCardList 
                    articles={articles}
                    itemsToShow={itemsToShow}
                    articlesLength={articlesLength}
                />
                <div className={style.ArticlesCard__button}>
                    {itemsToShow < articlesLength &&
                        <Button 
                            onClick={this.showMore}
                            noneArrow={noneArrow}
                        >
                            Показать больше
                        </Button>
                    }
                </div>
            </div>
        )
    }
}

export default ArticlesCard