import React , {Component} from 'react';
import {Link} from 'react-router-dom';
import style from './History.module.css';

import Gallery from '../Gallery/Gallery';
import HistorySlider from '../UI/HistorySlider/HistorySlider';
import HistoryGalleryMobile from '../HistoryGalleryMobile/HistoryGalleryMobile';


class History extends Component {

    state = {
        activeHistory: false,
        activeHistoryId: null,
        isMobile: false,
        history: [
            {
                "id": "0",
                "preview": {
                    "src": "../../../img/history/history-1.jpg",
                    "alt": "История 1"
                },
                "content": [
                    {
                        "id": "0",
                        "type": "photo",
                        "src": "../../../img/history/history-1-1.jpg"
                    },
                    {
                        "id": "1",
                        "type": "photo",
                        "src": "../../../img/history/history-1-2.jpg"
                    },
                    {
                        "id": "2",
                        "type": "photo",
                        "src": "../../../img/history/history-1-3.jpg"
                    }
                ] 
            },
            {
                "id": "1",
                "preview": {
                    "src": "../../../img/history/history-2.jpg",
                    "alt": "История 2"
                },
                "content": [
                    {
                        "id": "0",
                        "type": "photo",
                        "src": "../../../img/history/history-2-1.jpeg"
                    },
                    {
                        "id": "1",
                        "type": "photo",
                        "src": "../../../img/history/history-2-2.jpeg"
                    },
                    {
                        "id": "2",
                        "type": "photo",
                        "src": "../../../img/history/history-2-3.jpeg"
                    }
                ] 
            },
            {
                "id": "2",
                "preview": {
                    "src": "../../../img/history/history-3.jpg",
                    "alt": "История 3"
                },
                "content": [
                    {
                        "id": "0",
                        "type": "photo",
                        "src": "../../../img/history/history-3-1.jpg"
                    },
                    {
                        "id": "1",
                        "type": "photo",
                        "src": "../../../img/history/history-3-2.jpg"
                    },
                    {
                        "id": "2",
                        "type": "photo",
                        "src": "../../../img/history/history-3-3.jpg"
                    }
                ] 
            },
            {
                "id": "3",
                "preview": {
                    "src": "../../../img/history/history-4.jpg",
                    "alt": "История 4"
                },
                "content": [
                    {
                        "id": "0",
                        "type": "photo",
                        "src": "../../../img/history/history-4-1.jpg"
                    },
                    {
                        "id": "1",
                        "type": "photo",
                        "src": "../../../img/history/history-4-2.jpg"
                    },
                    {
                        "id": "2",
                        "type": "photo",
                        "src": "../../../img/history/history-4-3.jpg"
                    }
                ] 
            },
            {
                "id": "4",
                "preview": {
                    "src": "../../../img/history/history-5.jpg",
                    "alt": "История 5"
                },
                "content": [
                    {
                        "id": "0",
                        "type": "photo",
                        "src": "../../../img/history/history-5-1.jpg"
                    },
                    {
                        "id": "1",
                        "type": "photo",
                        "src": "../../../img/history/history-5-2.jpg"
                    },
                    {
                        "id": "2",
                        "type": "photo",
                        "src": "../../../img/history/history-5-3.jpg"
                    }
                ] 
            },
            {
                "id": "5",
                "preview": {
                    "src": "../../../img/history/history-6.jpg",
                    "alt": "История 6"
                },
                "content": [
                    {
                        "id": "0",
                        "type": "photo",
                        "src": "../../../img/history/history-6-1.jpg"
                    },
                    {
                        "id": "1",
                        "type": "photo",
                        "src": "../../../img/history/history-6-2.jpg"
                    },
                    {
                        "id": "2",
                        "type": "photo",
                        "src": "../../../img/history/history-6-3.jpg"
                    }
                ] 
            },
            // {
            //     "id": "6",
            //     "preview": {
            //         "src": "../../../img/history/history-1.jpg",
            //         "alt": "История 6"
            //     },
            //     "content": [
            //         {
            //             "id": "0",
            //             "type": "photo",
            //             "src": "../../../img/history/history-1-1.jpg"
            //         },
            //         {
            //             "id": "1",
            //             "type": "photo",
            //             "src": "../../../img/history/history-1-2.jpg"
            //         },
            //         {
            //             "id": "2",
            //             "type": "photo",
            //             "src": "../../../img/history/history-1-3.jpg"
            //         }
            //     ]
            // }
        ]
    }

    componentDidMount() {
        this.resize();

        // fetch("http://localhost:3000/json/history.json")
        // .then(response => response.json())
        // .then(json => {
        //   console.log(json);
        //   this.setState({
        //     history: json
        //   });
        // });
    }
    
    resize = () => {
        if (window.innerWidth <= 768) {
            this.setState({isMobile: true});
        } else {
            this.setState({isMobile: false});
        }
    }
    
    toggle = (id) => {
        this.setState({activeHistory: !this.state.activeHistory})
        this.setState({activeHistoryId: id})
    };

    getContentById = () => {
        // // todo make safety
        const histories = this.state.history.filter(item => item.id === this.state.activeHistoryId);
        return histories.length ? histories[0].content : [];
    };

    render() {

        const {history, activeHistory, isMobile} = this.state;

        return(
            <div className={style.History}>
                <HistorySlider
                    onClick={this.toggle}
                    history={history}
                />
                <div className={this.props.link ? style.link : style.linkNone}>
                    <Link to='/work-with-us'>Стать частью команды</Link>
                    <div className={style.arrow}></div>
                </div>
                { activeHistory ? 
                <>
                    { !isMobile ?  
                        <Gallery 
                            content={this.getContentById()} 
                            setActive={this.toggle} 
                            active={activeHistory} 
                        />
                    : 
                        <HistoryGalleryMobile 
                            content={this.getContentById()}
                            setActive={this.toggle} 
                            active={activeHistory} 
                        />  
                    }
                </> : null 
                }
            </div>
        )
    }

}

export default History