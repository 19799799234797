import React, { Component } from 'react';
import { Container } from 'reactstrap';


import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Description from '../../components/Description/Description';
import Social from '../../components/Social/Social';
import ContactFaces from '../../components/ContactFaces/ContactFaces';
import Requisites from "../Requisites/Requisites";

class Contact extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Контакты'
            }
        ]
    }

    render() {

        const {descriptions} = this.state;

        return(
            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions[0]}/>
                    <ContactFaces/>
                    <Requisites/>
                    <Social/>
                    <Footer/>
                </Container>
            </div>
        )
    }

}

export default Contact