import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import style from './Tabs.module.css';
import DirectionsDevelopmentItem from '../../DirectionsDevelopment/DirectionsDevelopmentItem/DirectionsDevelopmentItem';

const Tabs = ({directions, showMore, itemsToShow, expanded}) => {

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const elements = directions.map((direction) => {
      return (
        <DirectionsDevelopmentItem
            showMore={showMore}
            itemsToShow={itemsToShow}
            expanded={expanded}
            key={direction.id}
            {...direction}
        />
      )
  })

  return (
    <div id={style.Tabs} className={style.Tabs}>
      <Nav className={style.Tabs__ul}>
        <NavItem className={style.Tabs__li}>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            iOS
          </NavLink>
        </NavItem>
        <NavItem className={style.Tabs__li}>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Android
          </NavLink>
        </NavItem>
        <NavItem className={style.Tabs__li}>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Back-end
          </NavLink>
        </NavItem>
        <NavItem className={style.Tabs__li}>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
            React Native
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
            {elements[0]}
        </TabPane>
        <TabPane tabId="2">
            {elements[1]}
        </TabPane>
        <TabPane tabId="3">
            {elements[2]}
        </TabPane>
        <TabPane tabId="4">
            {elements[3]}
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Tabs;
