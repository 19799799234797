import React, {Component} from 'react';

import Modal from '../UI/Modal/Modal';
import Form from '../UI/Form/Form';
import FormRequest from '../UI/FormRequest/FormRequest';
import FormResume from '../UI/FormResume/FormResume';
import Successful from '../UI/Successful/Successful';

export default class FormHandler extends Component {

    state = {
        successful: null,
        activeModal: false,
        noneArrow: true
    }

    updateBodyStyles = () => {
        if (this.state.activeModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "visible";
        }
    };

    toggleModal = () => {
        this.setState({activeModal: !this.state.activeModal},
        this.updateBodyStyles)
    }

    processResponse = ((resp) => {
        this.setState({successful: resp.status === 200});
        this.toggleModal();
    });

    renderSwitch = (param) => {
        switch(param) {
            case 'formResume':
                return <FormResume method={this.processResponse}/>;
            case 'formRequest':
                return <FormRequest method={this.processResponse}/>;
            default:
                return <Form black={this.props.black} method={this.processResponse}/>;
        }
    }

    render() {
        return (
            <>
                {this.renderSwitch(this.props.form)}
                
                {this.state.successful == null ? null 
                : 
                <Modal
                    active={this.state.activeModal}
                    setActive={this.toggleModal}
                    successful={this.state.successful}
                >
                    <Successful 
                        successful={this.state.successful}
                        noneArrow={this.state.noneArrow}
                        onClick={this.toggleModal}
                    />
                </Modal>
                }
            </>

        )
    }

};