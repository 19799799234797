import React, { Component } from 'react';
import CompanySlider from '../UI/CompanySlider/CompanySlider';
import style from './Company.module.css';

class Company extends Component {

    state = {
        img: [
            {
                id: 0,
                src: '../../img/partners/tinkoff.png',
                alt: 'Тинькофф'
            },
            {
                id: 1,
                src: '../../img/partners/yandex.png',
                alt: 'Яндекс'
            },
            {
                id: 2,
                src: '../../img/partners/tsu.png',
                alt: 'ТГУ'
            },
            {
                id: 3,
                src: '../../img/partners/iway.png',
                alt: 'i way'
            },
            {
                id: 4,
                src: '../../img/partners/vestfrost.png',
                alt: 'vestfrost'
            },
            {
                id: 5,
                src: '../../img/partners/zoomtv.png',
                alt: 'zoom TV'
            },
            {
                id: 6,
                src: '../../img/partners/insky.png',
                alt: 'insky digital'
            },
            {
                id: 7,
                src: '../../img/partners/traders.png',
                alt: 'united traders'
            },
            {
                id: 8,
                src: '../../img/partners/ais.png',
                alt: 'AIS Engineering'
            },
            {
                id: 9,
                src: '../../img/partners/luxury.png',
                alt: 'Luxury travel magazine'
            }
        ],
        title: 'Компании \nс которыми \nмы работаем'
    }

    render(){
        return(
            <div className={style.Company}>
                <h2 className={style.Company__title}>{this.state.title}</h2>
                <CompanySlider img={this.state.img}/>
            </div>
        )
    }

}

export default Company