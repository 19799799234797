import React from 'react';
import style from './Expectations.module.css';

const Expectations = ({expectations, plus}) => {

    const listExpectations = expectations.list.map((expectation) => {
        return (
            <li key={expectation.id}>
                <p>{expectation.item}</p>
            </li>
        )
    })

    return(
        <div className={style.Expectations}>
            <div className={style.Expectations__block}>
                <h5>Что мы от тебя ждём?</h5>
                <ul>
                    {listExpectations}
                </ul>
            </div>
            {
                plus ?      
                    <div className={style.Expectations__plus} >
                        <h5>Будет плюсом:</h5>
                        <ul>
                            {plus.list.map((item) => ( 
                                <li key={item.id}>
                                    <p>{item.item}</p>
                                </li>) 
                            )}
                        </ul>
                    </div>
                : null

            }
       
        </div>
    )
}

export default Expectations