// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GallerySlideItem_GallerySlideItem__3-qBM {\n    position: relative;\n    width: 100%;\n    min-height: 500px;\n}\n.GallerySlideItem_GallerySlideItem__content__1pMqG {\n    width: 100%;\n    height: 100%;\n    text-align: -webkit-center;\n}\n", "",{"version":3,"sources":["webpack://src/components/Gallery/GallerySlideItem/GallerySlideItem.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,YAAY;IACZ,0BAA0B;AAC9B","sourcesContent":[".GallerySlideItem {\n    position: relative;\n    width: 100%;\n    min-height: 500px;\n}\n.GallerySlideItem__content {\n    width: 100%;\n    height: 100%;\n    text-align: -webkit-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GallerySlideItem": "GallerySlideItem_GallerySlideItem__3-qBM",
	"GallerySlideItem__content": "GallerySlideItem_GallerySlideItem__content__1pMqG"
};
export default ___CSS_LOADER_EXPORT___;
