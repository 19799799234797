// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArticlesCard_ArticlesCard__NA8fv {\n    margin-bottom: 7.5rem;\n}\n.ArticlesCard_ArticlesCard__button__1APTY {\n    width: 12.875rem;\n    margin: 0 auto;\n}\n\n@media screen and (max-width: 767px) {\n    .ArticlesCard_ArticlesCard__button__1APTY  {\n        width: 11.5rem;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/ArticlesCard/ArticlesCard.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".ArticlesCard {\n    margin-bottom: 7.5rem;\n}\n.ArticlesCard__button {\n    width: 12.875rem;\n    margin: 0 auto;\n}\n\n@media screen and (max-width: 767px) {\n    .ArticlesCard__button  {\n        width: 11.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ArticlesCard": "ArticlesCard_ArticlesCard__NA8fv",
	"ArticlesCard__button": "ArticlesCard_ArticlesCard__button__1APTY"
};
export default ___CSS_LOADER_EXPORT___;
