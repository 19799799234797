import React from 'react';
import style from './WorkDetailsItem.module.css';

const WorkDetailsItem = (item) => {
    return (
    <div className={`${style.WorkDetailsItem} ${item.customClass && style[item.customClass]}`}>
        <svg className={`${style.WorkDetailsItem__icon} ${item.customClass && style['promises_icon']}`}>
            <use xlinkHref={item.url}>
            </use>
        </svg>
        <p>{item.text}</p>
    </div>
  )
}

export default WorkDetailsItem