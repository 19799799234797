// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SteamaticAustralia_SteamaticAustralia__3hoG9 {\n    background: #151515;\n    overflow: hidden;\n}\n\n@media screen and (max-width: 991px) {\n    .SteamaticAustralia_SteamaticAustralia__preview__2R7oC {\n        min-height: 1360px;\n    }\n}\n@media screen and (max-width: 767px) {\n    .SteamaticAustralia_SteamaticAustralia__preview__2R7oC {\n        min-height: 900px;\n    }\n}\n@media screen and (max-width: 550px) {\n    .SteamaticAustralia_SteamaticAustralia__preview__2R7oC {\n        min-height: 960px;\n    }\n}\n@media screen and (max-width: 375px) {\n    .SteamaticAustralia_SteamaticAustralia__preview__2R7oC {\n        min-height: 980px;\n    }\n}\n@media screen and (max-width: 320px) {\n    .SteamaticAustralia_SteamaticAustralia__preview__2R7oC {\n        min-height: 1060px;\n    }\n}", "",{"version":3,"sources":["webpack://src/pages/Case/SteamaticAustralia/SteamaticAustralia.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;AACA;IACI;QACI,iBAAiB;IACrB;AACJ;AACA;IACI;QACI,iBAAiB;IACrB;AACJ;AACA;IACI;QACI,iBAAiB;IACrB;AACJ;AACA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".SteamaticAustralia {\n    background: #151515;\n    overflow: hidden;\n}\n\n@media screen and (max-width: 991px) {\n    .SteamaticAustralia__preview {\n        min-height: 1360px;\n    }\n}\n@media screen and (max-width: 767px) {\n    .SteamaticAustralia__preview {\n        min-height: 900px;\n    }\n}\n@media screen and (max-width: 550px) {\n    .SteamaticAustralia__preview {\n        min-height: 960px;\n    }\n}\n@media screen and (max-width: 375px) {\n    .SteamaticAustralia__preview {\n        min-height: 980px;\n    }\n}\n@media screen and (max-width: 320px) {\n    .SteamaticAustralia__preview {\n        min-height: 1060px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SteamaticAustralia": "SteamaticAustralia_SteamaticAustralia__3hoG9",
	"SteamaticAustralia__preview": "SteamaticAustralia_SteamaticAustralia__preview__2R7oC"
};
export default ___CSS_LOADER_EXPORT___;
