// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../img/RoadMapBg.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RoadMapBlock_RoadMapBlock__1UGBp {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") left no-repeat;\n    margin-top: 4rem;\n    height: 100%;\n    padding-bottom: 7.5rem;\n}\n\n@media screen and (max-width: 991px) {\n    .RoadMapBlock_RoadMapBlock__1UGBp {\n        margin: 2rem 0;\n    }\n}\n@media screen and (max-width: 767px) {\n    .RoadMapBlock_RoadMapBlock__1UGBp {\n        background: none;\n        min-height: 100%;\n        padding-bottom: 0;\n    }\n}\n@media screen and (max-width: 414px) {\n    .RoadMapBlock_RoadMapBlock__1UGBp {\n        margin: 1rem 0;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/RoadMapBlock/RoadMapBlock.module.css"],"names":[],"mappings":"AAAA;IACI,kEAAyD;IACzD,gBAAgB;IAChB,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;AACA;IACI;QACI,gBAAgB;QAChB,gBAAgB;QAChB,iBAAiB;IACrB;AACJ;AACA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".RoadMapBlock {\n    background: url('../../img/RoadMapBg.svg') left no-repeat;\n    margin-top: 4rem;\n    height: 100%;\n    padding-bottom: 7.5rem;\n}\n\n@media screen and (max-width: 991px) {\n    .RoadMapBlock {\n        margin: 2rem 0;\n    }\n}\n@media screen and (max-width: 767px) {\n    .RoadMapBlock {\n        background: none;\n        min-height: 100%;\n        padding-bottom: 0;\n    }\n}\n@media screen and (max-width: 414px) {\n    .RoadMapBlock {\n        margin: 1rem 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RoadMapBlock": "RoadMapBlock_RoadMapBlock__1UGBp"
};
export default ___CSS_LOADER_EXPORT___;
