// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OurProjectCardList_OurProjectCardList__3Myt8 {\n    position: relative;\n}\n.OurProjectCardList_OurProjectCardList__3Myt8::after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 430px;\n    background: linear-gradient(180deg, rgba(0, 0, 0, 0) -5.45%, #151515 100%);\n}\n.OurProjectCardList_OurProjectCardList__3Myt8.OurProjectCardList_boxShadow__3vDnr::after  {\n    display: none;\n}", "",{"version":3,"sources":["webpack://src/components/OurProject/OurProjectCardList/OurProjectCardList.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,aAAa;IACb,0EAA0E;AAC9E;AACA;IACI,aAAa;AACjB","sourcesContent":[".OurProjectCardList {\n    position: relative;\n}\n.OurProjectCardList::after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 430px;\n    background: linear-gradient(180deg, rgba(0, 0, 0, 0) -5.45%, #151515 100%);\n}\n.OurProjectCardList.boxShadow::after  {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OurProjectCardList": "OurProjectCardList_OurProjectCardList__3Myt8",
	"boxShadow": "OurProjectCardList_boxShadow__3vDnr"
};
export default ___CSS_LOADER_EXPORT___;
