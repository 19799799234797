import React from 'react';
import Button from '../Button/Button';
import style from './Successful.module.css';

const Successful = ({successful, noneArrow, onClick}) => {

    const cls = [style.Successful];

    if (successful) {
        cls.push(style.open)
    }

    return(
        <div className={cls.join(' ')}>
            <div className={style.Successful__title}>
                <h3>Спасибо!</h3>
            </div>
            <div className={style.Successful__subtitle}>
                <p>Мы свяжемся с вами в ближайшее время</p>
            </div>
            <div className={style.Successful__button}>
                <Button 
                    noneArrow={noneArrow}
                    onClick={onClick} 
                >
                    Хорошо
                </Button>
            </div>
        </div>
    )
}

export default Successful
