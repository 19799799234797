import React, {Component} from 'react';
import style from './Project.module.css';
import { Container } from 'reactstrap';
import projects from '../../json/ourProject.json'

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Description from '../../components/Description/Description';
import ProjectBlock from '../../components/ProjectBlock/ProjectBlock';
import ReviewsSlider from '../../components/UI/ReviewsSlider/ReviewsSlider';


class Project extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Проекты'
            }
        ],
        projects: projects,
        itemsToShow: document.documentElement.clientWidth < 415 ? 4 : 8,
        noneArrow: true,
        filters: [],
        reviews: [
            {
                id: 0,
                text: 'Мы благодарны компании Диджитал Номадс за то, что она предлагает лучшие решения для нашего бизнеса. Ребята погрузились в наш проект как в свой собственный - первоклассная команда. Они слышали нас и быстро перестраивались под наши требования, помогая регулярно внедрять новые функции. Они показали себя не просто подрядчиками, а полноценными партнёрами. Мы рекомендуем компанию Диджитал Номадс как профессионалов в мобильной разработке.',
                autor: 'Валерий Князев',
                position: 'Руководитель ИТС компания Харакири'
            }
        ],
    }

    showMore = () => {
        this.setState((prev) => {
          return {itemsToShow: prev.itemsToShow + 8};
        });
    }

    filterProject = (items) => {
        if (this.state.filters.length === 0) {
            return items;
        } 

        return items.filter(item => {
            let flag = false;
            item.filter.forEach(element => {
                if(this.state.filters.includes(element)) {
                    flag = true;
                }
            });
            return flag;
        });
    }

    onFilterSelect = (name) => {
        if (!this.state.filters.includes(name)) {
            let elements = this.state.filters;
            elements.push(name);
            this.setState({ filters: elements});
            // добавить крестик
        } else {
            const index = this.state.filters.indexOf(name);
            this.state.filters.splice(index, 1);
            this.setState({ filters: this.state.filters});
            // убрать крестик
        }
    }

    render() {
        const {descriptions, projects, itemsToShow, noneArrow, reviews} = this.state;
        const visibleProject = this.filterProject(projects);
        const projectsLength = visibleProject.length;

        return(

            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions[0]}/>
                    <ProjectBlock
                        projects={projects}
                        itemsToShow={itemsToShow}
                        showMore={this.showMore}
                        projectsLength={projectsLength}
                        noneArrow={noneArrow}
                    />
                    <ReviewsSlider reviews={reviews}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default Project