import React, {Component} from 'react';
import style from './MobileTesting.module.css';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import FormHandler from '../../components/FormHandler/FormHandler';
import Footer from '../../components/Footer/Footer';
import Stages from '../../components/Stages/Stages';


class MobileTesting extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Ключевые компетентности',
                subtitle: 'Компания Digital nomads предлагает полный комплекс услуг по проектированию, разработке и сопровождению мобильных приложений на самых популярных платформах'
            },
            {
                id: 1,
                title: 'Разработка мобильных приложений',
                subtitle: 'Более 10 лет работы в сфере разработки мобильных приложений различной специфики позволил нам накопить богатый опыт в проектировании интуитивного и релевантного тематике приложения интерфейса',
                price: 'Стоимость: от 1 500 000 ₽',
                data: 'Сроки: от 2 месяцев'
            },
            {
                id: 2,
                title: 'Дизайн интерфейсов приложений',
                subtitle: 'UX-дизайн отвечает за функциональность, адаптивность продукта и то, какие эмоции он вызывает у пользователей. Чем проще ваш интерфейс, тем проще пользователю получить результат и совершить целевое действие.',
                price: 'Стоимость: от 1 500 ₽ / час'
            },
            {
                id: 3,
                title: 'Создание MVP',
                subtitle: 'MVP — сокращение от «Minimum Viable Product». Цель разработки MVP — быстро проверить основную гипотезу о востребованности приложения среди целевой аудитории. Вместо долгой и дорогой разработки приложения с большим набором функций и сценариев выпустить приложение, хорошо решающее одну пользовательскую задачу.',
                price: 'Стоимость: от 500 000 ₽',
                data: 'Сроки: от 4-х недель'
            },
            {
                id: 4,
                title: 'Аналитика мобильных приложений',
                subtitle: 'Аналитика — один из ключевых элементов для принятия решений в экономике и продвижения мобильных приложений.',
                price: 'Стоимость: от 1 750 ₽ / час'
            },
            {
                id: 5,
                title: 'Формирование команды для реализации поекта',
                subtitle: 'Наш главный офис находится в Томске — сибирской Силиконовой долине. В Томске много специалистов мирового уровня, которые готовы помочь вам в реализации проекта. А мы поможем вам их найти, обучить и сплотить. Обычно команда состоит из middle-разработчика, тимлида/senior-разработчика, QA-инженера и PM парт-тайм.',
                price: 'Стоимость: от 500 000 ₽'
            },
            {
                id: 6,
                title: 'Составление технической документации',
                subtitle: 'Детализированная документация по организационным и проектным решениям для успешного создания и эффективного функционирования всей системы.',
                price: 'Стоимость: от 1200 ₽ / час'
            },
            {
                id: 7,
                title: 'Аутстаффинг',
                subtitle: 'Аутстаффинг интересен тем компаниям, которые уже имеют свои подразделения по разработке программного обеспечения, но испытывают необходимость в дополнительных специалистах требуемой квалификации или хотят временно привлечь разработчиков для выполнения определенного проекта, не вводя их в штат компании.',
                price: 'Стоимость: от 1500 ₽ / час'
            },
            {
                id: 8,
                title: 'Мобильный A/B-тестинг',
                subtitle: 'Мобильное A/B тестирование — это процесс проведения эксперимента в мобильном приложении. Мы делаем выводы и внедряем те или иные изменения только тогда, когда тест достигает статистических значений.',
                price: 'Стоимость: от 100 000 ₽',
                data: 'Срок реализации: от 2-х недель'
            }
        ],
        stages: [
            {
                number: '01',
                title: 'Формулируем гипотезы',
                subtitle: 'Например, проверяем эффективность удержания новых пользователей. Гипотеза заключается в том, что пользователям легче понять упрощенные визуальные элементы в тьюториале. Это приведёт к увеличению удержания пользователей.'
            },
            {
                number: '02',
                title: 'Выбираем ЦА',
                subtitle: 'Выбираем сегменты ЦА и на их основе проверяем гипотезы: количество/качество текста/изображений.'
            },
            {
                number: '03',
                title: 'Проведение эксперимента и сбор данных',
                subtitle: 'Разрабатываем дизайн-концепцию приложения, согласовываем стилистику с заказчиком.'
            },
            {
                number: '04',
                title: 'Анализ результатов',
                subtitle: 'Анализируем и предоставляем результаты А/B теста, а затем реализуем улучшения вашего продукта.'
            }
        ],
        black: true
    }


    render() {
        const {descriptions, stages, black} = this.state;

        return(

            <div className={style.MobileTesting}>
                <Container>
                    <Header/>
                    <Description description={descriptions[8]}/>
                    <Stages stages={stages}/>
                    <FormHandler black={black}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default MobileTesting