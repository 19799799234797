import React from 'react';
import RoadMapItem from '../RoadMapItem/RoadMapItem';
import style from './RoadMapList.module.css';

const RoadMapList = ({roadmap, scrolling, widthForRoadItems}) => {

    const elements = roadmap.map((item, index) => {
        return(
            <RoadMapItem
                widthForRoadItems={widthForRoadItems}
                key={index}
                stages={item.stages}
                scrolling = {scrolling}
                {...item}
            />
        )
    })

    return(
        <div className={style.RoadMapList} id={'RoadMapList'}>
            <div className={style.RoadMapList__line} style={{ height: `${scrolling}%`}}></div>
            {elements}
        </div>
    ) 
}

export default RoadMapList
