import React, {Component} from 'react';
import style from './Banner.module.css';

import Modal from '../UI/Modal/Modal';
import Form from '../UI/Form/Form';
import History from '../History/History';
import Successful from '../UI/Successful/Successful';


export default class Banner extends Component {

    state = {
        successful: null,
        activeModal: false,
        noneArrow: true,
        link: true,
        title: 'Меняем \nпредставление \nо работе и формируем эффективные команды'
    }

    toggleModal = () => {
        this.setState({activeModal: !this.state.activeModal})
        if (!this.state.activeModal) {
            this.setState({successful: null})
        }
    }

    processResponse = ((resp) => {
        this.setState({successful: resp.status === 200});
    });

    render() {

        const {successful, activeModal, noneArrow, link} = this.state
        
        return(
            <div className={style.Banner}>
                <h1 className={style.Banner__title}>{this.state.title}</h1>
                <div className={style.Banner__description}>
                    <p>
                        Digital nomads — команда единомышленников и профессионалов,
                        готовых четко выполнять свои задачи в том месте, где им комфортно.
                        Мы объединены общей идеей и формируем целые команды для решения задач клиента.
                    </p>
                </div>
                {/* Modal */}
                    <Modal
                        active={activeModal}
                        setActive={this.toggleModal}
                        successful={successful}
                    >
                    { successful == null ? <Form method={this.processResponse} toggleModal={this.toggleModal}/> 
                    : <Successful 
                        successful={successful}
                        noneArrow={noneArrow}
                        onClick={this.toggleModal}
                    />}
                    </Modal>
                {/* Modal */}
                <div className={style.Banner__history}>
                    <History link={link}/>
                </div>
            </div>
        ) 
    }
}
