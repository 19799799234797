import React from 'react';
import AdvantagesItem from './AdvantagesItem/AdvantagesItem';
import style from './AdvantagesList.module.css';

const AdvantagesList = ({advantages}) => {

    const elements = advantages.map( advantage => {
        return (
            <AdvantagesItem 
                key={advantage.id}
                {...advantage}
            />
        )
    })

    return(

        <div className={style.AdvantagesList}>
            {elements}
        </div>
    )
}

export default AdvantagesList