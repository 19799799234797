import React from 'react';
import style from './AdvantagesItem.module.css';

const AdvantagesItem = ({img, title, subtitle}) => {

    return(

        <div className={style.AdvantagesItem}>
            <img src={img} alt={title}/>
            <h5 className={style.AdvantagesItem__title}>
                {title}
            </h5>
            <p className={style.AdvantagesItem__subtitle}>
                {subtitle}
            </p>
        </div>
    )
}

export default AdvantagesItem