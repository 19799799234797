// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Harakiri_Harakiri__1LR8x {\n    background: #151515;\n    overflow: hidden;\n}\n[class*=\"Harakiri\"] [class*=RoadMapItem__stages ]> div:first-child{\n    width: 45%;\n}\n\n[class^=\"Harakiri\"] [class*=RoadMapItem__stages ] > div:nth-child(2) {\nwidth: 42%;\n} \n\n@media screen and (max-width: 991px) {\n    [class*=\"Harakiri\"] [class*=RoadMapItem__stages ]> div:first-child{\n        width: 100%;\n    }\n    \n    [class^=\"Harakiri\"] [class*=RoadMapItem__stages ] > div:nth-child(2) {\n    width: 100%;\n    } \n}\n", "",{"version":3,"sources":["webpack://src/pages/Case/Harakiri/Harakiri.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;;AAEA;AACA,UAAU;AACV;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;IACA,WAAW;IACX;AACJ","sourcesContent":[".Harakiri {\n    background: #151515;\n    overflow: hidden;\n}\n[class*=\"Harakiri\"] [class*=RoadMapItem__stages ]> div:first-child{\n    width: 45%;\n}\n\n[class^=\"Harakiri\"] [class*=RoadMapItem__stages ] > div:nth-child(2) {\nwidth: 42%;\n} \n\n@media screen and (max-width: 991px) {\n    [class*=\"Harakiri\"] [class*=RoadMapItem__stages ]> div:first-child{\n        width: 100%;\n    }\n    \n    [class^=\"Harakiri\"] [class*=RoadMapItem__stages ] > div:nth-child(2) {\n    width: 100%;\n    } \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Harakiri": "Harakiri_Harakiri__1LR8x"
};
export default ___CSS_LOADER_EXPORT___;
