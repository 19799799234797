import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import style from './Article.module.css';
import { Container } from 'reactstrap';
import articles from '../../json/articles.json';

import Header from '../../components/Header/Header';
import FormHandler from '../../components/FormHandler/FormHandler';
import Footer from '../../components/Footer/Footer';
import ArticleContent from '../../components/ArticleContent/ArticleContent';

class Article extends Component {

    state = {
        articles: articles,
        black: true
    }

    componentDidMount() {
        this.scrollTop()
    }

    scrollTop = () =>  {
        window.scrollTo({
            top: 500,
            behavior: "smooth"
        });
    }

    render() {

        const {articles, black} = this.state;
        const id = this.props;

        const elements = articles.map((article) => {
            return(
                <ArticleContent
                    key={article.id}
                    scrollTop={this.scrollTop}
                    {...article}
                />
            )
        })

        return(
            <div className={style.Article}>
                <Container>
                    <Header/>
                    <Link to={'/blog'} className={style.Article__link}>
                        <div className={style.arrow}></div>
                        <p>Блог</p>
                    </Link>
                    {elements[id.id.id-1]}
                    <FormHandler black={black}/>
                    <Footer/>
                </Container>
            </div>
        )
    }

}

export default Article