import React from 'react';
import Backdrop from '../Backdrop/Backdrop';
import style from './Modal.module.css';

const Modal = ({children, active, setActive, successful}) => {

    const  cls = [style.Modal];

    if (active) {
        cls.push(style.active)
    } 
    if (successful) {
        cls.push(style.successful)
    } 

    return(
        <React.Fragment>
            <div className={cls.join(' ')}>
                <div className={style.Modal__header}>
                    <div 
                        className={style.close}
                        onClick={setActive}
                    >
                        <div></div>
                    </div>
                </div>
                <div className={style.Modal__body}>
                    {children}
                </div>
            </div>
            { active ? <Backdrop onClick={setActive} zIndex={'true'} /> : null }
        </React.Fragment>
    )
}


export default Modal