import React from 'react';
import style from './DirectionsDevelopment.module.css';

import Tabs from '../UI/Tabs/Tabs';
import Collapses from '../UI/Collapse/Collapse';

const DirectionsDevelopment = ({directions, showMore, itemsToShow, expanded}) => {

  return (
    <div className={style.DirectionsDevelopment}>
      <Tabs
        directions={directions}
        showMore={showMore}
        itemsToShow={itemsToShow}
        expanded={expanded}
      />
      <Collapses
        directions={directions}
        showMore={showMore}
        itemsToShow={itemsToShow}
        expanded={expanded}
      />
    </div>
  );
}

export default DirectionsDevelopment;
