import React, { Component } from 'react';
import style from './Preloader.module.css';
import logo from '../../img/logo.svg';
import MatrixEffect from '../MatrixEffect/MatrixEffect';

class Preloader extends Component {

    constructor(props){
        super(props);
        this.myRef = React.createRef();
    }
    
    render() {
        return(
            <div ref={this.myRef} className={style.Preloader}>
                <MatrixEffect/>
                <div className={style.Preloader__logoBlock}>
                    <div className={style.Preloader__logo}>
                        <img src={logo} alt='Digital Nomads'/>
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount(){
        this.myRef.current.style.opacity = 0;
    }
}

export default Preloader