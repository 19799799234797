import React from 'react';
import Button from '../../UI/Button/Button';
import style from './GeneralServiceItem.module.css';

const GeneralServiceItem = ({number, title, subtitle, to}) => {

    return(
        <div key={number} className={style.GeneralServiceItem}>
            <svg viewBox="0 0 813 750" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M288.655 578.693C235.768 697.628 353.885 842.301 537.532 792.766C656.22 760.753 675.557 686.075 689.068 649.838C697.606 626.947 703.415 602.977 707.024 578.693C710.527 555.074 711.601 529.415 700.009 508.832C685.028 482.231 653.64 471.755 631.97 450.822C605.001 424.756 595.135 380.988 608.135 345.019C614.745 326.716 626.416 311.016 637.005 294.9C652.558 271.225 666.06 246.074 677.3 219.851C685.934 199.711 693.363 177.772 690.089 155.962C686.982 135.268 674.703 117.602 666.271 98.6066C659.08 82.3896 654.573 64.4557 656.219 46.6604C657.865 28.8651 666.271 11.2635 680.583 1.53516" stroke="url(#paint0_linear)" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M705.824 1.0083C749.005 67.5932 752.605 160.05 714.766 230.114C697.664 261.782 673.019 288.854 659.042 322.164C654.297 333.462 650.812 345.913 652.81 358.078C654.817 370.243 663.61 381.845 675.299 383.341C693.694 385.685 706.096 364.678 713.314 346.781C723.876 320.595 734.104 293.976 749.454 270.541C764.805 247.107 786.026 226.754 812 218.604" stroke="url(#paint1_linear)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="10 10"/>
                <path d="M475.158 494.275C469.138 515.274 454.676 533.447 436.06 543.425C430.225 546.554 424.028 548.898 418.325 552.276C403.925 560.796 393.151 576.265 390.96 593.442C388.768 610.628 395.677 629.06 409.127 639.139C417.383 645.323 427.452 648.231 437.451 650.086C446.737 651.812 457.088 652.504 464.825 646.846C468.583 644.096 471.435 640.016 475.554 637.912C478.784 636.26 482.49 635.992 485.87 634.7C489.25 633.408 492.559 630.472 492.471 626.707" stroke="url(#paint2_linear)" strokeWidth="4" strokeMiterlimit="10"/>
                <path d="M574.519 1C566.906 17.5862 562.91 35.9907 562.927 54.4045C562.936 64.982 563.737 77.0825 556.511 84.4757C552.11 88.9799 545.676 90.5213 539.532 90.872C520.168 91.9981 497.987 84.0973 483.007 97.0192C473.078 105.585 470.429 120.463 461.997 130.644C451.69 143.086 434.605 146.436 418.885 146.639C412.891 146.722 406.783 146.427 401.15 144.267C386.283 138.554 378.309 121.045 377.033 104.486C375.756 87.9369 379.576 71.4615 380.721 54.9029C381.865 38.3444 379.744 20.2168 368.838 8.17169" stroke="url(#paint3_linear)" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M227.278 4.47852C211.206 21.0186 206.664 45.8656 201.418 68.8297C196.164 91.7938 187.819 116.595 168.139 128.068C156.221 135.009 141.478 136.107 130.678 144.82C124.288 149.979 119.887 157.354 116.085 164.812C104.545 187.435 97.0902 212.503 82.5848 233.151C68.0706 253.807 43.3286 269.747 19.722 263.019" stroke="url(#paint4_linear)" strokeWidth="4" strokeMiterlimit="10"/>
                <path d="M218.291 141.138C198.892 158.786 188.488 186.734 191.358 213.492C193.558 234.019 202.976 253.485 202.633 274.132C202.281 295.186 192.528 319.193 205.309 335.438C210.22 341.677 217.623 345.166 225.016 347.492C239.742 352.126 257.838 352.024 268.066 339.988C285.599 319.341 266.429 283.298 280.899 260.195C298.943 231.388 350.944 248.326 367.482 218.531C375.941 203.293 369.665 183.716 360.749 168.763C349.764 150.35 332.451 133.311 311.741 133.533C294.744 133.708 287.359 120.731 270.029 120.832C251.298 120.952 232.445 128.253 218.291 141.138Z" stroke="url(#paint5_linear)" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M70.9487 276.338C85.7534 260.601 106.543 249.488 127.474 252.1C131.382 252.589 135.325 253.586 138.6 255.866C143.335 259.17 146.169 264.865 147.437 270.68C150.861 286.334 144.321 302.2 140.078 317.632C135.836 333.065 134.7 352.014 145.976 362.887C157.849 374.332 178.428 371.545 189.281 384.06C200.468 396.973 194.148 418.544 182.327 430.81C170.498 443.077 154.513 450.008 142.076 461.592C127.016 475.631 118.215 495.438 110.266 514.849C102.31 534.259 94.52 554.325 80.7979 569.795C67.0759 585.255 45.7403 595.297 26.5259 588.965" stroke="url(#paint6_linear)" strokeWidth="4" strokeMiterlimit="10"/>
                <path d="M105.178 417.003C104.976 422.717 107.731 428.762 112.704 431.097C115.247 432.297 118.126 432.454 120.907 432.39C127.984 432.224 135.025 430.701 141.591 427.922C143.264 427.212 144.962 426.372 146.089 424.904C147.471 423.095 147.744 420.649 147.973 418.342C148.228 415.72 148.483 413.099 148.738 410.478C149.011 407.644 149.196 404.451 147.445 402.272C145.913 400.362 143.669 400.288 141.767 399.079C139.637 397.722 138.291 395.378 136.346 394.141C132.86 391.926 126.54 391.132 122.544 392.212C113.593 394.621 105.495 407.847 105.178 417.003Z" stroke="url(#paint7_linear)" strokeWidth="4" strokeMiterlimit="10"/>
                <path d="M1 627.455C15.9719 639.122 31.3575 650.724 48.9259 657.314C66.4856 663.904 86.7826 664.947 103.251 655.763C116.577 648.333 126.021 635.19 134.647 622.203C149.452 599.913 163.482 576.09 168.059 549.351C173.217 519.188 167.927 482.619 190.354 462.977C205.449 449.76 227.075 450.378 246.536 447.453C311.555 437.669 364.322 379.492 381.266 312.944C386.811 291.18 389.161 268.244 398.614 248.012C410.25 223.082 431.85 204.456 455.624 192.254C479.397 180.052 505.442 173.664 531.337 167.905C555.85 162.45 584.227 158.389 603.521 175.16C622.04 191.248 624.249 220.839 617.524 245.049C607.481 281.24 582.088 310.102 563.094 342.111C544.099 374.111 531.469 415.831 548.932 448.782C556.114 462.331 567.574 472.687 577.899 483.791C597.034 504.364 613.035 528.796 620.623 556.403C628.201 584.01 678.417 621.459 664.695 646.324C651.105 670.959 723.986 703.436 706.505 725.2C681.852 755.899 611.882 807.79 617.524 847.479C618.748 856.072 531.689 833.135 539.021 837.206C546.344 841.267 557.962 834.049 554.855 825.982" stroke="url(#paint8_linear)" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="10 10"/>
                <defs>
                <linearGradient id="paint0_linear" x1="492.687" y1="1.53516" x2="492.687" y2="802.756" gradientUnits="userSpaceOnUse">
                <stop stopColor="#151515" stopOpacity="0"/>
                <stop offset="0.296875" stopColor="#0064FA"/>
                <stop offset="1" stopColor="#151515" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="732.118" y1="1.0083" x2="732.118" y2="383.521" gradientUnits="userSpaceOnUse">
                <stop stopColor="#151515" stopOpacity="0"/>
                <stop offset="0.296875" stopColor="#0064FA"/>
                <stop offset="1" stopColor="#151515" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint2_linear" x1="441.516" y1="494.275" x2="441.516" y2="651.321" gradientUnits="userSpaceOnUse">
                <stop stopColor="#151515" stopOpacity="0"/>
                <stop offset="0.296875" stopColor="#0064FA"/>
                <stop offset="1" stopColor="#151515" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint3_linear" x1="471.679" y1="1" x2="471.679" y2="146.652" gradientUnits="userSpaceOnUse">
                <stop stopColor="#151515" stopOpacity="0"/>
                <stop offset="0.296875" stopColor="#0064FA"/>
                <stop offset="1" stopColor="#151515" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint4_linear" x1="123.5" y1="4.47852" x2="123.5" y2="264.581" gradientUnits="userSpaceOnUse">
                <stop stopColor="#151515" stopOpacity="0"/>
                <stop offset="0.296875" stopColor="#0064FA"/>
                <stop offset="1" stopColor="#151515" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint5_linear" x1="281.182" y1="120.832" x2="281.182" y2="350.328" gradientUnits="userSpaceOnUse">
                <stop stopColor="#151515" stopOpacity="0"/>
                <stop offset="0.296875" stopColor="#0064FA"/>
                <stop offset="1" stopColor="#151515" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint6_linear" x1="110.822" y1="251.719" x2="110.822" y2="590.89" gradientUnits="userSpaceOnUse">
                <stop stopColor="#151515" stopOpacity="0"/>
                <stop offset="0.296875" stopColor="#0064FA"/>
                <stop offset="1" stopColor="#151515" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint7_linear" x1="127.041" y1="391.733" x2="127.041" y2="432.402" gradientUnits="userSpaceOnUse">
                <stop stopColor="#151515" stopOpacity="0"/>
                <stop offset="0.296875" stopColor="#0064FA"/>
                <stop offset="1" stopColor="#151515" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint8_linear" x1="355.115" y1="163.154" x2="355.115" y2="849.422" gradientUnits="userSpaceOnUse">
                <stop stopColor="#151515" stopOpacity="0"/>
                <stop offset="0.296875" stopColor="#0064FA"/>
                <stop offset="1" stopColor="#151515" stopOpacity="0"/>
                </linearGradient>
                </defs>
            </svg>
            <div className={style.GeneralServiceItem__number}>
                {number}
            </div>
            <h4 className={style.GeneralServiceItem__title}>
                {title}
            </h4>
            <p className={style.GeneralServiceItem__subtitle}>
                {subtitle}
            </p>
            <div className={style.GeneralServiceItem__button}>
                <Button to={to}>
                    Подробнее
                </Button>
            </div>
        </div>
    )
}

export default GeneralServiceItem