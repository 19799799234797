import React, {Component} from 'react';
import style from './BecomeClient.module.css';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import Why from '../../components/Why/Why';
import Footer from '../../components/Footer/Footer';
import FormHandler from '../../components/FormHandler/FormHandler';


class BecomeClient extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Стать клиентом digital nomads'
            }
        ],
        form: 'formRequest',
    }

    render() {
        const {descriptions, form} = this.state;

        return(

            <div className={style.BecomeClient}>
                <Container>
                    <Header/>
                    <Description description={descriptions[0]}/>
                    <Why/>
                    <FormHandler form={form}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default BecomeClient