import React, { useRef, useEffect } from 'react';
import style from './RoadMapItem.module.css';

let setColor = false;
let  cls = [];

const RoadMapItem = ({title, subtitle, stages, widthForRoadItems}) => {

    const RoadMapTitleRef = useRef(null);

    const Enter = (items) => {
       return items.split('\n').map((item, index) => {return (<p key={index}> {item} </p>) })
    }

    useEffect(() => {
        setColor ? cls.push(style.active) : cls.splice(0,1)
    }, [setColor]);

    window.addEventListener('scroll', () => {
        if (RoadMapTitleRef.current) {
            if ((document.documentElement.clientHeight - 150 > RoadMapTitleRef.current.getBoundingClientRect().bottom) && !setColor) {
                setColor = true;
            } else if ((document.documentElement.clientHeight - 150 < RoadMapTitleRef.current.getBoundingClientRect().bottom) && setColor){
                setColor = false;
            }
        }
    });

    return(
        <div className={style.RoadMapItem}>
            <div className={style.RoadMapItem__left}>
                <h5 ref={RoadMapTitleRef} className={cls.join(' ')}>{title}</h5>
            </div>
            <div className={style.RoadMapItem__right}>
                {Enter(subtitle)}
                <div className={style.RoadMapItem__stages}>
                    {stages ? stages.map((stage, index) => {
    
                        return(
                            <div key={index}>
                               
                                <div className={style.RoadMapItem__stages_number} >
                                    {stage.number}
                                </div>
                                <div className={style.RoadMapItem__stages_description} >
                                    {Enter(stage.description)}
                                </div>
                            </div>
                        ) 
                    })
                        : null
                    }
                </div>
            </div>
        </div>
    ) 
}

export default RoadMapItem