import React, {Component} from 'react';
import {Container} from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import Company from '../../components/Company/Company';
import Footer from '../../components/Footer/Footer';
import AboutBlock from '../../components/AboutBlock/AboutBlock';
import Arda from "../../components/Arda/Arda";
import style from './AboutUs.module.css'; // Импортируем стили


class AboutUs extends Component {
    state = {
        descriptions: [
            {
                id: 0,
                title: `Digital Nomads  — ${'\n'}ваш надёжный партнёр ${'\n'}в цифровом пространстве`,
                subtitle: 'Мы — команда настоящих профессионалов, искренне преданных своему делу. Нам важно добиться такого результата, которым будете гордиться не только вы, но и мы сами. Наша проектная система помогает реализовать любые идеи в эффективное решение для вашего бизнеса.'
            }
        ]
    }

    render() {
        const {descriptions} = this.state;

        return (
            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions[0]}/>
                    <AboutBlock/>
                    <Company/>
                    <div className={style.FooterArdaContainer}>
                        <div className={style.Arda}>
                            <Arda/>
                        </div>
                        <div className={style.Footer}>
                            <Footer/>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default AboutUs