import React from 'react';
import style from './ReviewsSlider.module.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ArrowLeft = ({currentSlide, slideCount, className, ...props}) => (
    <div
        {...props}
        className={`prev ${className}`}
    >
        <div></div>
    </div>
);

const ArrowRight = ({currentSlide, slideCount, className, ...props}) => (
    <div
        {...props}
        className={`next ${className}`}
    >
        <div></div>
    </div>
);

const ReviewsSlider = ({reviews}) => {

    const settings = {
        dots: false,
        infinite: false,
        className: style.CustomSlider,
        arrows: true,
        prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
    };

    const elements = reviews.map((review) => {
        return(
            <div key={review.id} className={style.ReviewsSlider__content}>
                <div className={style.ReviewsSlider__content_text}>
                    {review.text}
                </div>
                <div className={style.ReviewsSlider__content_autor}>
                    <p className={style.autor}>{review.autor}</p>
                    <p>{review.position}</p>
                </div>
            </div>
        )
    })

    return(
        <div className={style.ReviewsSlider}>
            <div className={style.ReviewsSlider__title}>Клиенты о нас</div>
            <Slider {...settings}>
                <div>
                    {elements[0]}
                </div>
            </Slider>
        </div>

    )
}

export default ReviewsSlider