import React from 'react';
import style from './ProjectDetailsItem.module.css';

const ProjectDetailsItem = ({title, list}) => {

    return(
        <div className={style.ProjectDetailsItem}>
            <div className={style.ProjectDetailsItem__border}>
                <h5>{title}</h5>
                <ul>
                    {list.map((item, index) => {return( <li key={index}>{item.item}</li> )})}
                </ul>
            </div>
        </div>
    ) 
}

export default ProjectDetailsItem