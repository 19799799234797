import React, {Component} from 'react';
import style from './IosDeveloper.module.css';
import { Container } from 'reactstrap';
import descriptionVacancies from '../../json/vacanciesInfo.json';

import Header from '../../components/Header/Header';
import DescriptionVacancies from '../../components/DescriptionVacancies/DescriptionVacancies';
import Expectations from '../../components/Expectations/Expectations';
import FormResume from '../../components/UI/FormResume/FormResume';
import Footer from '../../components/Footer/Footer';
import Successful from '../../components/UI/Successful/Successful';
import Modal from '../../components/UI/Modal/Modal';


class IosDeveloper extends Component {

    state = {
        successful: null,
        activeModal: false,
        noneArrow: true
    };

    toggleModal = () => {
        this.setState({activeModal: !this.state.activeModal})
        if (!this.state.activeModal) {
            this.setState({successful: null});
        }
    }

    processResponse = ((resp) => {
        this.setState({successful: resp.status === 200});
        this.toggleModal()
    });

    render() {

        const {successful, activeModal, noneArrow} = this.state

        return(
            <div className={style.IosDeveloper}>
                <Container>
                    <Header/>
                    <DescriptionVacancies
                        description={descriptionVacancies[1]}
                    />
                    <Expectations 
                        expectations={descriptionVacancies[1].expectations}
                        plus={descriptionVacancies[1].plus}
                    />
                    <FormResume method={this.processResponse} /> 
                    <Modal
                        active={activeModal}
                        setActive={this.toggleModal}
                        successful={successful}
                    >
                        { successful === null ? null
                        : <Successful 
                            successful={successful}
                            noneArrow={noneArrow}
                            onClick={this.toggleModal}
                        />}
                    </Modal>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default IosDeveloper