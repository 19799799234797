import React from 'react';
import { Link } from 'react-router-dom';
import style from './OtherServiceItem.module.css';

const OtherServiceItem = ({list}) => {

    const renderOtherServiceLink = () => {
        return list.map((item, index) => {
            return (
                <li key={index} className={item.label ? '' : style.empty}>
                    <Link
                        to={item.to}
                    >
                        <p>{item.label}
                        <div className={style.arrow}></div></p>
                    </Link>
                </li>
            )
        })
    }

    return(
        <div className={style.OtherServiceItem}>
            <ul>
                {renderOtherServiceLink()}
            </ul>
        </div>
    )
}

export default OtherServiceItem