import React, {Component} from 'react';
import style from './MobileApp.module.css';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import Footer from '../../components/Footer/Footer';
import AdvantagesList from '../../components/AdvantagesList/AdvantagesList';
import Stages from '../../components/Stages/Stages';
import DirectionsDevelopment from '../../components/DirectionsDevelopment/DirectionsDevelopment';
import FormHandler from '../../components/FormHandler/FormHandler';


class MobileApp extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Ключевые компетентности',
                subtitle: 'Компания Digital nomads предлагает полный комплекс услуг по проектированию, разработке и сопровождению мобильных приложений на самых популярных платформах'
            },
            {
                id: 1,
                title: 'Разработка мобильных приложений',
                subtitle: 'Более 10 лет работы в сфере разработки мобильных приложений различной \nспецифики позволили нам накопить богатый опыт в проектировании интуитивного \nи релевантного тематике приложения интерфейса',
                price: 'Стоимость: от 1 500 000 ₽',
                data: ' Срок реализации: от 3-х месяцев'
            }
        ],
        advantages: [
            {
                id: 0,
                img: './../img/mobileApp-advantage-1.svg',
                title: 'Мы честны с клиентом и с самими собой',
                subtitle: 'Предлагаем те решения, которые действительно несут ценность для клиента и не обещаем того, что не можем сделать.',
            },
            {
                id: 1,
                img: './../img/mobileApp-advantage-2.svg',
                title: 'ТМ/FIX',
                subtitle: 'Мы можем подстроиться под нужды клиента. Возможность работы как за фиксированную плату с оговоренными сроками, так и по типу Time&Materials.',
            },
            {
                id: 2,
                img: './../img/mobileApp-advantage-3.svg',
                title: 'Продуктивный подход',
                subtitle: 'Руководствуемся цифрами и метриками. \nВ первую очередь думаем об эффективности: сначала считаем и анализируем, потом делаем.',
            },
            {
                id: 3,
                img: './../img/mobileApp-advantage-4.svg',
                title: 'Контроль на каждом этапе',
                subtitle: 'Каждый проект курирует технический директор и руководитель разработки. Это помогает укладываться в срок и выполнять данные обещания.',
            },
        ],
        directions: [
            {
                id: 0,
                title: 'iOS',
                src: './../img/Ios.jpg',
                alt: 'Ios',
                languages: [
                    {item: 'Swift'},
                    {item: 'Objective-C'}
                ],
                services: [
                    {item: 'Google FireBase'},
                    {item: 'VKApi, FBApi'},
                    {item: 'Fabric, Crashlytics'},
                    {item: 'Sketch, Zeplin, Figma'},
                    {item: 'Яндекс.Касса sdk'},
                    {item: 'Stripe'},
                    {item: 'CloudPayments'},
                    {item: 'PayKeeper'},
                    {item: 'Apple Pay'}
                ],
                technologys: [
                    {
                        list: [
                            {item: 'MVC, MVP, MVVM, VIPER SOLID, Clean architecture'},
                            {item: 'AutoLayout, SnapKit Realm, CoreData Git'},
                            {item: 'CoreAnimation, CoreGraphics'},
                            {item: 'CoreLocation, MapKit'},
                            {item: 'WebSocket'},
                            {item: 'GCD'},
                            {item: 'Swinject'},
                        ]
                    },
                    {
                        list: [
                            {item: 'PromiseKit'},
                            {item: 'Socket, STOMP'},
                            {item: 'Dispatch Queues'},
                            {item: 'PassKit'},
                            {item: 'AVKit'},
                            {item: 'ARKit'},
                            {item: 'SpriteKit'},
                            {item: 'Starscream, CocoaAsyncSocket'},
                            {item: 'Speech'},
                            {item: 'RxSwift, Reactive'}
                        ]
                    }
                ]
            },
            {
                id: 1,
                title: 'Android',
                src: './../img/Android.jpg',
                alt: 'Android',
                languages: [
                    {item: 'Kotlin'},
                    {item: 'Java'}
                ],
                services: [
                    {item: 'App metrika'},
                    {item: 'Appsflyer'},
                    {item: 'Firebase'},
                    {item: 'Crashlytics (Firebase/Fabric)'},
                    {item: 'Pushwoosh'},
                    {item: 'Applozic'},
                    {item: 'Sendbird'},
                    {item: 'Facebook analytics'},
                    {item: ''},
                    {item: 'Google play services'},
                    {item: 'Google AdMob'},
                    {item: 'GoogleMaps'},
                    {item: 'Stripe'},
                    {item: 'PayKeeper'},
                    {item: 'CloudPayments'},
                    {item: 'Android Pay'}
                ],
                more: true,
                technologys: [
                    {
                        list: [
                            {item: 'MVP, MVI, SOLID, Clean architecture'},
                            {item: 'Retrofit 2, okhttp3, Scarlet'},
                            {item: 'Rxjava2, coroutines'},
                            {item: 'Dagger 2, koin, toothpick'},
                            {item: 'Cicerone'},
                            {item: 'Groupie, Adapter delegate'},
                            {item: 'Moxy, Reamp, MviCore'},
                        ]
                    },
                    {
                        list: [
                            {item: 'SqlLite, room, realm, dbflow'},
                            {item: 'Junit, mockito, mockk'},
                            {item: 'Glide, Picasso'},
                            {item: 'MpChart, Lottie'},
                            {item: 'Custom View'},
                            {item: 'Android studio, Android sdk, Jetpack'},
                            {item: 'Git, gradle, proguard'},
                        ]
                    }
                ]
            },
            {
                id: 2,
                title: 'Back-end',
                src: './../img/Back-end.jpg',
                alt: 'Back-end',
                languages: [
                    {item: 'Kotlin'},
                    {item: 'Java'}
                ],
                services: [
                    {item: 'Postman'},
                    {item: 'Swagger'},
                ],
                technologys: [
                    {
                        list: [
                            {item: 'Git'},
                            {item: 'Alf resco'},
                            {item: 'SQL'},
                            {item: 'Apache Solr'},
                            {item: 'Jenkins'},
                            {item: 'REST'},
                            {item: 'Hibernate ORM'},
                            {item: 'JPA'},
                            {item: 'Microservices'},
                            {item: 'Jasper'},
                            {item: 'Elasticsearch'},
                            {item: 'XBRL'},
                            {item: 'Openshift'},
                        ]
                    },
                    {
                        list: [
                            {item: 'JUnit'},
                            {item: 'Cucumber'},
                            {item: 'Spring Boot'},
                            {item: 'Spring Data'},
                            {item: 'Spring Security'},
                            {item: 'Spring Websocket'},
                            {item: 'Spring Web (spring data rest)'},
                            {item: 'Spring AOP'},
                            {item: 'Logback'},
                            {item: 'MySQL (PostgreSQL)'},
                            {item: 'Maven'}
                        ]
                    }
                ]
            },
            {
                id: 3,
                title: 'ReactNative',
                src: './../img/ReactNative.jpg',
                alt: 'ReactNative',
                languages: [
                    {item: 'JavaScript'},
                ],
                services: [
                    {item: 'VK API'},
                    {item: 'CloudPayments'},
                    {item: 'InApp Purchases'},
                ],
                technologys: [
                    {
                        list: [
                            {item: 'MVC'},
                            {item: 'Git'},
                            {item: 'Trello, JIRA'},
                            {item: 'REST, JSON,XML'},
                            {item: 'React Native'},
                            {item: 'React'},
                            {item: 'React navigation'},
                        ]
                    },
                    {
                        list: [
                            {item: 'Redux'},
                            {item: 'Redux-Saga'},
                            {item: 'Redux-Thunk'},
                            {item: 'Redux Persist'},
                            {item: 'Redux Logger'},
                            {item: 'React animations'},
                            {item: 'Jest'},
                        ]
                    }
                ]
            },
        ],
        stages: [
            {
                number: '01',
                title: 'Аналитика',
                subtitle: 'Собираем информацию о продукте, клиенте, конкурентах и близких аналогах.'
            },
            {
                number: '02',
                title: 'Проектирование',
                subtitle: 'Составляем подробную карту экранов приложения c кратким описанием технических решений.'
            },
            {
                number: '03',
                title: 'Дизайн',
                subtitle: 'Разрабатываем дизайн‑концепцию приложения, согласовываем стилистику с заказчиком.'
            },
            {
                number: '04',
                title: 'Разработка',
                subtitle: 'Разрабатываем продукт, соблюдая сроки указанные в договоре.'
            },
            {
                number: '05',
                title: 'Тестирование',
                subtitle: 'Начинаем тестирование с самой первой сборки приложения. '
            },
            {
                number: '06',
                title: 'Поддержка',
                subtitle: 'Выделяем команду, которая будет заниматься развитием и дальнейшей разработкой приложения.'
            }
        ],
        black: true,
        itemsToShow: 9,
        expanded: false
    }

    showMore = () => {
        this.state.itemsToShow === 9 ? (
          this.setState({ itemsToShow: this.state.directions[1].services.length, expanded: true})
        ) : (
          this.setState({ itemsToShow: 9, expanded: false})
        )
    }

    render() {
        const {descriptions, advantages, directions, stages, black, itemsToShow, expanded} = this.state;

        return(

            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions[1]}/>
                    <AdvantagesList advantages={advantages}/>
                    <DirectionsDevelopment
                        directions={directions}
                        showMore={this.showMore}
                        itemsToShow={itemsToShow}
                        expanded={expanded}
                    />
                    <Stages stages={stages}/>
                    <FormHandler black={black}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default MobileApp