import React, {Component} from 'react';
import style from './Main.module.css';
import { Container } from 'reactstrap';

import Banner from '../../components/Banner/Banner';
import Header from '../../components/Header/Header';
import Preloader from '../../components/Preloader/Preloader';

export default class Main extends Component {

    state = {
        form: false,
        isLoading: true
    }

    componentDidMount() {
        if(sessionStorage.getItem('showPreloader') === 'false') {
            //не показываем прелоадер
            this.setState({isLoading: false});
            return;
        }
        
        if(sessionStorage.getItem('showPreloader') == undefined) {
            //показываем
            this.timer = setTimeout(() => {
                this.setState({isLoading: false})
            }, 7000);
            this.setlocalStorage(false);
        }
    }

    setlocalStorage(flag) {
        sessionStorage.setItem('showPreloader', flag) 
    }   

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        return (
            this.state.isLoading ? <Preloader/> 
            :  <div className={style.Main}> 
                    <Container>
                        <Header/>
                        <Banner/>
                    </Container>
                </div>
           
        )
    }

};