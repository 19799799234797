// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Backdrop_Backdrop__3j6VK {\n    z-index: 50;\n    background: rgba(0, 0, 0, .6);\n    position: fixed;\n    top: 0;\n    right: 0;\n    left: 0;\n    bottom: 0;\n}\n.Backdrop_Backdrop__3j6VK.Backdrop_zIndex__28WtY {\n    z-index: 102;\n}", "",{"version":3,"sources":["webpack://src/components/UI/Backdrop/Backdrop.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,6BAA6B;IAC7B,eAAe;IACf,MAAM;IACN,QAAQ;IACR,OAAO;IACP,SAAS;AACb;AACA;IACI,YAAY;AAChB","sourcesContent":[".Backdrop {\n    z-index: 50;\n    background: rgba(0, 0, 0, .6);\n    position: fixed;\n    top: 0;\n    right: 0;\n    left: 0;\n    bottom: 0;\n}\n.Backdrop.zIndex {\n    z-index: 102;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Backdrop": "Backdrop_Backdrop__3j6VK",
	"zIndex": "Backdrop_zIndex__28WtY"
};
export default ___CSS_LOADER_EXPORT___;
