import React from 'react';
import style from './DescriptionCase.module.css';

const DescriptionCase = ({description}) => {

    const Enter = (items) => {
        return items.split('\n').map((item, index) => {return (<p key={index}>{item}</p>)})
    }

    return(
        <div key={description.id} className={style.DescriptionCase} >
            <div className={style.DescriptionCase__platform}>
                <div>
                    <img src={description.adroidSrc} />
                    <img src={description.iosSrc} />
                </div>
                <p>{description.platform}</p>
            </div>
            <h2 className={style.DescriptionCase__title}>
                {description.title}
            </h2>
            <div className={style.DescriptionCase__subtitle}>
                {Enter(description.subtitle)}
            </div>
            <div className={style.DescriptionCase__conditions}>
                <p>{description.price}</p>
                <p>{description.data}</p>
            </div>
            {
                description.apps
                ?
                    <div className={style.DescriptionCase__apps}>
                        {
                            description.apps.googlePlay
                            ?
                                <div className={style.DescriptionCase__app}>
                                    <a href={description.apps.googlePlay} target='_blank' rel="noreferrer">
                                        <div className={style.googlePlay}></div>
                                    </a>
                                </div>
                            : null
                        }
                        {
                            description.apps.appStore
                            ?
                                <div className={style.DescriptionCase__app} >
                                    <a href={description.apps.appStore} target='_blank' rel="noreferrer">
                                        <div className={style.appStore}></div>
                                    </a>
                                </div>
                            : null
                        }
                    </div>
                : null
            }
        </div>
    )
}

export default DescriptionCase