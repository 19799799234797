import React from 'react';
import style from './GallerySlideItem.module.css';

const GallerySlideItem = ({src, type}) =>  {
        return (
            <div className={style.GallerySlideItem}>
                <div className={style.GallerySlideItem__content}>
                    {type ==='photo'? 
                        <img
                            width="auto" height="565px"
                            src={src}
                            alt={'Шерегеш'}
                        />
                    : 
                        <video width="100%" height="100%" autoPlay controls>
                            <source src={src} type="video/mp4"/>
                        </video>
                    }
                </div>
            </div>
        )
}

export default GallerySlideItem