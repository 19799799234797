import React from 'react';
import { Link } from 'react-router-dom';
import style from './DirectionsDevelopmentItem.module.css';

const DirectionsDevelopmentItem = ({id, src, alt, languages, services, technologys, more, showMore, itemsToShow, expanded, onClick, arrow}) => {

  const listLanguages = languages.map((language, index) => {
    return (
      <li key={index}>{language.item}</li>
    )
  })

  const listServices = services.slice(0, itemsToShow).map((service, index) => {
    return (
      <li key={index}>{service.item}</li>
    )
  })

  const listTechnologys = technologys.map((technology, index) => {
    return (
      <li key={index}>{technology.list}</li>
    )
  })

  const cls = [style.DirectionsDevelopmentItem__more]

  if (more) {
    cls.push(style.active)
  }

  const imgAndroid = [style.DirectionsDevelopmentItem__img]

  if (more) {
    imgAndroid.push(style.active)
  }

  return (
    <div key={id} className={style.DirectionsDevelopmentItem}>
      <div className={ expanded ? imgAndroid.join(' ') : style.DirectionsDevelopmentItem__img} >
        <img src={src} alt={alt}/>
      </div>
      <div className={style.DirectionsDevelopmentItem__description}>
        <div className={style.DirectionsDevelopmentItem__description_left}>
          <div>
            <p>Языки</p>
            <ul>
              {listLanguages}
            </ul>
          </div>
          <div>
            <p>Сервисы</p>
            <ul>
              {listServices}
              { expanded ?     
                (
                  <Link to={'#'}
                    className={cls.join(' ')} 
                    onClick={showMore}
                    more={more}
                  ></Link>
                ) 
              : (
                  <Link to={'#'}
                    className={cls.join(' ')} 
                    onClick={showMore}
                    more={more}
                  >
                    ещё
                  </Link>
                )
              }
            </ul>
          </div>
        </div>
        <div className={style.DirectionsDevelopmentItem__description_right}> 
          <div>
            <p>Технологии и инструменты</p>
            <div className={style.DirectionsDevelopmentItem__row}>
              <ul>
                {listTechnologys[0].props.children.map((list, index) => (<li key={index}>{list.item}</li>) )}
              </ul>
              <ul>
                {listTechnologys[1].props.children.map((list, index) => (<li key={index}>{list.item}</li>) )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      { arrow ? <div className={style.arrow} onClick={onClick}></div> : null}
    </div>
  );
}

export default DirectionsDevelopmentItem;
