import React from 'react';
import { Link } from 'react-router-dom';
import style from './DescriptionVacancies.module.css';

const DescriptionVacancies = ({description}) => {

    return(
        <div className={style.DescriptionVacancies}>
            <Link to={'/work-with-us'} className={style.DescriptionVacancies__link}>
                <div className={style.arrow}></div>
                <p>Карьера</p>
            </Link>
            <div key={description.id} className={style.DescriptionVacancies__title}>
                <div className={style.DescriptionVacancies__title_top}>
                    <h2>{description.title}</h2>
                    <div className={style.DescriptionVacancies__level}>
                        <div className={style.DescriptionVacancies__level_item}>
                            {description.level[0].item}
                        </div>
                        <div className={style.DescriptionVacancies__level_item}>
                            {description.level[1].item}
                        </div>
                    </div>
                </div>
                <p>{description.salary}</p>
            </div>
        </div>
    )
}

export default DescriptionVacancies