import React, { useState, useEffect } from 'react';
import style from './AboutBlock.module.css';
import aboutPromises from '../../json/aboutPromises.json';
import Description from '../Description/Description';
import WorkDetailsList from '../WorkDetails/WorkDetailsList/WorkDetailsList';

const descriptions = [
    {
        id: 0,
        title: 'Молодые и целеустремленные',
        subtitle: 'Наша компания находится на рынке недавно, поэтому у нас современный подход\n' +
            ' к работе, быстрое развитие и свежие идеи для решения проблем вашего бизнеса.',
        subtitle_1: 'Сегодня Digital nomads — команда более чем из 40 молодых, творческих ребят, работающих' +
            ' с крупными проектами. Ещё до пандемии мы хотели сделать так, чтобы наши сотрудники могли совмещать работу ' +
            'и путешествия, поэтому начали создавать комфортные условия для удаленного формата работы.'
    },
    {
        id: 1,
        title: 'Миссия компании',
        subtitle: 'Меняем отношение к работе и формируем эффективные команды, обеспечивая долгосрочный рост нашим клиентам.'
    },
    {
        id: 2,
        title: 'Философия компании',
        subtitle: `Digital nomads — это те, кому для работы нужны три вещи: ноутбук, розетка, Wi-Fi. ${'\n'}
            Мы — семья, партнеры и путешественники. Наша главная особенность в том, что мы
            сами распоряжаемся своим временем и зарабатываем на ту жизнь, которую хотим.`,
        subtitle_1: `Мы формируем новый тренд условий работы и создаём для своих сотрудников
            комфортную обстановку, в которой работа превращается в приключение,
            в игру, где правит коллективный разум и вдохновение.`
    },
    {
        id: 3,
        title: 'Наши ценности',
    }
]

const historyDate = [
    {
        date: 'ОКТЯБРЬ 2018  —',
        text: 'Разработчики, основатели Digital Nomads, открыли ИП и начали разрабатывать мобильные приложения,' +
            ' в составе трёх человек занимались небольшими проектами.',
    },
    {
        date: 'ФЕВРАЛЬ 2019  —',
        text: 'Небольшая команда стала серебряным призером Globus Community и получила награду в Сочи за работу' +
            ' в качестве субподрядчика на общеизвестных федеральных проектах.',
    },
    {
        date: 'ЯНВАРЬ 2020  —',
        text: 'Создана и зарегистрирована ИТ-компания Digital nomads. Основное направление работы — ' +
            'создание мобильных приложений и веб-сервисов.',
    },
    {
        date: 'АПРЕЛЬ 2020  —',
        text: 'Компания стала победителем конкурса начинающих предпринимателей\n от Томской области “Первый шаг”.\n' +
            ' Получили премию за вклад в социально-экономического развитие региона.',
    },
    {
        date: 'АВГУСТ 2021  —',
        text: 'Команда Digital Nomads начала работать с крупными продуктовыми компаниями,' +
            ' увеличила количество сотрудников и показатели эффективности в несколько раз.',
    }
]

const worthses = [
    '— Возможность совмещать работу и путешествия',
    '— Индивидуальный подход к каждому сотруднику',
    '— Развитие и самореализация',
    '— Взаимопомощь',
    '— Гибкость',
    '— Свобода',
    '— Движение',
]

const worthBlock = () => {
    return worthses.map((worth, index) => {
        return (
            <p key={index}>{worth}</p>
        )
    })
}

const historyBlock = () => {
    return historyDate.map((point, index) => {
        return (
            <div key={index} className={style.history_row}>
                <div className={style.history_item}>
                    <div className={style.history_item_date}>{point.date}</div>
                    <div className={style.history_item_text}>{point.text}</div>
                </div>
            </div>
        )
    })
}

const philosophyBlock  = () => {
    const arr = [];
    for (let i = 1; i < 6; i++) {
        arr.push(
            <div key={i} className={style.philosophy_img_item}>
                <img src={`../../img/philosophy/philosophy-${i}.png`} alt='philosophy'/>
            </div>
        )
    }
    return arr;
}


const AboutBlock = () => {
    const [displayTypeIsMobile, setMobileType] = useState(document.documentElement.clientWidth < 415);

    const setDisplayType = (e) => {
        setMobileType(e.target.innerWidth < 415);
    }

    useEffect(() => {
        window.addEventListener('resize', setDisplayType);
        return () => {
            window.removeEventListener('resize', setDisplayType);
        }
    },[])

    const setImageFromSprite = (spriteId) => {
        return `../../img/vacancyIcon/sprite.svg${displayTypeIsMobile ? spriteId + '_mobile' : spriteId}`;
    }

    return(
        <div className={style.PhotoBlock}>
            <div className={style.PhotoBlock__top}>
                <div className={style.PhotoBlock__img}>
                    <img src={'../../img/aboutUs-1.jpg'} alt='О компании'/>
                </div>
            </div>
            <div className={style.history}>
                <Description description={descriptions[0]}/>
                <div className={style.history_block}>
                    { historyBlock() }
                </div>
                <div className={style.history_item_text}>{descriptions[0].subtitle_1}</div>
            </div>
            <div className={style.mission}>
                <Description description={descriptions[1]}/>
                <svg className={style.mission_img}>
                    <use xlinkHref={ setImageFromSprite('#mission') }>
                    </use>
                </svg>
            </div>
            <div className={style.philosophy}>
                <Description description={descriptions[2]}/>
                <div className={style.philosophy_img}>
                    { philosophyBlock() }
                </div>
                <div className={style.history_item_text}>{descriptions[2].subtitle_1}</div>
            </div>
            <div className={style.promises}>
                <WorkDetailsList detailList={aboutPromises} customClass={'promises_item'}/>
                <p className={style.promises_text}>
                    Digital Nomads — это новый формат жизни, в котором есть место работе и путешествиям,
                    <br/>
                    серьезному бизнесу и отдыху, индивидуальности и командным отношениям.
                </p>
            </div>
            <div className={style.worth}>
                <Description description={descriptions[3]}/>
                <div className={style.worth__block}>
                    <div className={style.worth__block_list}>
                        { worthBlock() }
                    </div>
                    <svg className={style.worth__block_img}>
                        <use xlinkHref={ setImageFromSprite('#worth') }>
                        </use>
                    </svg>
                </div>

            </div>
        </div>
    )
}

export default AboutBlock