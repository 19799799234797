// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Successful_Successful__IrMxz {\n    display: none;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    height: 200px;\n    background: #FFFFFF;\n}\n.Successful_Successful__IrMxz.Successful_open__2DQm0 {\n    display: flex;\n}\n.Successful_Successful__subtitle__1ZT7l {\n    font-size: 1.25rem;\n}\n.Successful_Successful__button__2z7yr {\n    width: 7.75rem;\n}\n\n@media screen and (max-width: 414px) {\n    .Successful_Successful__subtitle__1ZT7l {\n        padding: 0 3.5rem;\n    }\n    .Successful_Successful__subtitle__1ZT7l p {\n        font-size: 1.125rem;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/UI/Successful/Successful.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".Successful {\n    display: none;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    height: 200px;\n    background: #FFFFFF;\n}\n.Successful.open {\n    display: flex;\n}\n.Successful__subtitle {\n    font-size: 1.25rem;\n}\n.Successful__button {\n    width: 7.75rem;\n}\n\n@media screen and (max-width: 414px) {\n    .Successful__subtitle {\n        padding: 0 3.5rem;\n    }\n    .Successful__subtitle p {\n        font-size: 1.125rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Successful": "Successful_Successful__IrMxz",
	"open": "Successful_open__2DQm0",
	"Successful__subtitle": "Successful_Successful__subtitle__1ZT7l",
	"Successful__button": "Successful_Successful__button__2z7yr"
};
export default ___CSS_LOADER_EXPORT___;
