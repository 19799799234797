// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../img/bg_java.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JavaDeveloper_JavaDeveloper__293Mf {\n    background: #151515 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 100% 30% no-repeat;\n    padding: 10px 0;\n    overflow: hidden;\n    background-size: 40%;\n}", "",{"version":3,"sources":["webpack://src/pages/JavaDeveloper/JavaDeveloper.module.css"],"names":[],"mappings":"AAAA;IACI,8EAAmE;IACnE,eAAe;IACf,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":[".JavaDeveloper {\n    background: #151515 url(\"../../img/bg_java.svg\") 100% 30% no-repeat;\n    padding: 10px 0;\n    overflow: hidden;\n    background-size: 40%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"JavaDeveloper": "JavaDeveloper_JavaDeveloper__293Mf"
};
export default ___CSS_LOADER_EXPORT___;
