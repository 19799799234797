import React from 'react';
import { Link } from 'react-router-dom';
import style from './OurProjectCardItem.module.css';

const OurProjectCardItem = ({id, src, alt, title, hashtag, description, googlePlay, appStore, to}) => {

    return(
        <div key={id} className={style.OurProjectCardItem}>
            <div className={style.OurProjectCardItem__left}>
                <img src={src} alt={alt}/>
            </div>
            <div className={style.OurProjectCardItem__right}>
                <div>
                    <h4>{title}</h4>
                    <span>{hashtag}</span>
                    <p>{description}</p>
                </div>
                <div className={style.OurProjectCardItem__right_bottom} >
                    <div className={style.OurProjectCardItem__apps}>
                        {
                            googlePlay ?
                                <a href={googlePlay} target='_blank'>
                                    <div className={style.googlePlay}></div>
                                </a>
                            : null
                        }
                        {
                            appStore ?
                                <a href={appStore} target='_blank'>
                                    <div className={style.appStore}></div>
                                </a>
                            : null
                        }
                    </div>
                    <div className={style.OurProjectCardItem__button}>
                        <Link to={to}>
                            Подробнее
                        </Link>
                        <div className={style.arrow}></div>
                    </div>
                </div>
            </div>
       </div>
    )
}

export default OurProjectCardItem
