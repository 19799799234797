// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Social_Social__3AD_V {\n    margin-bottom: 3rem;\n}\n.Social_Social__title__JVn1Y {\n    font-weight: 600;\n    margin-bottom: 1.875rem;\n}\n.Social_Social__group__26umB {\n    display: flex;\n}\n.Social_Social__item__oxCiW {\n    margin-right: 2rem;\n}\n.Social_Social__item_svg__2EA5g {\n    height: 69px;\n    width: 69px;\n}\n\n@media screen and (max-width: 414px) {\n    .Social_Social__item__oxCiW {\n        margin: auto;\n    }\n    .Social_Social__item_svg__2EA5g {\n        height: 54px;\n        width: 54px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Social/Social.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,aAAa;AACjB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,YAAY;QACZ,WAAW;IACf;AACJ","sourcesContent":[".Social {\n    margin-bottom: 3rem;\n}\n.Social__title {\n    font-weight: 600;\n    margin-bottom: 1.875rem;\n}\n.Social__group {\n    display: flex;\n}\n.Social__item {\n    margin-right: 2rem;\n}\n.Social__item_svg {\n    height: 69px;\n    width: 69px;\n}\n\n@media screen and (max-width: 414px) {\n    .Social__item {\n        margin: auto;\n    }\n    .Social__item_svg {\n        height: 54px;\n        width: 54px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Social": "Social_Social__3AD_V",
	"Social__title": "Social_Social__title__JVn1Y",
	"Social__group": "Social_Social__group__26umB",
	"Social__item": "Social_Social__item__oxCiW",
	"Social__item_svg": "Social_Social__item_svg__2EA5g"
};
export default ___CSS_LOADER_EXPORT___;
