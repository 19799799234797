// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Requisites_Requisites__directions__V9brX {\n    margin: 1rem 0 3rem;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n.Requisites_Requisites__item__1FSFd {\n    position: relative;\n    margin: 0.5rem 0;\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://src/pages/Requisites/Requisites.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".Requisites__directions {\n    margin: 1rem 0 3rem;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n.Requisites__item {\n    position: relative;\n    margin: 0.5rem 0;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Requisites__directions": "Requisites_Requisites__directions__V9brX",
	"Requisites__item": "Requisites_Requisites__item__1FSFd"
};
export default ___CSS_LOADER_EXPORT___;
