// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TechnicalDocumentation_TechnicalDocumentation__J6TPj {\n    background: #151515;\n    padding: 10px 0;\n    overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://src/pages/TechnicalDocumentation/TechnicalDocumentation.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".TechnicalDocumentation {\n    background: #151515;\n    padding: 10px 0;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TechnicalDocumentation": "TechnicalDocumentation_TechnicalDocumentation__J6TPj"
};
export default ___CSS_LOADER_EXPORT___;
