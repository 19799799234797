import React from 'react';
import { Container } from 'reactstrap';
import style from './PreviewCase.module.css';

import Header from '../Header/Header';
import DescriptionCase from '../DescriptionCase/DescriptionCase';
import ProjectDetailsList from '../ProjectDetailsList/ProjectDetailsList';

const PreviewCase = ({descriptions, preview, details}) => {
    return(
        <div className={style.PreviewCase} style={{backgroundImage: `url(${preview.bg})`}}>
            <Container>
                <Header/>
                <div className={style.PreviewCase__description}>
                    <div className={style.PreviewCase__image}>
                        <div className={style.PreviewCase__before}
                             style={{backgroundImage: `url(${preview.before})`}}>
                        </div>
                        <div className={style.PreviewCase__after}
                             style={{backgroundImage: `url(${preview.after})`}}>
                        </div>
                    </div>
                    <div>
                        <DescriptionCase description={descriptions}/>
                        <ProjectDetailsList
                            details={details}
                            preview={preview}
                        />
                    </div>
                </div>
            </Container>
        </div>
    ) 
}

export default PreviewCase