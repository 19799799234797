import React from 'react';
import style from './GeneralServiceBlock.module.css';
import GeneralServiceItem from './GeneralServiceItem/GeneralServiceItem';

const GeneralServiceBlock = ({services}) => {

    const elements = services.map((service) => {
        return (
            <GeneralServiceItem
                key={service.number}
                {...service}
            />
        )
    });

    return(
        <div className={style.GeneralServiceBlock}>
            {elements}
        </div>
    )
}

export default GeneralServiceBlock