// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Language_Language__2Dn0v {\n    text-transform: uppercase;\n    z-index: 101;\n}\n@media screen and (max-width: 414px) {\n    .Language_Language__2Dn0v ul{\n        opacity: 0;\n    }\n}\n.Language_Language__2Dn0v.Language_open__2hZ0T li:first-child {\n    color: #333333;\n}\n.Language_Language__2Dn0v li:last-child {\n    color: #BBBBBB;\n}\n.Language_Language__2Dn0v ul {\n    display: flex;\n    align-items: center;\n}\n.Language_Language__2Dn0v span {\n    display: block;\n    width: 0.125rem;\n    height: 1rem;\n    margin: 0 0.4375rem;\n    background: #BBBBBB;\n}", "",{"version":3,"sources":["webpack://src/components/Language/Language.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI;QACI,UAAU;IACd;AACJ;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".Language {\n    text-transform: uppercase;\n    z-index: 101;\n}\n@media screen and (max-width: 414px) {\n    .Language ul{\n        opacity: 0;\n    }\n}\n.Language.open li:first-child {\n    color: #333333;\n}\n.Language li:last-child {\n    color: #BBBBBB;\n}\n.Language ul {\n    display: flex;\n    align-items: center;\n}\n.Language span {\n    display: block;\n    width: 0.125rem;\n    height: 1rem;\n    margin: 0 0.4375rem;\n    background: #BBBBBB;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Language": "Language_Language__2Dn0v",
	"open": "Language_open__2hZ0T"
};
export default ___CSS_LOADER_EXPORT___;
