import React, {useState} from 'react';
import style from './HistorySlider.module.css'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HistoryItem from '../../History/HistoryItem/HistoryItem';


const HistorySlider = ({history, onClick}) => {

    const [swipe, setSwipe] = useState(false);

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        adaptiveHeight: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        swipeToSlide: true,
        pauseOnHover: true,
        variableWidth: true,
        draggable: history.length > 6,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                swipeToSlide: true,
                pauseOnHover: true
              }
            },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 3,
                  autoplay: false,
                  swipeToSlide: true
                }
            }
        ]
    };

    const elements = history.map((item) => {
        return(
            <div key={item.id} className={style.HistorySlider__content} onFocus={() => setSwipe(true)}>
                <HistoryItem
                    {...item}
                    onClick={onClick}
                />
            </div>
        )
    })

    const cls = [style.CustomSlider]
    if (swipe) {
        cls.push(style.swipe)
    }

    return(
        <div className={style.HistorySlider}>
            <Slider {...settings} className={cls.join(' ')}>
                {elements}
            </Slider>
        </div>
    )
}

export default HistorySlider