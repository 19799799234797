// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabsTeam_TabsTeam__ul__WhLZx {\n    margin-bottom: 1.875rem;\n}\n.TabsTeam_TabsTeam__li__yPLpS {\n    width: auto;\n    text-align: left;\n    margin-right: 2.5rem;\n    margin-bottom: 1rem;\n}\n.TabsTeam_TabsTeam__li__yPLpS a {\n    font-weight: 600;\n    font-size: 1.25rem;\n    line-height: 1.625rem;\n    color: #FFFFFF;\n    padding: 0 !important;\n}\n.TabsTeam_TabsTeam__li__yPLpS a.active {\n    color: #0064FA;\n    border-bottom: 2px solid #0064FA;\n}\n.TabsTeam_TabsTeam__li__yPLpS a:hover {\n    cursor: pointer;\n}\n\n@media screen and (max-width: 425px) {\n    .TabsTeam_TabsTeam__li__yPLpS a {\n        font-size: 1rem;\n    }\n    .TabsTeam_TabsTeam__li__yPLpS {\n        margin-right: 2rem;\n    }\n}\n\n\n", "",{"version":3,"sources":["webpack://src/components/UI/TabsTeam/TabsTeam.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;IACd,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,gCAAgC;AACpC;AACA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".TabsTeam__ul {\n    margin-bottom: 1.875rem;\n}\n.TabsTeam__li {\n    width: auto;\n    text-align: left;\n    margin-right: 2.5rem;\n    margin-bottom: 1rem;\n}\n.TabsTeam__li a {\n    font-weight: 600;\n    font-size: 1.25rem;\n    line-height: 1.625rem;\n    color: #FFFFFF;\n    padding: 0 !important;\n}\n.TabsTeam__li :global(a.active) {\n    color: #0064FA;\n    border-bottom: 2px solid #0064FA;\n}\n.TabsTeam__li a:hover {\n    cursor: pointer;\n}\n\n@media screen and (max-width: 425px) {\n    .TabsTeam__li a {\n        font-size: 1rem;\n    }\n    .TabsTeam__li {\n        margin-right: 2rem;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TabsTeam__ul": "TabsTeam_TabsTeam__ul__WhLZx",
	"TabsTeam__li": "TabsTeam_TabsTeam__li__yPLpS"
};
export default ___CSS_LOADER_EXPORT___;
