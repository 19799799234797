import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import style from './ProjectItemMobile.module.css';

import Backdrop from '../../UI/Backdrop/Backdrop';

const ProjectItemMobile = ({id, to, src, title, description}) => {

    const [activeModal, setActiveModal] = useState(false);

    const toggle = () => {
        setActiveModal(!activeModal);
        updateBodyStyles();
    };

    const active = () => {
        if (!activeModal) {
            setActiveModal(!activeModal);
            updateBodyStyles();
        }
    };

    const updateBodyStyles = () => {
        if (!activeModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "visible";
        }
    };

    const cls = [style.ProjectItemMobile]

    if (activeModal) {
        cls.push(style.active)
    }

    return(
        <React.Fragment>
            <div key={id} className={cls.join(' ')} onClick={active}>
                <img src={src} alt={title}/>
                <div className={style.ProjectItemMobile__content}>
                    { activeModal ? 
                    <div 
                        className={style.close}
                        onClick={toggle}
                    >
                        <div></div>
                    </div> : null }
                    
                    <div className={style.ProjectItemMobile__description}>
                        <h4>{title}</h4>
                        <p>{description}</p>
                        <Link 
                            to={to} 
                            className={style.ProjectItemMobile__btn}
                            onClick={toggle}
                        >
                            <div>
                                Подробнее
                                <div className={style.arrow}></div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div> 
            { activeModal ? <Backdrop onClick={toggle} zIndex={'true'} /> : null }
        </React.Fragment>
    )
}

export default ProjectItemMobile