// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileTesting_MobileTesting__1FaP4 {\n    background: #151515;\n    padding: 10px 0;\n    overflow: hidden;\n}", "",{"version":3,"sources":["webpack://src/pages/MobileTesting/MobileTesting.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".MobileTesting {\n    background: #151515;\n    padding: 10px 0;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MobileTesting": "MobileTesting_MobileTesting__1FaP4"
};
export default ___CSS_LOADER_EXPORT___;
