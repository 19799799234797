import React, {Component} from 'react';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import Footer from '../../components/Footer/Footer';
import WorkDetails from '../../components/WorkDetails/WorkDetails';


class WorkWithUs extends Component {

    state = {
        descriptions: {
            'vacancy': {
                title: `Digital Nomads —${'\n'} это новый формат жизни`,
                subtitle: 'Образ жизни цифрового кочевника обеспечивает нашим сотрудникам постоянную смену впечатлений и свободу выбора комфортной атмосферы для жизни. Позитивные эмоции помогают отлично справляться с рабочим переутомлением, а новый культурный опыт развивает креативность сотрудника и позволяет взглянуть на решение рабочих проблем и интересных задач под новым углом. '
            }
        }
    }

    render() {
        const {descriptions} = this.state;

        return(

            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions.vacancy}/>
                    <WorkDetails/>

                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default WorkWithUs