import React, {Component} from 'react';
import { Container } from 'reactstrap';

import Description from '../../components/Description/Description';
import Header from '../../components/Header/Header';
import GeneralServiceBlock from '../../components/GeneralServiceBlock/GeneralServiceBlock';
import OtherServiceBlock from '../../components/OtherServiceBlock/OtherServiceBlock';
import OurProject from '../../components/OurProject/OurProject';
import FormHandler from '../../components/FormHandler/FormHandler';
import Footer from '../../components/Footer/Footer';


class OurServices extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Ключевые компетентности',
                subtitle: 'Компания Digital nomads предлагает полный комплекс услуг\n по проектированию, разработке и сопровождению IT-систем.'
            },
            {
                id: 1,
                title: 'Разработка мобильных приложений',
                subtitle: 'Более 10 лет работы в сфере разработки мобильных приложений различной специфики позволил нам накопить богатый опыт в проектировании интуитивного и релевантного тематике приложения интерфейса',
                price: 'Стоимость: от 1 500 000 ₽',
                data: 'Сроки: от 2 месяцев'
            }
        ],
        services: [
            {
                number: '01',
                title: 'Разработка мобильных приложений',
                subtitle: 'Занимаемся созданием мобильных \nприложений на Android и iOS',
                to: '/our-services/mobile-app'
            },
            {
                number: '02',
                title: 'Формирование команды для реализации проекта',
                subtitle: 'Поможем найти, обучить и сплотить \nспециалистов мирового уровня для \nреализации вашего проекта',
                to: '/our-services/team-building'
            },
            {
                number: '03',
                title: 'Создание MVP',
                subtitle: 'MVP — первая версия приложения с \nминимумом функций, хорошо \nрешающая основные задачи.',
                to: '/our-services/mvp'
            },
            {
                number: '04',
                title: 'Аналитика',
                subtitle: 'Аналитика — один из ключевых элементов \nдля принятия решений в экономике \nи продвижения мобильных приложений.',
                to: '/our-services/analytics',
            }
        ],
        otherServices: [  
            {
                list: [
                    {to: '/', label: ''},
                    {to: '/our-services/audit', label: ' Аудит проекта'}
                ]
            },
            {
                list: [
                    {to: '/our-services/design', label: ' UX/UI дизайн'},
                    {to: '/', label: ''},
                ]
            },
            {
                list: [
                    {to: '/', label: ''},
                    {to: '/our-services/outstaffing', label: ' Аутстаффинг'}
                ]
            },
            {
                list: [
                    {to: '/our-services/technical-documentation', label: ' Составление технической документации'}
                ]
            }
        ],
        black: true
    }

    render() {

        const {descriptions, services, otherServices, black, page} = this.state;
   
        return(

            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions[0]}/>
                    <GeneralServiceBlock
                        services={services}
                        page={page}
                    />
                    <OtherServiceBlock otherServices={otherServices}/>
                    <OurProject/>
                    <FormHandler black={black}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default OurServices