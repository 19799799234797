import React from 'react';
import style from './ArticleCardList.module.css';

import ArticleCardItem from '../ArticleCardItem/ArticleCardItem';

const ArticleCardList = ({articles, itemsToShow, articlesLength}) => {

    const elements = articles.slice(0, itemsToShow).map((article) => {
        return(
            <ArticleCardItem
                key={article.id}
                {...article}
            />
        )
    })

    const boxShadow = [style.ArticleCardList];

    if (itemsToShow >= articlesLength) {
        boxShadow.push(style.boxShadow)
    }

    return(
        <div className={boxShadow.join(' ')}>
            {elements}
        </div>
    )
}

export default ArticleCardList