import React, {Component} from 'react';
import style from './FormResume.module.css';
import { Link } from 'react-router-dom';
import Input from 'react-phone-number-input/input'
import InputMask from 'react-input-mask';

class FormResume extends Component {    
    state = {
        userName: '',
        email: '',
        phone: '',
        link: '',
        file: '',
        fileItem: null,
        errorFileSize: ''
    };

    handlerSubmit = event => {
        event.preventDefault();

        const slackApiToken = 'xoxb-479231226724-2186732529542-uNDE8ZsWBYfXkzhZVq7Z7uhO';
        const channelId = 'C028ZEQH508';

        const json = [
                {
                    "color": "#36a64f",
                    "pretext": "Запрос с сайта https://digitalnomads.software",
                    "title": "Сообщение от: " + this.state.userName,
                    "fields": [
                        {
                            "title": "E-mail",
                            "value": this.state.email,
                            "short": false
                        },
                        {
                            "title": "Телефон",
                            "value": this.state.phone,
                            "short": false
                        },
                        {
                            "title": "Ссылка на hh или другие ресурсы",
                            "value": this.state.link,
                            "short": false
                        }
                    ],
                    "image_url": "http://my-website.com/path/to/image.jpg",
                    "thumb_url": "http://example.com/path/to/thumb.png",
                    "footer": "Slack API",
                    "footer_icon": "https://platform.slack-edge.com/img/default_application_icon.png",
                    "ts": new Date().getTime()
                }
            ];

        this.sendTextMessage(json, slackApiToken, 'C028ZEQH508');
        this.sendDocumentMessage(this.state.fileItem, slackApiToken, channelId);
        this.resetFileInput();
    }

    sendTextMessage = (json, token, channels) => {
        var urlencoded = new URLSearchParams();
        urlencoded.append("token", token);
        urlencoded.append("channel", channels);
        urlencoded.append("attachments", JSON.stringify(json));

        fetch('https://slack.com/api/chat.postMessage', {
            method: 'POST',
            body: urlencoded
        })
        .then(response => {
            this.props.method(response);
            console.log(response);
        })
        .catch(err => {
            console.log(err);
        })

        this.setState({userName:'', email:'', phone:'', link: '', file: ''});
    }

    sendDocumentMessage = (file, token, channels) => {
        if (!file) {
            return;
        }

        let formData = new FormData();
        formData.append("file", file);
        formData.append("token", token);
        formData.append("channels", channels);


        fetch('https://slack.com/api/files.upload', {
            method: 'POST', 
            body: formData
        })
        .then(response => {
            this.props.method(response);
            console.log(response);
        })
        .catch(err => {
            console.error(err);
        });
    }

    handlerChange = event => {
        this.setState({errorFileSize: ""})
        if (event.target.files) {
            if (event.target.files.length <= 0) {
                this.resetFileInput();
                return;
            }
            
            const maxFileSize = 2 * 1024 * 1024;
            if (event.target.files[0].size > maxFileSize) {
                this.setState({errorFileSize: "Размер файла превышает 2МБ"})
                this.resetFileInput();
                return;
            }

            this.setState({file: event.target.value, fileItem: event.target.files[0]})
            return;
        }

        const {value, name} = event.target;
        this.setState ({ [name]: value });
    }

    resetFileInput = () => {
        this.setState({file: "", fileItem: null})
    }

    render() {
        return(
            <form 
                onSubmit={this.handlerSubmit}
                className={style.FormResume}
            >
                <div className={style.FormResume__title}>
                    <h3>Отправь <br/> своё резюме</h3>
                </div>
                <div className={style.FormResume__block}>
                    <div className={style.FormResume__input}>
                        <div>
                            <input
                                name='userName'
                                type='text'
                                value={this.state.userName}
                                onChange={this.handlerChange}
                                required
                            />
                            <label>Введите Ф.И.О. <span>*</span></label>
                        </div>
                        <div>
                            <input
                                name='email'
                                type='email'
                                value={this.state.email}
                                onChange={this.handlerChange}
                                required
                            />
                            <label>E-mail <span>*</span></label>
                        </div>
                        <div>
                            <InputMask
                                name='phone'
                                type='phone'
                                mask="(+7) 999-999-99-99"
                                required
                                onChange={this.handlerChange}
                                value={this.state.phone}
                            />
                            <label>Телефон <span>*</span></label>
                        </div>
                    </div>
                    <div className={style.FormResume__resume}>
                        <div>
                            <input
                                name='link'
                                type='text'
                                value={this.state.link}
                                onChange={this.handlerChange}
                            />
                            <label>Ссылка на hh или другие ресурсы</label>
                        </div>
                        <p>или</p>
                        <div>
                            <input
                                name='file'
                                type='file'
                                accept='application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                application/pdf'
                                value={this.state.file}
                                onChange={this.handlerChange}
                            />
                            <div className={style.file}>
                                <label > 
                                    выбрать файл
                                    
                                </label>
                                <span>
                                    { this.state.fileItem ? this.state.fileItem.name 
                                        : this.state.errorFileSize ? this.state.errorFileSize 
                                        : 'файл не выбран'
                                    }
                                </span>
                            </div>
                            <label>Прикрепите своё резюме (.pdf, .doc, .docx) Максимальный размер – 2МБ</label>
                        </div>
                    </div>
                    <div className={style.FormResume__bottom}>
                        <div className={style.FormResume__bottom_checkbox}>
                            <input
                                type="checkbox" 
                                required
                            />
                            <span></span>
                            <label>Я согласен с <Link to='/privacy-policy' target='_blank'>условиями <br/>обработки персональных данных</Link></label>
                        </div>
                        <input
                            type='submit'
                            value='Отправить'
                        />
                    </div>
                </div>
            </form>
        )
    }
}

export default FormResume
