import React, {Component} from 'react';
import style from './Team.module.css';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import OurProject from '../../components/OurProject/OurProject';
import Company from '../../components/Company/Company';
import FormHandler from '../../components/FormHandler/FormHandler';
import Footer from '../../components/Footer/Footer';
import TabsTeam from '../../components/UI/TabsTeam/TabsTeam';


class Team extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Наша команда'
            }
        ],
        team: {
            ios: [
                {
                    id: 0,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 1,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 2,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 3,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 4,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 5,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 6,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 7,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 8,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 9,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 10,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                },
                {
                    id: 11,
                    title: 'iOS',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'iOS-разрабочик',
                    backgroundColor: '#0064FA'
                }
            ],
            android: [
                {
                    id: 0,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 1,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 2,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 3,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 4,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 5,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 6,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 7,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 8,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 9,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 10,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-2.svg',
                        alt: 'Teammate-ios-2'
                    },
                    name: 'Женя',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                },
                {
                    id: 11,
                    title: 'Android',
                    avatar: {
                        src: './img/teammate-ios-1.svg',
                        alt: 'Teammate-ios-1'
                    },
                    name: 'Саня',
                    direction: 'Android-разрабочик',
                    backgroundColor: '#05C873'
                }
            ],
            backend:[],
            frontend:[],
            tester:[],
            management:[],
            other: [
                {
                    id: 0,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-1.svg',
                        alt: 'Teammate-other-1'
                    },
                    name: 'Настя',
                    direction: 'Офис-менеджер',
                    backgroundColor: '#00BCD4'
                },
                {
                    id: 1,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-2.svg',
                        alt: 'Teammate-other-2'
                    },
                    name: 'Стасен',
                    direction: 'Проджект-менеджер',
                    backgroundColor: '#00BCD4'
                },     {
                    id: 2,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-1.svg',
                        alt: 'Teammate-other-1'
                    },
                    name: 'Настя',
                    direction: 'Офис-менеджер',
                    backgroundColor: '#00BCD4'
                },
                {
                    id: 3,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-2.svg',
                        alt: 'Teammate-other-2'
                    },
                    name: 'Стасен',
                    direction: 'Проджект-менеджер',
                    backgroundColor: '#00BCD4'
                },     {
                    id: 4,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-1.svg',
                        alt: 'Teammate-other-1'
                    },
                    name: 'Настя',
                    direction: 'Офис-менеджер',
                    backgroundColor: '#00BCD4'
                },
                {
                    id: 5,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-2.svg',
                        alt: 'Teammate-other-2'
                    },
                    name: 'Стасен',
                    direction: 'Проджект-менеджер',
                    backgroundColor: '#00BCD4'
                },     {
                    id: 6,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-1.svg',
                        alt: 'Teammate-other-1'
                    },
                    name: 'Настя',
                    direction: 'Офис-менеджер',
                    backgroundColor: '#00BCD4'
                },
                {
                    id: 7,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-2.svg',
                        alt: 'Teammate-other-2'
                    },
                    name: 'Стасен',
                    direction: 'Проджект-менеджер',
                    backgroundColor: '#00BCD4'
                },     {
                    id: 8,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-1.svg',
                        alt: 'Teammate-other-1'
                    },
                    name: 'Настя',
                    direction: 'Офис-менеджер',
                    backgroundColor: '#00BCD4'
                },
                {
                    id: 9,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-2.svg',
                        alt: 'Teammate-other-2'
                    },
                    name: 'Стасен',
                    direction: 'Проджект-менеджер',
                    backgroundColor: '#00BCD4'
                },     {
                    id: 10,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-1.svg',
                        alt: 'Teammate-other-1'
                    },
                    name: 'Настя',
                    direction: 'Офис-менеджер',
                    backgroundColor: '#00BCD4'
                },
                {
                    id: 11,
                    title: 'Other',
                    avatar: {
                        src: './img/teammate-other-2.svg',
                        alt: 'Teammate-other-2'
                    },
                    name: 'Стасен',
                    direction: 'Проджект-менеджер',
                    backgroundColor: '#00BCD4'
                }
            ]
        },
        noneArrow: true,
        form: 'formResume'
    }

    render() {
        const {descriptions, team, form} = this.state;

        return(

            <div className={style.Team}>
                <Container>
                    <Header/>
                    <Description description={descriptions[0]}/>
                    <TabsTeam team={team}/>
                    <FormHandler form={form}/>
                    <OurProject/>
                    <Company/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default Team