// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Stages_Stages__1lBH9 {\n    margin: 5.5rem 0 3.5rem;\n}\n.Stages_Stages__title__2QxoF {\n    margin-bottom: 3.5rem;\n}\n\n@media screen and (max-width: 768px) {\n    .Stages_Stages__1lBH9 {\n        margin: 4rem 0;\n    }\n    .Stages_Stages__title__2QxoF {\n        margin-bottom: 2rem;\n    }\n}\n\n@media screen and (max-width: 414px) {\n    .Stages_Stages__1lBH9 {\n        margin: 0 0 1.5rem 0;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Stages/Stages.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI;QACI,cAAc;IAClB;IACA;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".Stages {\n    margin: 5.5rem 0 3.5rem;\n}\n.Stages__title {\n    margin-bottom: 3.5rem;\n}\n\n@media screen and (max-width: 768px) {\n    .Stages {\n        margin: 4rem 0;\n    }\n    .Stages__title {\n        margin-bottom: 2rem;\n    }\n}\n\n@media screen and (max-width: 414px) {\n    .Stages {\n        margin: 0 0 1.5rem 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Stages": "Stages_Stages__1lBH9",
	"Stages__title": "Stages_Stages__title__2QxoF"
};
export default ___CSS_LOADER_EXPORT___;
