import React, {Component} from 'react';
import style from './Analytics.module.css';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import Footer from '../../components/Footer/Footer';
import AdvantagesList from '../../components/AdvantagesList/AdvantagesList';
import Stages from '../../components/Stages/Stages';
import Technology from '../../components/Technology/Technology';
import FormHandler from '../../components/FormHandler/FormHandler';


class Analytics extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Ключевые компетентности',
                subtitle: 'Компания Digital nomads предлагает полный комплекс услуг по проектированию, разработке и сопровождению мобильных приложений на самых популярных платформах'
            },
            {
                id: 1,
                title: 'Разработка мобильных приложений',
                subtitle: 'Более 10 лет работы в сфере разработки мобильных приложений различной специфики позволил нам накопить богатый опыт в проектировании интуитивного и релевантного тематике приложения интерфейса',
                price: 'Стоимость: от 1 500 000 ₽',
                data: 'Сроки: от 2 месяцев'
            },
            {
                id: 2,
                title: 'Дизайн интерфейсов приложений',
                subtitle: 'UX-дизайн отвечает за функциональность, адаптивность продукта и то, какие эмоции он вызывает у пользователей. Чем проще ваш интерфейс, тем проще пользователю получить результат и совершить целевое действие.',
                price: 'Стоимость: от 1 500 ₽ / час'
            },
            {
                id: 3,
                title: 'Создание MVP',
                subtitle: 'MVP — сокращение от «Minimum Viable Product». Цель разработки MVP — быстро проверить основную гипотезу о востребованности приложения среди целевой аудитории. Вместо долгой и дорогой разработки приложения с большим набором функций и сценариев выпустить приложение, хорошо решающее одну пользовательскую задачу.',
                price: 'Стоимость: от 500 000 ₽',
                data: 'Сроки: от 4-х недель'
            },
            {
                id: 4,
                title: 'Аналитика мобильных приложений',
                subtitle: 'Аналитика — один из ключевых элементов для принятия решений в экономике и продвижения мобильных приложений.',
                price: 'Стоимость: от 1 750 ₽ / час'
            }
        ],
        advantages: [
            {
                id: 0,
                img: './../img/analytics-1.svg',
                title: 'Аналитика маркетинга',
                subtitle: 'Какой канал наиболее эффективен для продвижения приложения? Анализируем количество установок, источники и ключевые действия пользователей.',
            },
            {
                id: 1,
                img: './../img/analytics-2.svg',
                title: 'Аналитика продукта',
                subtitle: 'Что изменить в приложении, чтобы было больше конверсий? Анализируем количество действий пользователей, профит и retention полезных юзеров.',
            },
            {
                id: 2,
                img: './../img/analytics-3.svg',
                title: 'Аналитика сторов',
                subtitle: 'Насколько хорош продукт для пользователей? Анализируем количество установок, источники и ключевые действия пользователей.',
            }
        ],
        technologies: [
            {
                id: 0,
                title: 'Технологии/сервисы',
                img: './../img/technology-1.jpg',
                list: [
                    {item: 'Sketch'},
                    {item: 'Figma'},
                    {item: 'Adobe Photoshop'},
                    {item: 'Adobe Illustrator'},
                    {item: 'Marvel'},
                    {item: 'Pixate'},
                    {item: 'Principle'},
                    {item: 'Atomic'},
                    {item: 'Adobe After Effects'}
                ]
            },
            {
                id: 1,
                title: 'Технологии/сервисы',
                img: './../img/technology-2.jpg',
                list: [
                    {item: 'Google Analytics'},
                    {item: 'Admob'},
                    {item: 'Taptica'},
                    {item: 'Google AdWords'},
                    {item: 'Google Analytics Premium'},
                    {item: 'Flurry'},
                    {item: 'Mixpanel'},
                    {item: 'MAT'},
                    {item: 'AppsFlyer'},
                    {item: 'AppAnnie'}
                ]
            }
        ],
        stages: [
            {
                number: '01',
                title: 'Аналитика',
                subtitle:'Собираем информацию о продукте, клиенте, конкурентах и близких аналогах.'
            },
            {
                number: '02',
                title: 'Анализ',
                subtitle: 'Анализируем код, безопасность серверов, дизайн продукта, рекламные каналы продвижения. Так же анализируем ситуацию на рынке, собираем информацию о трендах рынка.'
            },
            {
                number: '03',
                title: 'Имплементация',
                subtitle: 'Предоставляем все рекомендации по улучшению или по стратегии запуска продукта.'
            }
        ],
        black: true
    }

    render() {
        const {descriptions, advantages, technologies, stages, black} = this.state;

        return(

            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions[4]}/>
                    <AdvantagesList advantages={advantages}/>
                    <Technology technologys={technologies[1]}/>
                    <Stages stages={stages}/>
                    <FormHandler black={black}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default Analytics