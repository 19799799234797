// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StagesList_StagesList__21CvZ {\n    display: flex;\n    align-items: flex-start;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n@media screen and (max-width: 767px) {\n    .StagesList_StagesList__21CvZ {\n        flex-direction: column;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Stages/StagesList/StagesList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".StagesList {\n    display: flex;\n    align-items: flex-start;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n@media screen and (max-width: 767px) {\n    .StagesList {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StagesList": "StagesList_StagesList__21CvZ"
};
export default ___CSS_LOADER_EXPORT___;
