import React from 'react';
import style from './CompanySlider.module.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const CompanySlider = ({img}) => {

    const settings = {
        dots: true,
        dotsClass: style.custom__dots,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 525,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };

    const elements = img.map((item) => {
        return(
            <div key={item.id} className={style.CompanySlider__content}>
                <img height={'90'} width={'auto'} src={item.src} alt={item.alt} />
            </div>
        )
    })

    return(
        <div className={style.CompanySlider}>
            <Slider {...settings}>
                {elements}
            </Slider>
        </div>

    )
}

export default CompanySlider