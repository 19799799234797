import React from 'react';
import OurProjectCardItem from '../OurProjectCardItem/OurProjectCardItem';
import style from './OurProjectCardList.module.css';

const OurProjectCardList = ({projects, itemsToShow, projectsLength}) => {

    const elements = projects.slice(0, itemsToShow).map((project) => {
        return (
            <OurProjectCardItem
                key={project.id}
                {...project}
            />
        )
    });

    const boxShadow = [style.OurProjectCardList];

    if (itemsToShow >= projectsLength) {
        boxShadow.push(style.boxShadow)
    }

    return(
        <div className={boxShadow.join(' ')}>
            {elements}
        </div>
    )
}

export default OurProjectCardList