import React, {Component} from 'react';
import style from './Audit.module.css';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Description from '../../components/Description/Description';
import FormHandler from '../../components/FormHandler/FormHandler';
import Footer from '../../components/Footer/Footer';
import AdvantagesList from '../../components/AdvantagesList/AdvantagesList';
import Stages from '../../components/Stages/Stages';


class Audit extends Component {

    state = {
        descriptions: [
            {
                id: 0,
                title: 'Ключевые компетентности',
                subtitle: 'Компания Digital nomads предлагает полный комплекс услуг по проектированию, разработке и сопровождению мобильных приложений на самых популярных платформах'
            },
            {
                id: 1,
                title: 'Разработка мобильных приложений',
                subtitle: 'Более 10 лет работы в сфере разработки мобильных приложений различной специфики позволил нам накопить богатый опыт в проектировании интуитивного и релевантного тематике приложения интерфейса',
                price: 'Стоимость: от 1 500 000 ₽',
                data: 'Срок реализации: от 2 месяцев'
            },
            {
                id: 2,
                title: 'Дизайн интерфейсов приложений',
                subtitle: 'UX-дизайн отвечает за функциональность, адаптивность продукта и то, какие эмоции он вызывает у пользователей. Чем проще ваш интерфейс, тем проще пользователю получить результат и совершить целевое действие.',
                price: 'Стоимость: от 1 500 ₽ / час'
            },
            {
                id: 3,
                title: 'Создание MVP',
                subtitle: 'MVP — сокращение от «Minimum Viable Product». Цель разработки MVP — быстро проверить основную гипотезу о востребованности приложения среди целевой аудитории. Вместо долгой и дорогой разработки приложения с большим набором функций и сценариев выпустить приложение, хорошо решающее одну пользовательскую задачу.',
                price: 'Стоимость: от 500 000 ₽',
                data: 'Срок реализации: от 4-х недель'
            },
            {
                id: 4,
                title: 'Аналитика мобильных приложений',
                subtitle: 'Аналитика — один из ключевых элементов для принятия решений в экономике и продвижения мобильных приложений.',
                price: 'Стоимость: от 1 750 ₽ / час'
            },
            {
                id: 5,
                title: 'Аудит проекта',
                subtitle: 'Аудит приложения проводится, если оно работает неправильно. Например, пользователи пишут \nв комментариях, что приложение плохо работает, не загружается, у него плохой интерфейс. Низкие оценки — приложение не поднимается в поиске и не выполняет своих задач.',
                price: 'Стоимость: от 1500 ₽ / час'
            },
            {
                id: 6,
                title: 'Формирование команды для реализации поекта',
                subtitle: 'Наш главный офис находится в Томске — сибирской Силиконовой долине. В Томске много специалистов мирового уровня, которые готовы помочь вам в реализации проекта. А мы поможем вам их найти, обучить и сплотить. Обычно команда состоит из middle-разработчика, тимлида/senior-разработчика, QA-инженера и PM парт-тайм.',
                price: 'Стоимость: от 500 000 ₽'
            },
            {
                id: 7,
                title: 'Составление технической документации',
                subtitle: 'Детализированная документация по организационным и проектным решениям для успешного создания и эффективного функционирования всей системы.',
                price: 'Стоимость: от 1200 ₽ / час'
            },
            {
                id: 8,
                title: 'Аутстаффинг',
                subtitle: 'Аутстаффинг интересен тем компаниям, которые уже имеют свои подразделения по разработке программного обеспечения, но испытывают необходимость в дополнительных специалистах требуемой квалификации или хотят временно привлечь разработчиков для выполнения определенного проекта, не вводя их в штат компании.',
                price: 'Стоимость: от 1500 ₽ / час'
            },
            {
                id: 9,
                title: 'Мобильный A/B-тестинг',
                subtitle: 'Мобильное A/B тестирование — это процесс проведения эксперимента в мобильном приложении. Мы делаем выводы и внедряем те или иные изменения только тогда, когда тест достигает статистических значений.',
                price: 'Стоимость: от 100 000 ₽',
                data: 'Срок реализации: от 2-х недель'
            }
           
        ],
        advantages: [
            {
                id: 0,
                img: './../img/audit-1.svg',
                title: 'Анализ кода',
                subtitle: 'Большинство критических проблем мобильных приложений скрываются в коде. Порой проще и дешевле написать приложение заново, чем исправить ошибки другого разработчика.',
            },
            {
                id: 1,
                img: './../img/audit-2.svg',
                title: 'Анализ дизайна',
                subtitle: 'Непропорционально большие или маленькие элементы, нечитабельные шрифты, неправильные сочетания цветов убивают конверсию приложения. И это не наше личное мнение, а цифры показателей. Посчитаем, проведём A/B-тесты, сделаем выводы.',
            },
            {
                id: 2,
                img: './../img/audit-3.svg',
                title: 'Анализ юзабилити',
                subtitle: 'Удобство использования приложения для достижения определенных целей. Интерфейс должен быть интуитивно понятным.',
            },
            {
                id: 3,
                img: './../img/audit-4.svg',
                title: 'Проверка приложения \nна безопасность',
                subtitle: 'Кроме того, в приложении может быть прописан вредоносный код, который показывает рекламу или ворует личные данные пользователей.',
            }
        ],
        stages: [
            {
                number: '01',
                title: 'Аналитика',
                subtitle: 'Собираем информацию о продукте, клиенте, конкурентах и близких аналогах.'
            },
            {
                number: '02',
                title: 'Анализ',
                subtitle: 'Анализируем код, безопасность серверов, дизайн продукта, рекламные каналы продви- жения. Также анализируем ситуацию на рынке, собираем информацию о трендах.'
            },
            {
                number: '03',
                title: 'Имплементация',
                subtitle: 'Предоставляем все рекомендации по улучшению или по стратегии запуска продукта.'
            }
        ],
        black: true
    }

    render() {
        const {descriptions, advantages, stages, black} = this.state;

        return(

            <div className='Matrix-background'>
                <Container>
                    <Header/>
                    <Description description={descriptions[5]}/>
                    <AdvantagesList advantages={advantages}/>
                    <Stages stages={stages}/>
                    <FormHandler black={black}/>
                    <Footer/>
                </Container>
            </div>
        )
    }
}

export default Audit