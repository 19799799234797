import React from 'react';
import { Link } from 'react-router-dom';
import style from './ProjectItem.module.css';

const ProjectItem = ({id, to, src, title, description}) => {

    return(
        <Link to={to} key={id} className={style.ProjectItem}>
            <img src={src} alt={title}/>
            <div className={style.ProjectItem__content}>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </Link>
    )
}

export default ProjectItem