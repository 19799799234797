import React from 'react';
import style from './Why.module.css';


const Why = () => {
    return(
        <div className={style.Why}>
            <div className={style.Why__img}>
                <div className={style.Why__img_block}>
                    <img src={'../../../img/why-1.jpg'}/>
                </div>
                <div className={style.Why__text}>
                    <h4>Почему мы?</h4>
                    <p>Мы искренне гордимся нашими людьми и делаем всё возможное, чтобы
                        их навыки, энергия и идеи приносили пользу для всего человечества.
                        <br/><br/>
                        Наши разработчики — это настоящие фанаты своего дела. У каждого есть
                        личные бэкграунды и мировоззрение, но всех нас объединяет любовь к своему делу, стремление к развитию и профессионализму.
                    </p>
                </div>
                <div className={style.Why__img_block}>
                    <img src={'../../../img/why-2.jpg'}/>
                </div>
            </div>
        </div>
    )
}

export default Why