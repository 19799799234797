// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdvantagesList_AdvantagesList__2XqyN {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-bottom: 3.5rem;\n}\n@media screen and (max-width: 768px) {\n    .AdvantagesList_AdvantagesList__2XqyN {\n        flex-direction: column;\n        margin-bottom: 2rem;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/AdvantagesList/AdvantagesList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,qBAAqB;AACzB;AACA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;AACJ","sourcesContent":[".AdvantagesList {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-bottom: 3.5rem;\n}\n@media screen and (max-width: 768px) {\n    .AdvantagesList {\n        flex-direction: column;\n        margin-bottom: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdvantagesList": "AdvantagesList_AdvantagesList__2XqyN"
};
export default ___CSS_LOADER_EXPORT___;
