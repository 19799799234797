import React from 'react';
import { Route, Switch} from 'react-router-dom';
import './App.css';
import pdfRu from './img/pdf/Portfolio_ru.pdf'
import pdfEn from './img/pdf/Portfolio_en.pdf'

import Main from './pages/Main/Main';
import OurServices from './pages/OurService/OurService';
import MobileApp from './pages/MobileApp/MobileApp';
import MVP from './pages/MVP/MVP'
import Design from './pages/Design/Design';
import Analytics from './pages/Analytics/Analytics';
import TeamBuilding from './pages/TeamBuilding/TeamBuilding';
import TechnicalDocumentation from './pages/TechnicalDocumentation/TechnicalDocumentation';
import Outstaffing from './pages/Outstaffing/Outstaffing';
import MobileTesting from './pages/MobileTesting/MobileTesting';
import Audit from './pages/Audit/Audit';
import Project from './pages/Project/Project';
import AboutUs from './pages/AboutUs/AboutUs';
import WorkWithUs from './pages/WorkWithUs/WorkWithUs';
import AndroidDeveloper from './pages/AndroidDeveloper/AndroidDeveloper';
import IosDeveloper from './pages/IosDeveloper/IosDeveloper';
import BecomeClient from './pages/BecomeClient/BecomeClient';
import Harakiri from './pages/Case/Harakiri/Harakiri';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Team from './pages/Team/Team';
import Blog from './pages/Blog/Blog';
import Article from './pages/Article/Article';
import CloudTrainer from './pages/Case/CloudTrainer/CloudTrainer';
import Fitabilty from './pages/Case/Fitabilty/Fitabilty';
import SteamaticAustralia from './pages/Case/SteamaticAustralia/SteamaticAustralia';
import TSU from './pages/Case/TSU/TSU';
import ItsMyLife from './pages/Case/ItsMyLife/ItsMyLife';
import SpeedReading from './pages/Case/SpeedReading/SpeedReading';
import BPharmacy from './pages/Case/BPharmacy/BPharmacy';
import Contact from './pages/Contact/Contact';
import JavaDeveloper from './pages/JavaDeveloper/JavaDeveloper';
import ReactDeveloper from './pages/ReactDeveloper/ReactDeveloper';
import { PortfolioPDF } from './pages/PortfolioPDF';


// const Main = lazy(() => import('./pages/Main/Main'));


const App = () => {

  return (
    <div className="App">
        <Switch>
          <Route exact path='/' component={Main} />
          <Route exact path='/our-services' component={OurServices} />
          <Route path='/our-services/mobile-app' component={MobileApp} />
          <Route path='/our-services/design' component={Design} />
          <Route path='/our-services/mvp' component={MVP} />
          <Route path='/our-services/analytics' component={Analytics} />
          <Route path='/our-services/audit' component={Audit} />
          <Route path='/our-services/team-building' component={TeamBuilding} />
          <Route path='/our-services/technical-documentation' component={TechnicalDocumentation} />
          <Route path='/our-services/outstaffing' component={Outstaffing} />
          <Route exact path='/project' component={Project} />
          <Route path='/project/harakiri' component={Harakiri} />
          <Route path='/project/cloud-trainer' component={CloudTrainer} />
          <Route path='/project/fitabilty' component={Fitabilty} />
          <Route path='/project/steamatic-australia' component={SteamaticAustralia} />
          <Route path='/project/tsu' component={TSU} />
          <Route path='/project/its-my-life' component={ItsMyLife} />
          <Route path='/project/speed-reading' component={SpeedReading} />
          <Route path='/project/b-pharmacy' component={BPharmacy} />
          <Route path='/about-us' component={AboutUs} />
          <Route exact path='/work-with-us' component={WorkWithUs} />
          <Route path='/work-with-us/android-developer' component={AndroidDeveloper} />
          <Route path='/work-with-us/ios-developer' component={IosDeveloper} />
          <Route path='/work-with-us/java-developer' component={JavaDeveloper} />
          <Route path='/work-with-us/react-developer' component={ReactDeveloper} />
          <Route path='/become-client' component={BecomeClient} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route path='/team' component={Team} />
          <Route path='/contact' component={Contact} />
          <Route path='/portfolio.pdf'>
            <PortfolioPDF pdfSource={pdfRu}/>
          </Route>

          <Route path='/en/portfolio.pdf'>
            <PortfolioPDF pdfSource={pdfEn}/>
          </Route>

          {/* <Route path='/mobile-testing' component={MobileTesting} />
          <Route exact path='/blog' component={Blog} />
          <Route path='/blog/:id'
            render={({match}) => {
              const id = match.params;
              return  <Article id={id}/>
            }}/> "Этих страниц нет в дизайне, при добавлении в дизайн, можно будет вернуть"*/}
        </Switch>
    </div>
  );
   
}

export default App;
