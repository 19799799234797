// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArticleCardList_ArticleCardList__3tpsq {\n    position: relative;\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));\n    /* grid-auto-rows: minmax(450px, auto); */\n    grid-gap: 3rem 1.25rem;\n    margin-bottom: 2rem;\n}\n.ArticleCardList_ArticleCardList__3tpsq::after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 430px;\n    background: linear-gradient(180deg, rgba(0, 0, 0, 0) -5.45%, #151515 100%);\n}\n.ArticleCardList_ArticleCardList__3tpsq.ArticleCardList_boxShadow__1iba9::after  {\n    display: none;\n}\n\n@media screen and (max-width: 991px) {\n    .ArticleCardList_ArticleCardList__3tpsq {\n        grid-gap: 1rem;\n        margin-bottom: 1rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://src/components/ArticlesCard/ArticleCardList/ArticleCardList.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,2DAA2D;IAC3D,yCAAyC;IACzC,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,aAAa;IACb,0EAA0E;AAC9E;AACA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,cAAc;QACd,mBAAmB;IACvB;AACJ","sourcesContent":[".ArticleCardList {\n    position: relative;\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));\n    /* grid-auto-rows: minmax(450px, auto); */\n    grid-gap: 3rem 1.25rem;\n    margin-bottom: 2rem;\n}\n.ArticleCardList::after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 430px;\n    background: linear-gradient(180deg, rgba(0, 0, 0, 0) -5.45%, #151515 100%);\n}\n.ArticleCardList.boxShadow::after  {\n    display: none;\n}\n\n@media screen and (max-width: 991px) {\n    .ArticleCardList {\n        grid-gap: 1rem;\n        margin-bottom: 1rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ArticleCardList": "ArticleCardList_ArticleCardList__3tpsq",
	"boxShadow": "ArticleCardList_boxShadow__1iba9"
};
export default ___CSS_LOADER_EXPORT___;
