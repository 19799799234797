import React from 'react';
import style from './TeammateItem.module.css';

const TeammateItem = ({avatar, name, direction, backgroundColor }) => {

  return (
    <div className={style.TeammateItem}>
        <div className={style.TeammateItem__avatar}>
            <div className={style.TeammateItem__circle} style={{ backgroundColor: `${backgroundColor}`}}></div>
            <img src={avatar.src} alt={avatar.alt}/>
        </div>
        <div className={style.TeammateItem__name}>
            <h5>{name}</h5>
        </div>
        <div className={style.TeammateItem__direction}>
            <p>{direction}</p>
        </div>
    </div>
  );
}

export default TeammateItem;
