import React from 'react';
import style from './Technology.module.css';

const Technology = ({technologys}) => {

    return(
        <div className={style.Technology}>
            <div className={style.Technology__img}>
                <img src={technologys.img} alt={technologys.title}/>
            </div>
            <div className={style.Technology__description}>
                <p>{technologys.title}</p>
                <ul>
                    {technologys.list.map((item, index) => {return( <li key={index}>{item.item}</li> )})}
                </ul>
            </div>
        </div>
    )
}

export default Technology