// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectBlock_ProjectBlock__2DhNh {\n    margin-bottom: 7.5rem;\n}\n.ProjectBlock_ProjectBlock__button__264BH {\n    width: 12.875rem;\n    margin: 0 auto;\n}\n\n@media screen and (max-width: 767px) {\n    .ProjectBlock_ProjectBlock__2DhNh {\n        margin-bottom: 2.5rem;\n    }\n    .ProjectBlock_ProjectBlock__button__264BH {\n        width: 11.5rem;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/ProjectBlock/ProjectBlock.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI;QACI,qBAAqB;IACzB;IACA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".ProjectBlock {\n    margin-bottom: 7.5rem;\n}\n.ProjectBlock__button {\n    width: 12.875rem;\n    margin: 0 auto;\n}\n\n@media screen and (max-width: 767px) {\n    .ProjectBlock {\n        margin-bottom: 2.5rem;\n    }\n    .ProjectBlock__button {\n        width: 11.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProjectBlock": "ProjectBlock_ProjectBlock__2DhNh",
	"ProjectBlock__button": "ProjectBlock_ProjectBlock__button__264BH"
};
export default ___CSS_LOADER_EXPORT___;
