import React from 'react';
import style from './Social.module.css';

const linksSocial = [
  // {href: 'https://www.facebook.com/dnomads.pro', label: ' Facebook', target: '_blank', src: '../../img/socialSprite.svg#facebook'},
  {href: 'https://www.linkedin.com/company/14587504', label: ' Linkedin', target: '_blank', src: '../../img/socialSprite.svg#linkedin'},
  // {href: 'https://www.instagram.com/dn.software/', label: ' Instagram', target: '_blank', src: '../../img/socialSprite.svg#instagram'},
  {href: 'https://vk.com/dnomads', label: 'Vk', target: '_blank', src: '../../img/socialSprite.svg#vk'}
];

const renderLinksSocial = () => {
  return linksSocial.map((link, index) => {
    return (
      <li key={index} className={style.Social__item}>
        <a
          href={link.href}
          target={link.target}
        >
          <svg className={style.Social__item_svg}>
            <use xlinkHref={link.src}>
            </use>
          </svg>
        </a>
      </li>
    )
  })
}

const Social = () => {
  return (
    <div className={style.Social}>
      <h2 className={style.Social__title}>Мы в соцсетях</h2>
      <ul className={style.Social__group}>
        {renderLinksSocial()}
      </ul>
    </div>
  )
}

export default Social