import React from 'react';
import style from './GallerySlider.module.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GallerySlideItem from '../../Gallery/GallerySlideItem/GallerySlideItem';

let slider;

const next = (() => {
    stopContent();
    slider.slickNext();
});

const prev = (() => {
    stopContent();
    slider.slickPrev();
});

const stopContent = () => {
    const contents = document.getElementsByClassName('GallerySlideItem_GallerySlideItem__content__1pMqG');
    Array.from(contents).forEach(el => {
        const mediaElement = el.children[0];
        if (mediaElement instanceof HTMLVideoElement) { 
            mediaElement.pause();
            mediaElement.currentTime = 0;
        }
    });
}

const ArrowLeft = ({currentSlide, slideCount, className, ...props}) => (
    <div
        {...props}
        className={`prev ${className}`}
        onClick={prev}
    >
    </div>
);

const ArrowRight = ({currentSlide, slideCount, className, onClick, ...props}) => (
    <div
        {...props}
        className={`next ${className}`}
        onClick={next}
    >
    </div>
);

const GallerySlider = ({content}) => {

    const settings = {
        dots: false,
        lazyLoad: true,
        infinite: false,
        className: style.CustomSlider,
        arrows: true,
        prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false
    };

    const elements = content.map((item) => {
        return(
            <div key={item.id} className={style.GallerySlider__content}>
                <GallerySlideItem {...item}/>
            </div>
        )
    })

    return(
        <div className={style.GallerySlider}>
            <Slider ref={c => {slider = c}} {...settings}>
                {elements}
            </Slider>
        </div>
    )
}

export default GallerySlider