import React, {Component} from 'react';
import style from './FormRequest.module.css';
import { Link } from 'react-router-dom';
import Input from 'react-phone-number-input/input'

import CustomSelect from './CustomSelect/CustomSelect';

class FormRequest extends Component {

    state = {
        userName: '',
        email: '',
        phone: '',
        file: '',
        fileItem: null,
        errorFileSize: '',
        companyName:'',
        companyLink: '',
        taskNames: {
            "android": "Android",
            "ios": "IOS",
            "frontend": "Веб-сайт",
            "backend": "Серверная часть",
            "admin": "Админ панель",
            "disign": "Дизайн",
            "integration": "Интеграция",
            "pay system": "Платежная система",
            "documentation": "Документация"
        },
        checkboxList: [
            { id: 0, label: "Android", name: "android" },
            { id: 1, label: "IOS", name: "ios" },
            { id: 2, label: "Веб-сайт", name: "frontend" },
            { id: 3, label: "Серверная часть", name: "backend" },
            { id: 4, label: "Админ панель", name: "admin" },
            { id: 5, label: "Дизайн", name: "disign" },
            { id: 6, label: "Интеграция", name: "integration" },
            { id: 7, label: "Платежная система", name: "pay system" },
            { id: 8, label: "Документация", name: "documentation" }
        ],
        selected: [],
        text: '',
        showList: false,
        budget: '',

        defaultSelectText: "Планируемый бюджет",
        optionsList: [
          { id: 0, name: "до 500 тыс." },
          { id: 1, name: "0,5-1 млн." },
          { id: 2, name: "1-3 млн." },
          { id: 3, name: "3-5 млн." },
          { id: 4, name: "более 5 млн." }
        ]
    }

    handlerSubmit = event => {
        event.preventDefault();

        const slackApiToken = 'xoxb-479231226724-2186732529542-uNDE8ZsWBYfXkzhZVq7Z7uhO';
        const channelId = 'C025FA0NXRU';

        const json = [
                {
                    "color": "#36a64f",
                    "pretext": "Заявка с сайта https://digitalnomads.software",
                    "title": "Сообщение от: " + this.state.userName,
                    "fields": [
                        {
                            "title": "E-mail",
                            "value": this.state.email,
                            "short": false
                        },
                        {
                            "title": "Телефон",
                            "value": this.state.phone,
                            "short": false
                        },
                        {
                            "title": "Название компании",
                            "value": this.state.companyName,
                            "short": false
                        },
                        {
                            "title": "Сайт компании",
                            "value": this.state.companyLink,
                            "short": false
                        },
                        {
                            "title": "Планируемый бюджет",
                            "value": this.state.budget,
                            "short": false
                        },
                        {
                            "title": "Что необходимо",
                            "value": this.calculateTaskName(),
                            "short": false
                        }
                    ],
                    "text": this.state.text,
                    "image_url": "http://my-website.com/path/to/image.jpg",
                    "thumb_url": "http://example.com/path/to/thumb.png",
                    "footer": "Slack API",
                    "footer_icon": "https://platform.slack-edge.com/img/default_application_icon.png",
                    "ts": new Date().getTime()
                }
            ];

        this.sendTextMessage(json, slackApiToken, 'C025FA0NXRU');
        this.sendDocumentMessage(this.state.fileItem, slackApiToken, channelId);
        this.resetFileInput();
        this.resetCheckboxChecked();
    }

    sendTextMessage = (json, token, channels) => {
        var urlencoded = new URLSearchParams();
        urlencoded.append("token", token);
        urlencoded.append("channel", channels);
        urlencoded.append("attachments", JSON.stringify(json));

        fetch('https://slack.com/api/chat.postMessage', {
            method: 'POST',
            body: urlencoded
        })
        .then(response => {
            this.props.method(response);
            console.log(response);
        })
        .catch(err => {
            console.log(err);
        })

        this.setState({userName:'', email:'', phone:'', link: '', file: '', companyName: '', companyLink: '', selected: [], text: ''});
    }

    sendDocumentMessage = (file, token, channels) => {
        if (!file) {
            return;
        }

        let formData = new FormData();
        formData.append("file", file);
        formData.append("token", token);
        formData.append("channels", channels);


        fetch('https://slack.com/api/files.upload', {
            method: 'POST', 
            body: formData
        })
        .then(response => {
            console.log(response);
        })
        .catch(err => {
            console.error(err);
        });
    }

    handlerChange = event => {
        //input
        const {value, name} = event.target;
        this.setState ({ [name]: value });
    }

    handleFileChange = event => {
        //file
        this.setState({errorFileSize: ""})
        if (event.target.files) {
            if (event.target.files.length <= 0) {
                this.resetFileInput();
                return;
            }
            
            const maxFileSize = 2 * 1024 * 1024;
            if (event.target.files[0].size > maxFileSize) {
                this.setState({errorFileSize: "Размер файла превышает 2МБ"})
                this.resetFileInput();
                return;
            }
            this.setState({file: event.target.value, fileItem: event.target.files[0]})
            return;
        }
    }

    handleCheckboxChange = event => {
        //chekbox
        let arr = this.state.selected;
        const item = event.target.name;
        const itemIndex = arr.indexOf(item);
        if (itemIndex > -1) {
            arr.splice(itemIndex, 1);
        } else {
            arr.push(item)
        }
        this.setState({selected: arr});
        const newArr = arr.map(item => this.state.taskNames[item]);
    }

    handerPhoneChange = value => {
        this.setState ({phone: value});
    }

    handleSelectChange = event => {
        this.setState({budget: event.target.textContent})
    }

    resetFileInput = () => {
        this.setState({file: "", fileItem: null})
    }

    resetCheckboxChecked = () => {
        var clist = document.getElementsByTagName("input");
        for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }
    }

    calculateTaskName = () => {
        return this.state.selected.map(item => this.state.taskNames[item]).join(", ");
    }

    render() {
        return(
            <form 
                onSubmit={this.handlerSubmit}
                className={style.FormRequest}
            >
                <div className={style.FormRequest__title}>
                    <h3>Оставить заявку</h3>
                </div>
                <div className={style.FormRequest__block}>
                    <div className={style.FormRequest__input}>
                        <div>
                            <input
                                name='userName'
                                type='text'
                                value={this.state.userName}
                                onChange={this.handlerChange}
                                required
                            />
                            <label>Введите Ф.И.О. <span>*</span></label>
                        </div>
                        <div>
                            <input
                                name='email'
                                type='email'
                                value={this.state.email}
                                onChange={this.handlerChange}
                                required
                            />
                            <label>E-mail <span>*</span></label>
                        </div>
                        <div>
                            <Input
                                name='phone'
                                value={this.state.phone}
                                onChange={this.handerPhoneChange}
                                required
                            />
                            <label>Телефон <span>*</span></label>
                        </div>
                    </div>
                    <div className={style.FormRequest__company}>
                        <div>
                            <input
                                name='companyName'
                                type='text'
                                value={this.state.companyName}
                                onChange={this.handlerChange}
                            />
                            <label>Название компании</label>
                        </div>
                        <div>
                            <input
                                name='companyLink'
                                type='text'
                                value={this.state.companyLink}
                                onChange={this.handlerChange}
                            />
                            <label>Сайт компании</label>
                        </div>
                    </div>
                    <div className={style.FormRequest__budget}>
                        <div  className={style.FormRequest__budget_select}>
                            <CustomSelect
                                defaultText={this.state.defaultSelectText}
                                optionsList={this.state.optionsList}
                                onChange={this.handleSelectChange}
                            />
                        </div>
                        <div>
                            <input
                                name='file'
                                type='file'
                                accept='application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                application/pdf'
                                value={this.state.file}
                                onChange={this.handleFileChange}
                            />
                            <div className={style.file}>
                                <label>выбрать файл</label>
                                <span>
                                    { this.state.fileItem ? this.state.fileItem.name 
                                        : this.state.errorFileSize ? this.state.errorFileSize 
                                        : 'файл не выбран'
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={style.FormRequest__checkbox}>
                        {
                            this.state.checkboxList.map(item => {
                                return (
                                    <div key={item.id} className={style.FormRequest__checkbox_item} >
                                        <label>{item.label}</label>
                                        <input 
                                            type="checkbox"
                                            name={item.name}
                                            onChange={this.handleCheckboxChange}
                                        />
                                        <span></span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <textarea
                        placeholder='Опишите ваш проект'
                        name='text'
                        value={this.state.text}
                        onChange={this.handlerChange}
                        wrap='soft'
                        required
                    />
                    <div className={style.FormRequest__bottom}>
                        <div className={style.FormRequest__bottom_checkbox}>
                            <input
                                type="checkbox" 
                                required
                            />
                            <span></span>
                            <label>Я согласен с <Link to='/privacy-policy' target='_blank'>условиями <br/>обработки персональных данных</Link></label>
                        </div>
                        <input
                            type='submit'
                            value='Отправить'
                        />
                    </div>
                </div>
            </form>
        )
    }
}

export default FormRequest